import * as React from 'react';
import { TextField, Autocomplete, InputLabel, createFilterOptions, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import { FormHelperText } from '@mui/material';
import { useState, useEffect } from 'react';
import { autoCompleteStyles, helperTextStyles, logoStyles, renderOptionLabelStyles, renderOptionListStyles, renderOptionStyles } from './autoCompleteWithFreeSoloStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { restrictSpaceAtStart } from 'constants/validations';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 240,
    width: '100%',
    height: '100%',
  },
  placeholder: {
    color: '#999',
  },
  standardVariant: {
    height: '88px',
  },
  select: {
    maxHeight: '370px',
  },
}));

export const AutoCompleteWithFreeSolo = (props) => {
  const classes = useStyles();
  const filter = createFilterOptions();
  const { input } = props;
  const [inputValue, setInputValue] = useState('');
  const imgSrc  = props?.imgSrc || '';
  const showIconField = props?.showIconConditionalField || false;

  useEffect(() => {
    if (input?.value) {
      setInputValue(input?.value?.label)
    }
    else {
      setInputValue("")
    }
  }, [input?.value])

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.label);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: 0,
        inputValue: inputValue,
        label: `"${inputValue}" will be added`,
      });
    }

    if (inputValue) {
      return filtered?.filter((option) => props?.noOptionsText !== option.label);
    }
    return filtered;
  };


  return (
    <FormControl
      className={classes.formControl}
      disabled={props.disabled}
      error={props.error}
      style={props.style}
      variant={props.variant}
      size="small"
    >
      <InputLabel shrink>{props.label}</InputLabel>

      <Autocomplete
        freeSolo
        noOptionsText={props?.noOptionsText ? props?.noOptionsText : "No options"}
        popupIcon={props.popupIcon ? <FontAwesomeIcon size="2xs" icon={faCaretDown} /> : ''}
        autoComplete
        autoHighlight
        value={input?.value || null}
        filterOptions={filterOptions}
        onBlur={(event) => {
          const isExisting = props.options?.some((option) => event.target.value === option.label);
          if (!isEmpty(event.target.value) && !isExisting) {
            if (typeof event.target.value === 'string') {
              props.onChange({
                value: 0,
                inputValue: inputValue,
                label: event.target.value,
              });
            }}
          }
        }
        getOptionDisabled={(option) =>
          option?.disabled === true
        }
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.value) {
            return option.label;
          }
          // Regular option
          return option.label;
        }}
        isOptionEqualToValue={(option, value) => option?.value === value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            props.onChange({
              value: 0,
              label: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            props.onChange({
              value: 0,
              label: newValue.inputValue,
            });
          } else {
            props.onChange(newValue);
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          // proceed only if the event type is "change", not "click" (indicating selection)
          if(event?.type === "change") {
            //Truncate spaces at start of input
            const value = restrictSpaceAtStart(newInputValue);
            setInputValue(value);
            if (props?.onInputChange) {
              props?.onInputChange(event)
            }
          }
        }}
        id="combo-box"
        options={props.options || []}
        sx={theme => autoCompleteStyles(theme)}
        renderInput={(params) =>
          <TextField
            {...params}
            error={props.error}
            placeholder={props.placeholder}
            inputProps={{
              ...params.inputProps, maxLength: props?.maxLength
            }}
          />
        }
        renderOption={(props, option) => {
          const showIcon = option && showIconField && option[showIconField];
          return (
            <div style={renderOptionStyles()}>
              <li {...props} style={renderOptionListStyles()}>
                <Tooltip title={option.label}>
                <div style={renderOptionLabelStyles()}>{option.label}</div>
                </Tooltip>
                {showIcon ? <img alt="CORL Cleared Logo" src={imgSrc} style={logoStyles()} /> : <></>}
              </li> 
            </div>
          )
        }}
        disabled={props.disabled}
      />
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
      {props.showHelperText && <div style={helperTextStyles()}>{props?.showHelperText}</div>}
    </FormControl>
  );
};

export default AutoCompleteWithFreeSolo;