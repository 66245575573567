export const customersAndProductBox = () => {
  return {
    marginBottom: '8px',
    marginTop: '0px',
  };
};

export const customersBox = (theme) => {
  return {
    width: '200px',
    marginBottom: '8px',
    display: 'flex',
    padding: '10px 0 5px 0',
    borderRadius: '8px',
    fontWeight: 'bold',
    '&.active': {
      background: `${theme.palette.grey[300]}`,
    },
    cursor: 'pointer',
  };
};

export const productsBox = (theme) => {
  return {
    width: '200px',
    margin: '-4px 0 4px 56px',
    padding: '10px',
    marginBottom: '8px',
    borderRadius: '8px',
    '&.active': {
      background: `${theme.palette.grey[300]}`,
    },
    cursor: 'pointer',
  };
};

export const iconstyle = () => {
  return {
    '& svg': {
      '& path': {
        margin: '1rem',
      },
    },
  };
};

export const loaderBackDropStyle = (theme) => {
  return {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  };
};
