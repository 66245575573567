import {
  GET_OUTREACH_ASSOCIATED_VENDORS_FAILURE,
  GET_OUTREACH_ASSOCIATED_VENDORS_LOADING,
  GET_OUTREACH_ASSOCIATED_VENDORS_SUCCESS,
  GET_OUTREACH_INFORMATION_FAILURE,
  GET_OUTREACH_INFORMATION_LOADING,
  GET_OUTREACH_INFORMATION_SUCCESS,
  GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_LOADING,
  GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_SUCCESS,
  GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_FAILURE,
} from '../actions/outreachInformation';

const initialState = {
  loading: false,
  outreachInformation: [],
  outreachAssociatedVendor: [],
  reviewStatusRecipientAssessment: {},
};

export const outreachInformation = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_OUTREACH_INFORMATION_LOADING:
    case GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_OUTREACH_INFORMATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        outreachInformation: payload,
      };
    }

    case GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        reviewStatusRecipientAssessment: payload?.Data?.Rows.length > 0 && payload?.Data?.Rows[0],
      };
    }

    case GET_OUTREACH_INFORMATION_FAILURE:
    case GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_OUTREACH_ASSOCIATED_VENDORS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_OUTREACH_ASSOCIATED_VENDORS_SUCCESS: {
      return {
        ...state,
        loading: false,
        outreachAssociatedVendor: payload,
      };
    }

    case GET_OUTREACH_ASSOCIATED_VENDORS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};
