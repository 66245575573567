import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { NO_DASHBOARD_DATA_FOUND, NO_DATA_FOUND } from '../constants/errorMessage';
import { getManagementDashboardSearchBody } from 'utils/dashboardUtil';

export const SEARCH_CLIENT_DASHBOARD_LOADING = '@@dashboard/SEARCH_CLIENT_DASHBOARD_LOADING';
export const SEARCH_CLIENT_DASHBOARD_SUCCESS = '@@dashboard/SEARCH_CLIENT_DASHBOARD_SUCCESS';
export const SEARCH_CLIENT_DASHBOARD_FAILURE = '@@dashboard/SEARCH_CLIENT_DASHBOARD_FAILURE';

export const getClientDashboard = () => async (dispatch) => {
  dispatch({ type: SEARCH_CLIENT_DASHBOARD_LOADING });
  try {
    //const body = getClientDashboardSearchBody();
    const response = await axios.post('app/customer/dashboard/action/geturl');
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message === NO_DATA_FOUND) {
        dispatch(setErrors(NO_DASHBOARD_DATA_FOUND));
      } else {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: SEARCH_CLIENT_DASHBOARD_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_CLIENT_DASHBOARD_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CLIENT_DASHBOARD_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_POWER_BI_DASHBOARD_URL_LOADING = '@@dashboard/GET_POWER_BI_DASHBOARD_URL_LOADING';
export const GET_POWER_BI_DASHBOARD_URL_SUCCESS = '@@dashboard/GET_POWER_BI_DASHBOARD_URL_SUCCESS';
export const GET_POWER_BI_DASHBOARD_URL_FAILURE = '@@dashboard/GET_POWER_BI_DASHBOARD_URL_FAILURE';

export const getPowerBIDashboardDetails = () => async (dispatch) => {
  dispatch({ type: GET_POWER_BI_DASHBOARD_URL_LOADING });
  try {
    const response = await axios.get('/app/customer/dashboard/powerbi/action/geturl');
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: GET_POWER_BI_DASHBOARD_URL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_POWER_BI_DASHBOARD_URL_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_POWER_BI_DASHBOARD_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const GET_MANAGEMENT_DASHBOARD_URL_LOADING = '@@dashboard/GET_MANAGEMENT_DASHBOARD_URL_LOADING';
export const GET_MANAGEMENT_DASHBOARD_URL_SUCCESS = '@@dashboard/GET_MANAGEMENT_DASHBOARD_URL_SUCCESS';
export const GET_MANAGEMENT_DASHBOARD_URL_FAILURE = '@@dashboard/GET_MANAGEMENT_DASHBOARD_URL_FAILURE';

export const getManagementDashboardDetails = () => async (dispatch) => {
  dispatch({ type: GET_MANAGEMENT_DASHBOARD_URL_LOADING });
  try {
    const body = getManagementDashboardSearchBody();
    const response = await axios.post('/app/customer/powerbi/dashboard/configuration/search', body);
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: GET_MANAGEMENT_DASHBOARD_URL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_MANAGEMENT_DASHBOARD_URL_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_MANAGEMENT_DASHBOARD_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
