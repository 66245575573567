import { TRUE } from 'constants/constants';
import { isEmpty, isNil } from 'lodash';
import { QA_APPROVED_QUESTIONNAIRE_TEMPLATE } from 'pages/AssessmentRequestDetail/constants';

export const getAssessmentRequestQuestionSearchBody = () => {
  return {
    intent: '',
    fields: ['QuestionText', 'QuestionPlainText', 'AssessmentRequestQuestionResponseType', 'IsRequired', 'ResponseOptionList', 'DisplayOrder'],
    Criterion: [],
    sort: [
      {
        field: 'DisplayOrder',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getvendorOverviewSearchListSearchBody = (formData = {}) => {
  let namwWebsiteUrl = formData?.VendorName;
  if (!isEmpty(formData?.WebsiteUrl)) {
    namwWebsiteUrl = `+${namwWebsiteUrl} ${formData?.WebsiteUrl}`;
  }
  return {
    Intent: '',
    Fields: ['VendorOrgId', 'VendorOrgName', 'DomainName', 'FullTextSearch', 'IsAssociatedWithCustomer', 'AssessmentRequestVendorRowLimit', 'RegisteredBusinessAddressState', 'RegisteredBusinessAddressCountry'],
    Criterion: [
      {
        Field: 'Org_DomainName',
        Operator: '=',
        Value: namwWebsiteUrl,
      },
      {
        Field: 'IsVerified',
        Operator: '=',
        Value: TRUE,
      },
      {
        Field: 'Active',
        Operator: '=',
        Value: TRUE,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};


export const getCustomerVendorProductListSearchBody = (vendorOrgId, searchText = "") => {
  const criterion = [
    {
      "Field": "VendorOrgId",
      "Operator": "=",
      "Value": vendorOrgId
    }
  ]
  if (searchText) {
    criterion.push({
      "Field": "ProductName",
      "Operator": "=",
      "Value": searchText
    })
  }
  return {
    "Intent": "",
    "Fields": [
      "ProductName",
      "ProductType",
      "ProductGroup",
      "ProductVendorRole",
      "Description",
      "ProductVendorId",
      "FullTextSearch",
      "CORLClearedStatus",
      "IsCorlCleared"
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0
  }
}

export const getAssessmentRequestSearchBody = (searchText) => {
  const criterion = [];
  if (!isNil(searchText)) {
    criterion.push({
      field: 'AssessmentRequestId',
      operator: '=',
      value: searchText,
    });
  }
  return {
    intent: '',
    fields: [
      'AssessmentRequestCategory',
      'AssessmentRequestType',
      'VendorOrgId',
      'VendorOrgName',
      'VendorUrl',
      'VendorDescription',
      'VendorContactId',
      'VendorContactTitle',
      'VendorContactFirstName',
      'VendorContactLastName',
      'VendorContactFullName',
      'VendorContactEmail',
      'VendorContactPhone',
      'VendorState',
      'VendorHeadquarterCountry',
      'StakeholderContactId',
      'StakeholderContactFirstName',
      'StakeholderContactLastName',
      'StakeholderContactFullName',
      'StakeholderContactDepartment',
      'StakeholderContactEmail',
      'StakeholderContactPhone',
      'ProductVendorId',
      'ProductName',
      'ProductDescription',
      'ProductUrl',
      'ProductCategory',
      'ProductVersion',
      'InternalAssessmentId',
      'InternalClientVendorId',
      'ScopingInformation',
      'SecurityControlInformation',
      'Comment',
      'Domain',
      'CapabilityGroup',
      'AssessmentStatus',
      'ClientOrgId',
      'ClientOrgName',
      'AssessmentRequestQuestionAndResponseList',
      'AssessmentRequestUploadedFile',
      'IsProductMedicalDevice',
      'ExternalTicketId',
      'DynVRASId',
      'DomainNameValidation',
      'IsVerified',
      'IsAssessmentValidated',
      'CCDynVRASId',
      'AssessmentVRASRequestType',
      "CORLClearedStatus",
      "IsCorlCleared",
      "Portal",
      "AssessmentRequestQuestionnaireTemplateList",
      "QTConfigExternalTicketId"
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentRequestReviewCommentsbody = (searchText) => {
  const criterion = [];
  if (!isEmpty(searchText)) {
    criterion.push({
      field: 'AssessmentRequestId',
      operator: '=',
      value: searchText,
    });
  }
  return {
    intent: '',
    fields: ['AssessmentRequestId', 'AssessmentStatus', 'ReviewComment', 'FirstName', 'LastName', 'InsertTimestamp'],
    Criterion: criterion,
    sort: [
      {
        field: 'InsertTimestamp',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getQuestionnaireTemplateSearchBody = (questionnaireTemplateTypeId = null, searchText = null, pageNo = 0, pageSize = 10) => {
  let intent = 'search';
  const fields = [
    'QuestionnaireTemplateName', 
    'QuestionnaireTemplateTypeName', 
    'VersionNumber', 
    'ReferenceId', 
    'ReferenceName', 
    'Status'
  ];

  const criterion = [
    {
      Field: 'QuestionnaireTemplateTypeId',
      Operator: 'in',
      Value: questionnaireTemplateTypeId, // Scoping = 1, Vendor Security Risk Assessment = 4, Supplemental = 5
    },
    {
      Field: 'Status',
      Operator: '=',
      Value: QA_APPROVED_QUESTIONNAIRE_TEMPLATE,
    },
  ];

  if (!isNil(searchText)) {
    criterion.push({
      Field: 'QuestionnaireTemplateName',
      Operator: 'contains',
      Value: searchText?.trim(),
    });
  }

  return {
    intent: intent,
    fields: fields,
    criterion: criterion,
    sort: [],
    page: pageNo,
    pageSize: pageSize,
  };
};

export const convertToValueLabel = (list = []) => {
  if (!isNil(list)) {
    return list?.map((item) => ({
      label: `${item?.QuestionnaireTemplateName} (v${item?.VersionNumber})`,
      value: item?.QuestionnaireTemplateId,
    }));
  }

  return [];
};