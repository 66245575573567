import {
  GET_INCIDENT_SLA_SECTION_LIST_LOADING,
  GET_INCIDENT_SLA_SECTION_LIST_SUCCESS,
  GET_INCIDENT_SLA_SECTION_LIST_FAILURE,
  SEARCH_SLA_INFORMATIONL_LIST_LOADING,
  SEARCH_SLA_INFORMATIONL_LIST_SUCCESS,
  SEARCH_SLA_INFORMATIONL_LIST_FAILURE,
} from '../actions/vendorsSlaInformation';

const initialState = {
  loading: false,
  slaInformationList: [],
  incidentSlaSectionList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_INCIDENT_SLA_SECTION_LIST_LOADING:
    case SEARCH_SLA_INFORMATIONL_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_SLA_INFORMATIONL_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        slaInformationList: payload.Data.Rows,
      };
    }

    case GET_INCIDENT_SLA_SECTION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        incidentSlaSectionList: payload?.Data,
      };
    }

    case GET_INCIDENT_SLA_SECTION_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_SLA_INFORMATIONL_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        slaInformationList: payload,
      };
    }

    default:
      return state;
  }
}
