import {
  SEARCH_VENDOR_CONTACT_LIST_LOADING,
  SEARCH_VENDOR_CONTACT_LIST_SUCCESS,
  SEARCH_VENDOR_CONTACT_LIST_FAILURE,
} from '../actions/vendorContactInformation';

const initialState = {
  loading: false,
  vendorContactList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_VENDOR_CONTACT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_VENDOR_CONTACT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorContactList: payload?.Data?.Rows.map((item) => {
          return {
            ...item,
            id: item.VendorContactId,
          };
        }),
      };
    }

    case SEARCH_VENDOR_CONTACT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorContactList: payload?.Data,
      };
    }

    default:
      return state;
  }
}
