import { DEFAULT_VENDOR_CATALOG_ROWS_PER_PAGE, VENDORS_CATALOG_TAB_OPTIONS } from "pages/VendorCatalog/constant";
import { 
        SET_VENDORS_CATALOG_TAB_VALUE,
        GET_TABLE_VIEW_DATA_LOADING,
        GET_TABLE_VIEW_DATA_SUCCESS,
        GET_TABLE_VIEW_DATA_FAILURE,
        SEARCH_VENDOR_CATALOG_CARD_LIST_LOADING,
        SEARCH_VENDOR_CATALOG_CARD_LIST_SUCCESS,
        SEARCH_VENDOR_CATALOG_CARD_LIST_FAILURE,
        ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_LOADING,
        ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_SUCCESS,
        ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_FAILURE,
        SET_VENDOR_CATALOG_ROW_PER_PAGE,
        SET_VENDOR_CATALOG_PAGE_NO,
        SEARCH_ALL_PRODUCTS_FOR_VENDOR_LOADING,
        SEARCH_ALL_PRODUCTS_FOR_VENDOR_SUCCESS,
        SEARCH_ALL_PRODUCTS_FOR_VENDOR_FAILURE,
        ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_LOADING,
        ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_SUCCESS,
        ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_FAILURE,
        SET_EXPANDED_VENDOR_ID,
        SET_SELECTED_VENDOR_PROFILE,
       } from "actions/vendorCatalog";
import { isEmpty, isNil } from "lodash";
import { ERROR_MESSAGE, ASSOCIATION_SUCCESSFULLY } from "constants/errorMessage";
import { toast } from "react-toastify";

const initialState = {
    loading: false,
    allProductsLoading: false,
    vendorCatalogTabValue: VENDORS_CATALOG_TAB_OPTIONS.CARD,
    vendorCatalogTableViewList: [],
    vendorCatalogCardsList: [],
    vendorCatalogTableViewListCount: 0,
    vendorCatalogPageNo: 0,
    vendorCatalogCardsListCount: 0,
    vendorCatalogRowsPerPage: DEFAULT_VENDOR_CATALOG_ROWS_PER_PAGE,
    expandedVendorId: null,
    allProductsForVendor: [],
    vendorProfileFromCatalog: {}
};

const getModifiedList = (actualList) => {

    let list = actualList;
    if (!isEmpty(list) && list?.length > 0) {
      return list.slice(1);
    }
    return list;
}

export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

        case SET_VENDORS_CATALOG_TAB_VALUE: {
            return {
              ...state,
              vendorCatalogTabValue: payload || VENDORS_CATALOG_TAB_OPTIONS.CARD,
            };
        }

        case GET_TABLE_VIEW_DATA_LOADING: {
            return {
                ...state,
                loading: true
            }
        }

        case GET_TABLE_VIEW_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
                vendorCatalogTableViewList: [],
                vendorCatalogTableViewListCount: 0
            }
        }

        case GET_TABLE_VIEW_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                vendorCatalogTableViewList: (!isNil(payload) && !isEmpty(payload)) ? getModifiedList(payload?.map((item, index) => ({
                    ...item,
                    id: index
                }))) : [],
                vendorCatalogTableViewListCount : payload?.length > 0 && payload[0]?.TotalCountOfVendors
            }
        }

        case SEARCH_VENDOR_CATALOG_CARD_LIST_LOADING: {
            return {
                ...state,
                loading: true
            }
        }

        case SEARCH_VENDOR_CATALOG_CARD_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                vendorCatalogCardsList: (!isEmpty(payload) && payload?.length > 0) ? payload?.slice(1) : payload,
                vendorCatalogCardsListCount: payload?.length > 0 && payload[0]?.TotalCountOfVendors
            }
        }

        case SEARCH_VENDOR_CATALOG_CARD_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                vendorCatalogCardsList: [],
                vendorCatalogCardsListCount: 0
            }
        }

        case SET_EXPANDED_VENDOR_ID: {
            return {
              ...state,
              expandedVendorId: payload,
              allProductsForVendor: [],
            };
        }

        case SET_SELECTED_VENDOR_PROFILE: {
            return {
              ...state,
              vendorProfileFromCatalog: payload
            };
        }
        
        case ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_LOADING: {
            return {
                ...state,
                loading: true
            }
        }

        case ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_FAILURE: {
            toast.error(ERROR_MESSAGE)
            return {
                ...state,
                loading: false
            }
        }

        case ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_SUCCESS: {
            toast.success(ASSOCIATION_SUCCESSFULLY);
            return {
                ...state,
                loading: false
            }
        }
        
        case SET_VENDOR_CATALOG_ROW_PER_PAGE: {
            return {
                ...state,
                loading: false,
                vendorCatalogRowsPerPage: payload
            }
        }

        case SET_VENDOR_CATALOG_PAGE_NO: {
            return {
                ...state,
                loading: false,
                vendorCatalogPageNo: payload
            }
        }

        case SEARCH_ALL_PRODUCTS_FOR_VENDOR_LOADING: {
            return {
              ...state,
              allProductsLoading: true,
            };
        }

        case SEARCH_ALL_PRODUCTS_FOR_VENDOR_SUCCESS: {
            return {
              ...state,
              allProductsLoading: false,
              allProductsForVendor: payload,
            };
        }

        case SEARCH_ALL_PRODUCTS_FOR_VENDOR_FAILURE: {
            return {
              ...state,
              allProductsLoading: false,
              allProductsForVendor: [],
            };
        }

        case ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_LOADING: {
            return {
                ...state,
                loading: true
            }
        }

        case ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }

        case ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_SUCCESS: {
            toast.success(ASSOCIATION_SUCCESSFULLY);
            return {
                ...state,
                loading: false
            }
        }

        default:
            return state;
    }
}
  