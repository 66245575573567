export const autoCompleteStyles = (theme) => {
  return {
    boxShadow: '-4px -4px 40px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.grey[0],
    borderRadius: '8px',
    '.MuiInputLabel-root': {
      marginTop: '4px !important',
      fontSize: '14px',
    },
    '.MuiInputBase-root': {
      padding: '2px',
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: '0',
    },
    '.MuiButtonBase-root': {
      marginRight: '4px',
    },
    '& button.MuiButtonBase-root': {
      visibility: 'hide',
    },
    '.MuiInputBase-input': {
      padding: "24px !important",
      "&::placeholder": {
        fontSize: "14px"
      }
    },
    marginTop: '20px',
  };
};

export const helperTextStyles = () => {
  return { 
    fontSize: '12px', 
    fontFamily: 'Roboto', 
    marginTop: '4px'
  };
};

export const logoStyles = () => {
  return {
    height: '16px',
    marginBottom: '8px',
    paddingLeft: '16px' 
  };
};

export const renderOptionStyles = () => {
  return {
    display: 'flex', 
    justifyContent: 'flex-start', 
    alignItems: 'center', 
    gap: '4px', 
    marginLeft: '16px', 
    width: 'calc(100% - 16px)',
  };
};

export const renderOptionListStyles = () => {
  return { 
    whiteSpace: "pre", 
    width: '100%', 
    paddingLeft: '4px' 
  };
};

export const renderOptionLabelStyles = () => {
  return { 
    maxWidth: '84%', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis'
  };
};