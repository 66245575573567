export const VENDORS_CATALOG_TAB_OPTIONS = {
    CARD: 'CARD',
    TABLE: 'TABLE',
};

export const VENDORS_CATALOG_TAB_LIST = [
    {
      label: VENDORS_CATALOG_TAB_OPTIONS.CARD,
      value: VENDORS_CATALOG_TAB_OPTIONS.CARD,
    },
    {
      label: VENDORS_CATALOG_TAB_OPTIONS.TABLE,
      value: VENDORS_CATALOG_TAB_OPTIONS.TABLE,
    },
];

export const QUICK_ADD = 'quick_add';
export const VENDOR_CATALOG = 'vendor-catalog';

export const ASSOCIATE_VENDOR_ORGANIZATION_CONFIRMATION_MESSAGE = `This action will link the selected vendor organization to your active vendor list. Would you like to proceed?`;
export const ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_CONFIRMATION_MESSAGE = `This action will associate Product to your active Vendors & Products list. Would you like to proceed?`;

export const DEFAULT_VENDOR_CATALOG_PAGE_NO = 1;
export const DEFAULT_VENDOR_CATALOG_ROWS_PER_PAGE = 12;
export const DEFAULT_VENDOR_CATALOG_TABLE_ROWS_PER_PAGE = 25;

export const NON_CORL_CLEARED_STATUS_ID = 1;
export const CORL_CLEARED_PARTICIPANT_STATUS_ID = 2;
export const CORL_CLEARED_STATUS_ID = 3;
export const CORL_CLEARED_CANDIDATE_STATUS_ID = 4;

export const NO_PRODUCTS_ASSOCIATED = 'No Products Associated for this Vendor';

export const ASSOCIATE_PRODUCT_TOOLTIP_WARNING_MESSAGE = 'This Vendor is not associated. Please associate the Vendor and then retry for Product Association.';

export const CARD_VIEW = 'CARD VIEW';
export const TABLE_VIEW = 'TABLE_VIEW';