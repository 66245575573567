import axios from 'axios';
import { isNil } from 'lodash';
import { getErrorObject } from 'utils/apiUtils';
import {
  getAssessmentOverviewBody,
  getCyberRiskScoreBody,
  getEvidenceReviewBody,
  getRiskRatingControlSummaryBody,
  getRiskRatingOverviewBody,
  getRiskFindingSummaryBody,
  getVendorCollaborationScoreBody,
  getAssessmentOverviewBodyForPortal,
  getRiskFindingSummaryBodyForPortal,
  getRiskRatingOverviewBodyForPortal,
  getEvidenceReviewBodyForPortal,
  getRiskRatingControlSummaryBodyForPortal,
} from 'utils/executiveSummaryUtils';
import { setErrors } from './errorHandler';
import { PORTAL_2 } from 'constants/constants';

export const SET_EXPAND_ALL_SECTIONS_SUCCESS = '@executivesummary/SET_EXPAND_ALL_SECTIONS_SUCCESS';

export const setExpandAllSections = (payload, callback) => async (dispatch) => {
  if (!isNil(payload)) {
    dispatch({
      type: SET_EXPAND_ALL_SECTIONS_SUCCESS,
      payload,
    });
  }
  if (callback) {
    callback();
  }
};

export const SET_LOADING_EXPANDED_SECTIONS = '@executivesummary/SET_LOADING_EXPANDED_SECTIONS';

export const setLoadingExpandedSections = (payload) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_EXPANDED_SECTIONS,
    payload,
  });
};

export const CLEAR_EXECUTIVE_SUMMARY = '@executivesummary/CLEAR_EXECUTIVE_SUMMARY';

export const clearExecutiveSummary = () => async (dispatch) => {
  dispatch({
    type: CLEAR_EXECUTIVE_SUMMARY,
  });
};

export const SEARCH_RISK_RATING_OVERVIEW_LOADING = '@executivesummary/SEARCH_RISK_RATING_OVERVIEW_LOADING';
export const SEARCH_RISK_RATING_OVERVIEW_SUCCESS = '@executivesummary/SEARCH_RISK_RATING_OVERVIEW_SUCCESS';
export const SEARCH_RISK_RATING_OVERVIEW_FAILURE = '@executivesummary/SEARCH_RISK_RATING_OVERVIEW_FAILURE';

export const getRiskRatingOverviewData =
  (id = null, source = "") =>
    async (dispatch) => {
      dispatch({ type: SEARCH_RISK_RATING_OVERVIEW_LOADING });
      try {
        let response;
        if (source === PORTAL_2) {
          const body = getRiskRatingOverviewBodyForPortal(id);
          response = await axios.post(`assessment/assessmentsummary/search`, body);
        } else {
          const body = getRiskRatingOverviewBody(id);
          response = await axios.post(`legacy/assessment/executivesummary/riskratingoverview/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_RISK_RATING_OVERVIEW_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_RISK_RATING_OVERVIEW_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_RISK_RATING_OVERVIEW_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_OVERVIEW_LOADING = '@executivesummary/SEARCH_ASSESSMENT_OVERVIEW_LOADING';
export const SEARCH_ASSESSMENT_OVERVIEW_SUCCESS = '@executivesummary/SEARCH_ASSESSMENT_OVERVIEW_SUCCESS';
export const SEARCH_ASSESSMENT_OVERVIEW_FAILURE = '@executivesummary/SEARCH_ASSESSMENT_OVERVIEW_FAILURE';

export const getAssessmentOverviewData =
  (id = null, source = "") =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_OVERVIEW_LOADING });
      try {
        let response;
        if (source === PORTAL_2) {
          const body = getAssessmentOverviewBodyForPortal(id);
          response = await axios.post(`assessment/search`, body);
        } else {
          const body = getAssessmentOverviewBody(id);
          response = await axios.post(`legacy/assessment/executivesummary/assessmentoverview/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_OVERVIEW_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_OVERVIEW_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_OVERVIEW_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING = '@executivesummary/SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING';
export const SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS = '@executivesummary/SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS';
export const SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE = '@executivesummary/SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE';

export const getRiskRatingControlSummaryData =
  (id = null, source = "") =>
    async (dispatch) => {
      dispatch({ type: SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING });
      try {
        let response;
        if (source === PORTAL_2) {
          const body = getRiskRatingControlSummaryBodyForPortal(id);
          response = await axios.post(`assessment/productvendor/recommendations/search`, body);
        } else {
          const body = getRiskRatingControlSummaryBody(id);
          response = await axios.post(`legacy/assessment/executivesummary/riskratingcontrol/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_EVIDENCE_REVIEW_LOADING = '@executivesummary/SEARCH_EVIDENCE_REVIEW_LOADING';
export const SEARCH_EVIDENCE_REVIEW_SUCCESS = '@executivesummary/SEARCH_EVIDENCE_REVIEW_SUCCESS';
export const SEARCH_EVIDENCE_REVIEW_FAILURE = '@executivesummary/SEARCH_EVIDENCE_REVIEW_FAILURE';

export const getEvidenceReviewData =
  (id = null, source) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_EVIDENCE_REVIEW_LOADING });
      try {
        let response;
        if (source === PORTAL_2) {
          const body = getEvidenceReviewBodyForPortal(id);
          response = await axios.post(`serviceprovider/assessmentquestionnairerequirement/search`, body);
        } else {
          const body = getEvidenceReviewBody(id);
          response = await axios.post(`legacy/assessment/executivesummary/validationevidence/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_EVIDENCE_REVIEW_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_EVIDENCE_REVIEW_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_EVIDENCE_REVIEW_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_CYBER_RISK_SCORE_LOADING = '@executivesummary/SEARCH_CYBER_RISK_SCORE_LOADING';
export const SEARCH_CYBER_RISK_SCORE_SUCCESS = '@executivesummary/SEARCH_CYBER_RISK_SCORE_SUCCESS';
export const SEARCH_CYBER_RISK_SCORE_FAILURE = '@executivesummary/SEARCH_CYBER_RISK_SCORE_FAILURE';

export const getCyberRiskScoreData =
  (id = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_CYBER_RISK_SCORE_LOADING });
      try {
        const body = getCyberRiskScoreBody(id);
        const response = await axios.post(`legacy/assessment/executivesummary/cyberriskscore/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_CYBER_RISK_SCORE_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_CYBER_RISK_SCORE_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_CYBER_RISK_SCORE_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_RISK_FINDING_SUMMARY_LOADING = '@executivesummary/SEARCH_RISK_FINDING_SUMMARY_LOADING';
export const SEARCH_RISK_FINDING_SUMMARY_SUCCESS = '@executivesummary/SEARCH_RISK_FINDING_SUMMARY_SUCCESS';
export const SEARCH_RISK_FINDING_SUMMARY_FAILURE = '@executivesummary/SEARCH_RISK_FINDING_SUMMARY_FAILURE';

export const getRiskFindingSummaryData =
  (id = null, source = "") =>
    async (dispatch) => {
      dispatch({ type: SEARCH_RISK_FINDING_SUMMARY_LOADING });
      try {
        let response;
        if (source === PORTAL_2) {
          const body = getRiskFindingSummaryBodyForPortal(id);
          response = await axios.post(`assessment/productvendor/recommendations/search`, body);
        } else {
          const body = getRiskFindingSummaryBody(id);
          response = await axios.post(`legacy/assessment/executivesummary/risksummary/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_RISK_FINDING_SUMMARY_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_RISK_FINDING_SUMMARY_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_RISK_FINDING_SUMMARY_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_VENDOR_COLLABORATION_SCORE_LOADING = '@executivesummary/SEARCH_VENDOR_COLLABORATION_SCORE_LOADING';
export const SEARCH_VENDOR_COLLABORATION_SCORE_SUCCESS = '@executivesummary/SEARCH_VENDOR_COLLABORATION_SCORE_SUCCESS';
export const SEARCH_VENDOR_COLLABORATION_SCORE_FAILURE = '@executivesummary/SEARCH_VENDOR_COLLABORATION_SCORE_FAILURE';

export const getVendorCollaborationScoreData = (id) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_COLLABORATION_SCORE_LOADING });
  try {
    const body = getVendorCollaborationScoreBody(id);
    const response = await axios.post(`legacy/assessment/executivesummary/vendorcollaborationscore/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_VENDOR_COLLABORATION_SCORE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_COLLABORATION_SCORE_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_COLLABORATION_SCORE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SAVE_ES_FEEDBACK_LOADING = '@executivesummary/SAVE_ES_FEEDBACK_LOADING';
export const SAVE_ES_FEEDBACK_SUCCESS = '@executivesummary/SAVE_ES_FEEDBACK_SUCCESS';
export const SAVE_ES_FEEDBACK_FAILURE = '@executivesummary/SAVE_ES_FEEDBACK_FAILURE';

export const saveESFeedback = (feedback) => async (dispatch) => {
  dispatch({ type: SAVE_ES_FEEDBACK_LOADING });
  try {
    const response = await axios.post('/client/executivesummary/feedback', feedback);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SAVE_ES_FEEDBACK_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: SAVE_ES_FEEDBACK_SUCCESS,
        payload: response?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: SAVE_ES_FEEDBACK_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_APRYSE_INIT_COUNT = '@executivesummary/SET_APRYSE_INIT_COUNT';

export const setApryseInitCount = (val = 0) => async (dispatch) => {
  dispatch({
    type: SET_APRYSE_INIT_COUNT,
    payload: val,
  });
};

export const SET_SEC_CERT_REVIEW_LIST = '@executivesummary/SET_SEC_CERT_REVIEW_LIST';

export const setSecCertReviewList = (payload) => async (dispatch) => {
  dispatch({
    type: SET_SEC_CERT_REVIEW_LIST,
    payload: payload,
  });
};