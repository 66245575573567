import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { getCompanyInformationSearchBody } from '../utils/companyInformationUtils';
import { setErrors } from './errorHandler';

export const GET_COMPANY_INFO_LOADING = '@@org/GET_COMPANY_INFO_LOADING';
export const GET_COMPANY_INFO_SUCCESS = '@@org/GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_INFO_FAILURE = '@@org/GET_COMPANY_INFO_FAILURE';

export const getCompanyInformation = () => async (dispatch) => {
  dispatch({
    type: GET_COMPANY_INFO_LOADING,
  });

  const body = getCompanyInformationSearchBody();

  try {
    const res = await axios.post(`/client/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_COMPANY_INFO_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: GET_COMPANY_INFO_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COMPANY_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_COMPANY_INFO_LOADING = '@@org/POST_COMPANY_INFO_LOADING';
export const POST_COMPANY_INFO_SUCCESS = '@@org/POST_COMPANY_INFO_SUCCESS';
export const POST_COMPANY_INFO_FAILURE = '@@org/POST_COMPANY_INFO_FAILURE';

export const addCompanyInformation = (id, formData) => async (dispatch) => {
  dispatch({
    type: POST_COMPANY_INFO_LOADING,
  });

  const body = {
    ClientOrgName: formData?.ClientOrgName,
    DomainName: formData?.DomainName,
    RegisteredBusinessAddressLine1: formData?.RegisteredBusinessAddressLine1,
    RegisteredBusinessAddressLine2: formData?.RegisteredBusinessAddressLine2,
    RegisteredBusinessAddressCity: formData?.RegisteredBusinessAddressCity,
    RegisteredBusinessAddressState: formData?.RegisteredBusinessAddressState,
    RegisteredBusinessAddressCountry: formData?.RegisteredBusinessAddressCountry,
    RegisteredBusinessAddressZip: formData?.RegisteredBusinessAddressZip,
    //Phone: formData?.Phone,
    PhoneCode: formData?.PhoneCode,
    MainPhone: formData?.MainPhone,
    PhoneExtension: formData?.PhoneExtension,
    NumberOfEmployeesRangeId: formData?.NumberOfEmployeesRangeId,
    YearFounded: parseInt(formData?.YearFounded),
    LogoFileLocation: '',
    ZendeskOrgId: formData?.ZendeskOrgId,
  };
  try {
    const res = await axios.put(`/client/${id}`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_COMPANY_INFO_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch(setCompanyInfoSuccess(true));
      dispatch({
        type: POST_COMPANY_INFO_SUCCESS,
        payload: res?.data?.Data,
      });
      dispatch(getCompanyInformation());
    }
  } catch (err) {
    dispatch({
      type: POST_COMPANY_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING = '@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING';
export const GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS = '@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS';
export const GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE = '@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE';

export const getNumberOfEmployeeRange = (callback) => async (dispatch) => {
  dispatch({
    type: GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=NumberOfEmployeesRange`);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS,
        payload: res?.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_STATE_LIST_LOADING = '@@lookup/GET_STATE_LIST_LOADING';
export const GET_STATE_LIST_SUCCESS = '@@lookup/GET_STATE_LIST_SUCCESS';
export const GET_STATE_LIST_FAILURE = '@@lookup/GET_STATE_LIST_FAILURE';

export const getStatesList = (countryName, callback) => async (dispatch) => {
  dispatch({
    type: GET_STATE_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=region?param=` + countryName);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_STATE_LIST_FAILURE,
        payload: {},
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: GET_STATE_LIST_SUCCESS,
        payload: res?.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: GET_STATE_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_COMPANY_INFO_LOADING = '@@org/SET_COMPANY_INFO_LOADING';
export const SET_COMPANY_INFO_SUCCESS = '@@org/SET_COMPANY_INFO_SUCCESS';
export const SET_COMPANY_INFO_FAILURE = '@@org/SET_COMPANY_INFO_FAILURE';

export const setCompanyInfoSuccess = (status) => async (dispatch) => {
  dispatch({
    type: SET_COMPANY_INFO_LOADING,
  });
  try {
    dispatch({
      type: SET_COMPANY_INFO_SUCCESS,
      payload: status,
    });
  } catch (err) {
    dispatch({
      type: SET_COMPANY_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_COUNTRY_LIST_LOADING = '@@lookup/GET_COUNTRY_LIST_LOADING';
export const GET_COUNTRY_LIST_SUCCESS = '@@lookup/GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAILURE = '@@lookup/GET_COUNTRY_LIST_FAILURE';

export const getCountriesList = (callback) => async (dispatch) => {
  dispatch({
    type: GET_COUNTRY_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=Country`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_COUNTRY_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_COUNTRY_LIST_SUCCESS,
        payload: res.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: GET_COUNTRY_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
