import TextField from '@mui/material/TextField';
import RadioGroup from '../RadioGroup/RadioGroup';
import CheckBox from '../CheckBox/CheckBox';
import CheckBoxGroup from '../CheckBoxGroup/CheckBoxGroup';
import Select from '../SelectBox/SelectBox';
import ToggleButtons from '../ToggleButtons/ToggleButtons';
import MultiSelectDropdown from 'components/MultiSelectDropdown/MultiSelectDropdown';
import AutocompleteBox from 'components/AutoComplete/AutoComplete';
import DatePickerField from 'components/DatePickerField/DatePickerField';
import MultiSelectAutocompleteBox from 'components/MultiSelectAutoComplete/MultiSelectAutoComplete';
import AutoCompleteWithFreeSolo from 'components/AutoCompleteWithFreeSolo/AutoCompleteWithFreeSolo';
import CountryCode from 'components/CountryCode/CountryCode';
import DateRangePicker from 'components/QuestionnaireBuilder/DateRangePicker';

export const T = {
  TEXT: 'text',
  NUMBER: 'number',
  EMAIL: 'email',
  RADIO_GROUP: 'radios',
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUP: 'checkboxes',
  SELECTBOX: 'select',
  DATE: 'date',
  TOGGLEBUTTON: 'togglebutton',
  MULTI_SELECT_DROPDOWN: 'multipleselectdropdown',
  AUTOCOMPLETE: 'autocomplete',
  DATEPICKER: 'datePicker',
  MULTI_SELECT_AUTOCOMPLETE: 'multiselectautocomplete',
  AUTOCOMPLETE_WITH_FREE_SOLO: "autocompleteWithFreeSolo",
  COUNTRY_CODE_DROPDOWN: 'countryCode',
  DATE_RANGE_PICKER: 'dateRangePicker',
};

export const getInputComponent = (inputType) => {
  switch (inputType) {
    case T.TEXT:
    case T.NUMBER:
    case T.EMAIL:
    case T.DATE:
      return TextField;
    case T.RADIO_GROUP:
      return RadioGroup;
    case T.CHECKBOX:
      return CheckBox;
    case T.CHECKBOX_GROUP:
      return CheckBoxGroup;
    case T.SELECTBOX:
      return Select;
    case T.TOGGLEBUTTON:
      return ToggleButtons;
    case T.MULTI_SELECT_DROPDOWN:
      return MultiSelectDropdown;
    case T.AUTOCOMPLETE:
      return AutocompleteBox;
    case T.DATEPICKER:
      return DatePickerField;
    case T.MULTI_SELECT_AUTOCOMPLETE:
      return MultiSelectAutocompleteBox;
    case T.AUTOCOMPLETE_WITH_FREE_SOLO:
      return AutoCompleteWithFreeSolo;
    case T.COUNTRY_CODE_DROPDOWN:
        return CountryCode;
    case T.DATE_RANGE_PICKER:
      return DateRangePicker;
    default:
      return TextField;
  }
};
