import { ASSESSSMENT_REQUEST_MAX_FILE_SIZE_LIMIT } from 'pages/Assessment/AssessmentRequest/constants';

export const ERROR_MESSAGE = 'Something Went Wrong.';
export const SAVE_SUCCESSFULLY_MESSAGE = 'Saved Successfully.';
export const UPDATED_SUCCESSFULLY_MESSAGE = 'Updated Successfully.';
export const DELETED_SUCCESSFULLY_MESSAGE = 'Deleted Successfully.';
export const USER_ROLE_ADDED_SUCCESSFULLY = 'User Role Assigned Successfully';
export const USER_ROLE_REMOVED_SUCCESSFULLY = 'User Role Removed Successfully';
export const INCIDENT_ERROR_MESSAGE = 'Unable to Save Incident Notification.';
export const INCIDENT_SAVED_SUCCESSFULLY_MESSAGE = 'Incident Notification Saved Successfully.';

export const UNASSIGN_USER_ROLE_CONFIRMATION_MESSAGE = 'This action will unassign the selected role of this user. Are you sure?';
export const FILE_UPLOADED_SUCCESSFULLY_MESSAGE = 'File Uploaded Successfully.';
export const FILE_UPLOAD_SIZE_ERROR_MESSAGE = `File size should not be greater than ${ASSESSSMENT_REQUEST_MAX_FILE_SIZE_LIMIT}MB`;
export const FILE_ALREADY_UPLOADED_MESSAGE = 'File already uploaded.';
export const NO_OF_FILES_TO_UPLOAD_LIMIT_MESSAGE = 'Exceeded the limit for uploading files here. ';
export const REMOVE_SELECTED_FILE_FROM_ASSESSMENT_REQUEST = 'This action will remove the selected file from the assessment request. Are you sure?';
export const ASSESSMENT_HELP_TICKET_CREATED_SUCCESSFULLY_MESSAGE = 'Assessment Help Ticket Created Successfully.';
export const RESEND_INVITE_SUCCESSFULLY = 'Invite Sent Successfully.';


//API Documentation
export const TOKEN_SET_SUCCESSFULLY_MESSAGE = 'Token set Successfully.';

export const NOTIFICATION_STATUS_UPDATED_SUCCESSFULLY_MESSAGE = "Notification Status Updated Successfully";

export const LINK_COPY_SUCCESSFULLY = 'Link copied to clipboard.';
