import { getRemediationGuidanceSearchBody, getRemediationGuidanceSearchBodyForPortal } from 'utils/remediationGuidanceUtils';
import { setErrors } from './errorHandler';
import { getErrorObject } from 'utils/apiUtils';
import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { PORTAL_2 } from 'constants/constants';

export const REMEDIATION_GUIDANCE_SEARCH_LOADING = '@@remediationGuidance/REMEDIATION_GUIDANCE_SEARCH_LOADING';
export const REMEDIATION_GUIDANCE_SEARCH_SUCCESS = '@@remediationGuidance/REMEDIATION_GUIDANCE_SEARCH_SUCCESS';
export const REMEDIATION_GUIDANCE_SEARCH_FAILURE = '@@remediationGuidance/REMEDIATION_GUIDANCE_SEARCH_FAILURE';

export const getRemediationGuidanceList = (assessmentId, source = "") => async (dispatch) => {
  dispatch({
    type: REMEDIATION_GUIDANCE_SEARCH_LOADING,
  });
  try {
    let res;
    if (source === PORTAL_2) {
      const body = getRemediationGuidanceSearchBodyForPortal(assessmentId, null);
      res = await axios.post(`assessment/productvendor/recommendations/search`, body);
    } else {
      const body = getRemediationGuidanceSearchBody(assessmentId, null);
      res = await axios.post(`/legacy/assessment/executivesummary/risksummary/search`, body);
    }
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: REMEDIATION_GUIDANCE_SEARCH_FAILURE,
        payload: {},
      });
      if (res?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(res?.data?.Errors));
      }
    } else {
      dispatch({
        type: REMEDIATION_GUIDANCE_SEARCH_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: REMEDIATION_GUIDANCE_SEARCH_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const REMEDIATION_GUIDANCE_DETAILS_SEARCH_LOADING = '@@remediationGuidance/REMEDIATION_GUIDANCE_DETAILS_SEARCH_LOADING';
export const REMEDIATION_GUIDANCE_DETAILS_SEARCH_SUCCESS = '@@remediationGuidance/REMEDIATION_GUIDANCE_DETAILS_SEARCH_SUCCESS';
export const REMEDIATION_GUIDANCE_DETAILS_SEARCH_FAILURE = '@@remediationGuidance/REMEDIATION_GUIDANCE_DETAILS_SEARCH_FAILURE';

export const getRemediationGuidanceDetials = (assessmentId, riskSummaryId, source = "") => async (dispatch) => {
  dispatch({
    type: REMEDIATION_GUIDANCE_DETAILS_SEARCH_LOADING,
  });
  try {
    let res;
    if (source === PORTAL_2) {
      const body = getRemediationGuidanceSearchBodyForPortal(assessmentId, riskSummaryId);
      res = await axios.post(`assessment/productvendor/recommendations/search`, body);
    } else {
      const body = getRemediationGuidanceSearchBody(assessmentId, riskSummaryId);
      res = await axios.post(`/legacy/assessment/executivesummary/risksummary/search`, body);
    }
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: REMEDIATION_GUIDANCE_DETAILS_SEARCH_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: REMEDIATION_GUIDANCE_DETAILS_SEARCH_SUCCESS,
        payload: res?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: REMEDIATION_GUIDANCE_DETAILS_SEARCH_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const CLEAR_REMEDIATION_GUIDANCE_DETAILS = '@@remediationGuidance/CLEAR_REMEDIATION_GUIDANCE_DETAILS';

export const clearRemediationGuidanceDetails = () => async (dispatch) => {
  dispatch({
    type: CLEAR_REMEDIATION_GUIDANCE_DETAILS,
  });
};

export const SUBMIT_ALTERNATE_GUIDANCE_LOADING = '@@remediationGuidance/SUBMIT_ALTERNATE_GUIDANCE_LOADING';
export const SUBMIT_ALTERNATE_GUIDANCE_SUCCESS = '@@remediationGuidance/SUBMIT_ALTERNATE_GUIDANCE_SUCCESS';
export const SUBMIT_ALTERNATE_GUIDANCE_FAILURE = '@@remediationGuidance/SUBMIT_ALTERNATE_GUIDANCE_FAILURE';

export const submitAlternateGuidance = (formData) => async (dispatch) => {
  dispatch({
    type: SUBMIT_ALTERNATE_GUIDANCE_LOADING,
  });

  try {
    const res = await axios.post(`/client/assessment/remediationguidance`, formData);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: SUBMIT_ALTERNATE_GUIDANCE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SUBMIT_ALTERNATE_GUIDANCE_SUCCESS,
        payload: res?.data?.Data,
      });
      dispatch(getRemediationGuidanceList(formData?.AssessmentId, formData?.Source));
    }
  } catch (err) {
    dispatch({
      type: SUBMIT_ALTERNATE_GUIDANCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ACCEPT_REMEDIATION_GUIDANCE_LOADING = '@@remediationGuidance/ACCEPT_REMEDIATION_GUIDANCE_LOADING';
export const ACCEPT_REMEDIATION_GUIDANCE_SUCCESS = '@@remediationGuidance/ACCEPT_REMEDIATION_GUIDANCE_SUCCESS';
export const ACCEPT_REMEDIATION_GUIDANCE_FAILURE = '@@remediationGuidance/ACCEPT_REMEDIATION_GUIDANCE_FAILURE';

export const acceptCORLRemediationGuidance = (assessmentId, acceptRemediationId, callback) => async (dispatch) => {
  dispatch({
    type: ACCEPT_REMEDIATION_GUIDANCE_LOADING,
  });
  const body = JSON.stringify({ AssessmentId: assessmentId, AcceptRemediationId: acceptRemediationId });
  try {
    const res = await axios.post(`/client/assessment/remediationguidance/corlrecommended`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: ACCEPT_REMEDIATION_GUIDANCE_FAILURE,
        payload: {},
      });
      if (res?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(res?.data?.Errors));
      }
    } else {
      dispatch({
        type: ACCEPT_REMEDIATION_GUIDANCE_SUCCESS,
        payload: res?.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: ACCEPT_REMEDIATION_GUIDANCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_REMEDIATION_GUIDANCE_STATUS_LOADING = '@@remediationGuidance/GET_REMEDIATION_GUIDANCE_STATUS_LOADING';
export const GET_REMEDIATION_GUIDANCE_STATUS_SUCCESS = '@@remediationGuidance/GET_REMEDIATION_GUIDANCE_STATUS_SUCCESS';
export const GET_REMEDIATION_GUIDANCE_STATUS_FAILURE = '@@remediationGuidance/GET_REMEDIATION_GUIDANCE_STATUS_FAILURE';

export const getCORLRemediationGuidanceSuccess = (assessmentId) => async (dispatch) => {
  dispatch({
    type: GET_REMEDIATION_GUIDANCE_STATUS_LOADING,
  });
  try {
    const res = await axios.get(`/client/assessment/${assessmentId}/remediationguidance`);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_REMEDIATION_GUIDANCE_STATUS_FAILURE,
        payload: {},
      });
      if (res?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(res?.data?.Errors));
      }
    } else {
      dispatch({
        type: GET_REMEDIATION_GUIDANCE_STATUS_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_REMEDIATION_GUIDANCE_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_LOADING = '@@remediationGuidance/SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_LOADING';
export const SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_SUCCESS = '@@remediationGuidance/SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_SUCCESS';
export const SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_FAILURE = '@@remediationGuidance/SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_FAILURE';

export const submitAlternateRemediationGuidance = (assessmentId, acceptRemediationId, callback) => async (dispatch) => {
  dispatch({
    type: SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_LOADING,
  });
  const body = JSON.stringify({ AssessmentId: assessmentId, AcceptRemediationId: acceptRemediationId });
  try {
    const res = await axios.post(`/client/assessment/remediationguidance/corlrecommended`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_FAILURE,
        payload: {},
      });
      if (res?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(res?.data?.Errors));
      }
    } else {
      dispatch({
        type: SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_SUCCESS,
        payload: res?.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
