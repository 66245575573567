import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { getSlaInformationListSearchBody } from '../utils/vendorSlaInformationUtil';
import { NO_DATA_FOUND } from 'constants/errorMessage';

export const GET_INCIDENT_SLA_SECTION_LIST_LOADING = '@@slaInformation/GET_INCIDENT_SLA_SECTION_LIST_LOADING';
export const GET_INCIDENT_SLA_SECTION_LIST_SUCCESS = '@@slaInformation/GET_INCIDENT_SLA_SECTION_LIST_SUCCESS';
export const GET_INCIDENT_SLA_SECTION_LIST_FAILURE = '@@slaInformation/GET_INCIDENT_SLA_SECTION_LIST_FAILURE';

export const getIncidentSlaSectionList = (entityName) => async (dispatch) => {
  dispatch({ type: GET_INCIDENT_SLA_SECTION_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=${entityName}`);
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: GET_INCIDENT_SLA_SECTION_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_INCIDENT_SLA_SECTION_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_SLA_SECTION_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_SLA_INFORMATIONL_LIST_LOADING = '@@slaInformation/SEARCH_SLA_INFORMATIONL_LIST_LOADING';
export const SEARCH_SLA_INFORMATIONL_LIST_SUCCESS = '@@slaInformation/SEARCH_SLA_INFORMATIONL_LIST_SUCCESS';
export const SEARCH_SLA_INFORMATIONL_LIST_FAILURE = '@@slaInformation/SEARCH_SLA_INFORMATIONL_LIST_FAILURE';

export const getSLAInformationList = (vendorOrgId, customerVendorId, productVendorId) => async (dispatch) => {
  dispatch({ type: SEARCH_SLA_INFORMATIONL_LIST_LOADING });
  try {
    const body = getSlaInformationListSearchBody(customerVendorId, productVendorId);
    const response = await axios.post(`/customer/vendor/${vendorOrgId}/slaresponse/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_SLA_INFORMATIONL_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_SLA_INFORMATIONL_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_SLA_INFORMATIONL_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
