import { SET_MENU, SET_MENU_PANEL_ID } from '../actions/navbar';

import { SESSION_DETAILS } from 'utils/localStorage';

export const MENU_CLAIM_LIST = 'menuClaimList';

const getMenus = () => {
  let sessionDetailsString = localStorage.getItem(SESSION_DETAILS);

  let sessionDetails = JSON.parse(sessionDetailsString);

  if (sessionDetails?.MenuClaimList) return sessionDetails?.MenuClaimList;
  return null;
};

const initialState = {
  menuList: getMenus(),
  menuPanelId: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MENU: {
      let sessionDetailsString = localStorage.getItem(SESSION_DETAILS);
      let sessionDetails = JSON.parse(sessionDetailsString);
      sessionDetails.MenuClaimList = payload;
      localStorage.setItem(SESSION_DETAILS, JSON.stringify(sessionDetails));
      return {
        ...state,
        menuList: payload,
      };
    }

    case SET_MENU_PANEL_ID: {
      return {
        ...state,
        menuPanelId: payload,
      };
    }

    default:
      return state;
  }
}
