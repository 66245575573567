import * as React from 'react';
import { TextField, Autocomplete, InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import { FormHelperText } from '@mui/material';
import { useState } from 'react';
import { autoCompleteStyles } from './autoCompleteStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 240,
    width: '100%',
    height: '100%',
  },
  placeholder: {
    color: '#999',
  },
  standardVariant: {
    height: '88px',
  },
  select: {
    maxHeight: '370px',
  },
}));

export const AutocompleteBox = (props) => {
  const classes = useStyles();
  const { input } = props;
  const [inputValue, setInputValue] = useState('');

  const filterOptions = (options, state) => {
    let filteredOptions = [];
    options.forEach((option) => {
      const optionItem = props?.options.find((item) => item.value === option);
      if (optionItem) {
        if (optionItem?.label?.toLowerCase()?.startsWith(state.inputValue.toLowerCase())) {
          filteredOptions.push(option);
        }
      }
    });
    return filteredOptions;
  };

  return (
    <FormControl className={classes.formControl} disabled={props.disabled} error={props.error} style={props.style} variant={props.variant} size="small">
      <InputLabel shrink>{props.label}</InputLabel>

      <Autocomplete
        freeSolo={props?.freeSolo ? props?.freeSolo : false}
        noOptionsText={props?.noOptionsText ? props?.noOptionsText : "No options"}
        popupIcon={props.popupIcon ? <FontAwesomeIcon size="2xs" icon={faCaretDown} /> : ''}
        autoComplete
        autoHighlight
        value={input.value || null}
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          const item = props?.options?.find((it) => it.value === option);
          return item?.label || '';
        }}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={(event, newValue) => props.onChange(newValue)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="combo-box"
        options={props.options.map((it) => it.value)}
        sx={autoCompleteStyles}
        renderInput={(params) => <TextField {...params} placeholder={props.placeholder} error={props.error} />}
        disabled={props.disabled}
      />
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
};

export default AutocompleteBox;
