import { YES_VALUE } from "constants/constants";
import { MANAGEMENT_DASHBOARD_SYSTEM_NAME } from "pages/ManagementDashboard/constants";

export const getClientDashboardSearchBody = () => {
  return {
    intent: '',
    fields: ['*'],
    criterion: [
      {
        Field: 'DashboardTag',
        Operator: '=',
        Value: 'MAIN',
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getManagementDashboardSearchBody = () => {
  return {
    intent: '',
    fields: [
      'SystemName',
      'PageName',
      'IsPageVisible',
      'FilterTableName',
      'FilterColumnName',
      'DashboardScrollHeight'
    ],
    criterion: [
      {
        Field: 'SystemName',
        Operator: '=',
        Value: MANAGEMENT_DASHBOARD_SYSTEM_NAME,
      },
      {
        Field: 'IsPageVisible',
        Operator: '=',
        Value: YES_VALUE,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
}