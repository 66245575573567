import DatePicker from 'react-multi-date-picker';
import { useEffect, useState } from 'react';
import 'react-multi-date-picker/styles/layouts/mobile.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';

import FormControl from '@mui/material/FormControl';
import { calendarIcon, datePickerStyle } from './datePickerFieldStyle';
import { FormHelperText } from '@mui/material';

export default function DatepickerResponse(props) {
  const [date, setDate] = useState();

  useEffect(() => {
    setDate(props.value);
  }, [props.value]);

  return (
    <div className="position-relative">
      <FormControl>
        <DatePicker
          {...props}
          style={datePickerStyle()}
          value={date}
          onChange={(e) => props.onChange(e?.format())}
          minDate={props?.disablePast ? new Date() : undefined}
          maxDate={props?.disableFuture ? new Date(new Date().setDate(new Date().getDate() - 1)) : undefined}
          placeholder="mm/dd/yyyy"
          format="MM/DD/YYYY"
          editable={true}
        />{' '}
        <FontAwesomeIcon icon={faCalendarDay} style={calendarIcon()} />
        {props.error && <FormHelperText error>{props.error}</FormHelperText>}
      </FormControl>
    </div>
  );
}
