import axios from "axios";
import { getErrorObject } from "utils/apiUtils";
import { getAppSettingsBody } from 'utils/appSettingUtils';

export const SEARCH_APP_SETTING_LOADING = '@assessmentsOverview/SEARCH_APP_SETTING_LOADING';
export const SEARCH_APP_SETTING_SUCCESS = '@assessmentsOverview/SEARCH_APP_SETTING_SUCCESS';
export const SEARCH_APP_SETTING_FAILURE = '@assessmentsOverview/SEARCH_APP_SETTING_FAILURE';

export const searchAppSettings = (KeysToSearch) => async (dispatch) => {
    dispatch({ type: SEARCH_APP_SETTING_LOADING });
    try {
      const body = getAppSettingsBody(KeysToSearch);
      const response = await axios.post(`/appsettings/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_APP_SETTING_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_APP_SETTING_SUCCESS,
          payload: response.data?.Data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_APP_SETTING_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };