import { isEmpty, isNil } from 'lodash';

export const getRemediationGuidanceSearchBody = (assessmentId = '', riskSummaryId = '') => {
  let criterion = [];
  if (!isEmpty(assessmentId)) {
    criterion = [
      {
        field: 'AssessmentId',
        operator: '=',
        value: assessmentId,
      },
    ];
  }
  if (!isEmpty(riskSummaryId)) {
    criterion.push({
      field: 'RiskSummaryId',
      operator: '=',
      value: riskSummaryId,
    });
  }
  return {
    intent: '',
    fields: [
      'EnvironmentId',
      'EnvironmentName',
      'RiskSummaryId',
      'RiskNumber',
      'Priority',
      'PriorityName',
      'RiskDescription',
      'ControlNumber',
      'ControlName',
      'StatusId',
      'Status',
      'CompletionDate',
      'RemediationStrategyAcceptByVendor',
      'AcceptedByVendor',
      'ContractuallyAgreedUponDate',
      'RecommendedDueDate',
      'ProposedRecommendation',
      'AssessmentResults',
      'ReviewComments',
      'ResultsOfTesting',
      'RiskNumber',
      'RemediationGuidance',
      'LastUpdated',
      'RiskStrategyRecommendationId',
      'RequiresRemediation',
      'RequiresRemediation_Value',
      'Source'
    ],
    Criterion: criterion,
    sort: [
      {
        Field: 'RiskNumber',
        Order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getRemediationGuidanceSearchBodyForPortal = (assessmentId, assessmentQuestionnaireRequirementId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion = [
      {
        field: 'AssessmentId',
        operator: '=',
        value: assessmentId,
      },
    ];
  }
  if (!isNil(assessmentQuestionnaireRequirementId)) {
    criterion.push({
      field: 'AssessmentQuestionnaireRequirementId',
      operator: '=',
      value: assessmentQuestionnaireRequirementId,
    });
  }
  return {
    intent: '',
    fields: [
      "RecommendationStatus",
      "NotesToVendor",
      "QuestionnaireTemplateRequirementId",
      "AssessmentQuestionnaireId",
      "RequirementId",
      "RequirementStatus",
      "RequirementNumber",
      "RequirementName",
      "RequirementType",
      "RequirementGroup",
      "ControlNumber",
      "DefaultTier",
      "AssessmentId",
      "QuestionnaireTemplateId",
      "AssessmentQuestionnaireName",
      "AssessmentName",
      "ProductVendorId",
      "RecommendationStatus",
      "Severity",
      "InadequecyReasonIPIList",
      "QuestionnaireTemplateRequirementId",
      "AcceptedByVendor",
      'RemediationGuidance',
      'RemediationDueDate'
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
