export default function TextField() {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          height: 'unset',
          width: '100%',
        },
      },
    },
  };
}
