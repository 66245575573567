// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.grey[0],
            opacity: 0.5,
          },
          padding: '12px 18px',
          borderRadius: '8px',
          '&:hover': {
            boxShadow: 'none',
          },
          textTransform: 'uppercase',
        },
        sizeMedium: {
          height: 38,
        },
        sizeLarge: {
          height: 48,
        },
        iconSizeSmall: {
          height: 12,
          width: 12,
          fontSize: '12px',
        },
        startIcon: {
          '>*:nth-of-type(1)': {
            fontSize: '12px',
          },
        },
        sizeSmall: {
          height: 27,
        },
        containedInherit: {
          boxShadow: 0, // theme.customShadows.z8,
        },
        containedPrimary: {
          boxShadow: 0, // theme.customShadows.primary
        },
        containedSecondary: {
          boxShadow: 0, // theme.customShadows.secondary
        },
        containedSuccess: {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.success.light,
          },
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
