export const notificationsWrapper = () => {
    return {
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexShrink: 0,
      alignSelf: 'stretch',
      padding: '24px',
      gap: '16px',
      width: '100%',
    };
  };

  export const notificationSidesheetContainerStyle = () => {
    return { 
      width: '448px !important'
    };
  };
  
  export const scrollbarWrapper = (theme) => {
    return {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '8px',
      backgroundColor: theme.palette.grey[100],
    };
  };
  export const scrollBarHeight = () => {
    return {
      height: 'calc(100vh - 150px)',
    };
  };
  
  export const timeSpanWrapper = () => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '16px',
      textTransform: 'capitalize',
      width: '100%',
    };
  };
  
  export const timeSpanStyles = (theme) => {
    return {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      width: '100%',
      padding: '8px',
    };
  };
  
  export const timeSpanText = (theme) => {
    return {
      color: theme.palette.grey[700],
      textWrap: 'nowrap',
    };
  };
  
  export const markAsReadButton = (theme) => {
    return {
      border: `1px solid ${theme.palette.grey[700]}`,
      color: theme.palette.grey[700],
      textTransform: 'uppercase',
      width: '100%',
      textAlign: 'center',
      display: 'block',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    };
  };
  
  export const unread = (theme) => {
    return {
        width: '8px',
        height: '96px',
        backgroundColor: theme.palette.info.main,
        border: `1px solid ${theme.palette.info.main}`,
        borderBottomLeftRadius: '5px',
        borderTopLeftRadius: '5px',
        '&:hover': {
            width: '24px',
            cursor: 'pointer',
        }
    }
  }
  
  export const unreadNotificationStyles = () => {
    return {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    };
  };
  
  export const notificationWrapper = (theme) => {
    return {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '96px',
      width: '100%',
      gap: '16px',
      padding: '5px 10px 5px',
      borderRadius: '5px',
      backgroundColor: theme.palette.background.paper,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    };
  };
  
  export const closeNotification = (theme) => {
    return {
      cursor: 'pointer',
      color: theme.palette.grey[700],
    };
  };
  
  export const notificationMessage = () => {
    return {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '240px',
      display: '-webkit-box',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
    };
  };
  
  export const viewNotificationStyles = () => {
    return {
      padding: 0,
      minWidth: 'auto',
      height: '20px',
    };
  };
  
  export const dismissAllButton = () => {
    return {
      marginTop: '16px',
    };
  };
  