import React from 'react';
import { FormControl, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { togglebuttonStyle } from './ToggleButtonStyles';
import { isEmpty } from 'lodash';

const ToggleButtons = (props) => {
  return (
    <div style={togglebuttonStyle()}>
      {!isEmpty(props?.options) && (
        <FormControl>
          <ToggleButtonGroup fullWidth value={props.value} exclusive onChange={(e) => props.onChange(e.target.value)} aria-label="toggle-button" disabled={props.disabled}>
            {props?.options.map((item, index) => (
              <ToggleButton key={index} value={item.value}>
                {item.label.toUpperCase()}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormControl>
      )}
    </div>
  );
};

export default ToggleButtons;
