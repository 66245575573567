import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { getProductListBody } from '../utils/clientProductUtils';
import { setErrors } from './errorHandler';

export const SEARCH_PRODUCT_LIST_LOADING = '@@clientProduct/SEARCH_PRODUCT_LIST_LOADING';
export const SEARCH_PRODUCT_LIST_SUCCESS = '@@clientProduct/SEARCH_PRODUCT_LIST_SUCCESS';
export const SEARCH_PRODUCT_LIST_FAILURE = '@@clientProduct/SEARCH_PRODUCT_LIST_FAILURE';

export const searchProductList = (vendorOrgId, productVendorId) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_LIST_LOADING });
  let body = getProductListBody(productVendorId);
  try {
    const response = await axios.post(`customer/vendor/${vendorOrgId}/product/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_PRODUCT_LIST_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: SEARCH_PRODUCT_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_LIST = '@@clientProduct/SET_PRODUCT_LIST';

export const setProductList = (val = []) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_LIST,
    payload: val
  });
}