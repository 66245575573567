import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography,Grid,Link } from '@mui/material';
import { footerSection1, footerSection2, moveToTop, copyrightStyles, footerLogo, copyrightSymbolStyle, footerNavigationStyles, navigationLinkStyles, boxStyles } from './footerStyles';
import corlLogo from 'assets/images/corl-logo-color.svg';
import { useHistory } from 'react-router-dom';
import { ABOUT_US_CORL_URL_KEY ,FOOTER_LEGAL_CONTACT_US_EMAIL_KEY,FOOTER_TECHNICAL_CONTACT_US_EMAIL_KEY,getContactUsLegalLink, getContactUsTechnicalSupportLink } from 'constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { searchAppSettings } from 'actions/appSettings';
const CommonFooter = () => {

  const history=useHistory();
  const sessionDetails = useSelector((state) => state.session.details);
  const footerTechnicalContactUsEmail = useSelector((state)=>state?.appSettings?.FooterTechnicalContactUsEmail);
  const footerLegalContactUsEmail = useSelector((state)=>state?.appSettings?.FooterLegalContactUsEmail);
  const aboutUsLink = useSelector((state)=>state?.appSettings?.AboutUsURL);
  const dispatch=useDispatch();

  const handleSupportPageClick = () => {
        history.push('/support');
  };

  useEffect(() => {
    dispatch(searchAppSettings([FOOTER_TECHNICAL_CONTACT_US_EMAIL_KEY,FOOTER_LEGAL_CONTACT_US_EMAIL_KEY,ABOUT_US_CORL_URL_KEY]))
   },[dispatch])
 
  return (
    <Box>
      <Box sx={(theme) => footerSection1(theme)}>
      <Grid container>
        <Grid item xs={4}>        
          <Box component="img" alt="CORL logo" src={corlLogo} sx={footerLogo} />       
        </Grid>
        <Grid item xs={6}>
        <Box  sx={footerNavigationStyles}>
          <Box sx={boxStyles}>       
            <Typography variant='h4' >CORL</Typography>
            <Link href={ aboutUsLink} underline="none" target='_blank'>
            <Typography variant='subtitle1' sx={(theme)=>navigationLinkStyles(theme)}   
                >About Us
            </Typography>
            </Link>    
          </Box>
          <Box sx={boxStyles} >            
            <Typography variant='h4' >Help</Typography>    
            <Link  variant="subtitle1" onClick={handleSupportPageClick} ><Typography variant='subtitle1' sx={(theme)=>navigationLinkStyles(theme)}>Support Page</Typography></Link>           
            <Link href={getContactUsTechnicalSupportLink(footerTechnicalContactUsEmail,sessionDetails?.OrgName)} underline="none"><Typography variant='subtitle1' sx={(theme)=>navigationLinkStyles(theme)}>Contact Us - Technical Support</Typography></Link>
            <Link href={getContactUsLegalLink(footerLegalContactUsEmail,sessionDetails?.OrgName)} underline="none"><Typography variant='subtitle1' sx={(theme)=>navigationLinkStyles(theme)}>Contact Us - Legal  </Typography> </Link>        
          </Box>
        </Box>
        </Grid>
      </Grid>
      </Box>
      <Box sx={(theme) => footerSection2(theme)}>
        <Box sx={(theme) => copyrightStyles(theme)}>
          <Typography variant="body2">
            Copyright
            <FontAwesomeIcon style={copyrightSymbolStyle()} icon={faCopyright} />
            CORL Technologies 2022-{new Date().getFullYear()}{' '}
          </Typography>
        </Box>
        <Box
          sx={(theme) => moveToTop(theme)}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <FontAwesomeIcon icon={faArrowUp} />
          Top
        </Box>
      </Box>
    </Box>
  );
};

export default CommonFooter;
