import {
  GET_VENDOR_QUESTIONNAIRE_RESPONSE_FAILURE,
  GET_VENDOR_QUESTIONNAIRE_RESPONSE_LOADING,
  GET_VENDOR_QUESTIONNAIRE_RESPONSE_SUCCESS,
  SET_RESPONSE_FAILURE,
  SET_RESPONSE_LOADING,
  SET_RESPONSE_SUCCESS,
} from '../actions/vendorQuestionnaireAnswers';

const initialState = {
  loading: false,
  vendorQuestionnaireAnswers: [],
  isResponseClicked: false,
  vendorDetails: [],
};

export const vendorQuestionnaireAnswers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_VENDOR_QUESTIONNAIRE_RESPONSE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_VENDOR_QUESTIONNAIRE_RESPONSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorQuestionnaireAnswers: payload,
      };
    }
    case GET_VENDOR_QUESTIONNAIRE_RESPONSE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_RESPONSE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_RESPONSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        isResponseClicked: payload.isResponseClicked,
        vendorDetails: payload.vendorDetails,
      };
    }
    case SET_RESPONSE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
