import axios from "axios";
import { searchUserGroupFeatureListBody, searchUserGroupListBody } from "utils/clientProfileUsersUtils";
import { setErrors } from "./errorHandler";
import { NO_DATA_FOUND } from "constants/errorMessage";

export const SEARCH_USER_GROUP_LIST_LOADING = "@@clientProfileUserGroup/SEARCH_USER_GROUP_LIST_LOADING";
export const SEARCH_USER_GROUP_LIST_SUCCESS = "@@clientProfileUserGroup/SEARCH_USER_GROUP_LIST_SUCCESS";
export const SEARCH_USER_GROUP_LIST_FAILURE = "@@clientProfileUserGroup/SEARCH_USER_GROUP_LIST_FAILURE";

  export const searchUserGroupList = (searchText = "", callback) => async (dispatch) => {
    dispatch({ 
      type: SEARCH_USER_GROUP_LIST_LOADING 
    });
  
    const body = searchUserGroupListBody(searchText)

    try {
      const response = await axios.post(`/usergroup/search`, body);
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
          (errorItem) => errorItem.Message !== NO_DATA_FOUND
        ); 
        dispatch({
          type: SEARCH_USER_GROUP_LIST_FAILURE,
        });
        dispatch(setErrors(errors));
      } else {
        dispatch({
          type: SEARCH_USER_GROUP_LIST_SUCCESS,
          payload: response?.data,
        });
        if(callback) {
          callback(response?.data.Data?.Rows)
        }
      }
    } catch {
      dispatch({
        type: SEARCH_USER_GROUP_LIST_FAILURE,
      });
    }
}

export const ADD_USER_GROUP_LOADING = "@@clientProfileUserGroup/ADD_USER_GROUP_LOADING";
export const ADD_USER_GROUP_SUCCESS = "@@clientProfileUserGroup/ADD_USER_GROUP_SUCCESS";
export const ADD_USER_GROUP_FAILURE = "@@clientProfileUserGroup/ADD_USER_GROUP_FAILURE";

  export const addUserGroup = (body) => async (dispatch) => {
    dispatch({ 
      type: ADD_USER_GROUP_LOADING 
    });
  
    try {
      const response = await axios.post(`/usergroup`, body);
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
          (errorItem) => errorItem.Message !== NO_DATA_FOUND
        ); 
        dispatch({
          type: ADD_USER_GROUP_FAILURE,
        });
        dispatch(setErrors(errors));
      } else {
        dispatch({
          type: ADD_USER_GROUP_SUCCESS,
          payload: response?.data,
        });
        dispatch(searchUserGroupList());
      }
    } catch {
      dispatch({
        type: ADD_USER_GROUP_FAILURE,
      });
    }
}

export const UPDATE_USER_GROUP_LOADING = "@@clientProfileUserGroup/UPDATE_USER_GROUP_LOADING";
export const UPDATE_USER_GROUP_SUCCESS = "@@clientProfileUserGroup/UPDATE_USER_GROUP_SUCCESS";
export const UPDATE_USER_GROUP_FAILURE = "@@clientProfileUserGroup/UPDATE_USER_GROUP_FAILURE";

  export const updateUserGroup = (body) => async (dispatch) => {
    dispatch({ 
      type: UPDATE_USER_GROUP_LOADING 
    });
  
    try {
      const response = await axios.put(`/usergroup/${body?.UserGroupId}`, body);
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
          (errorItem) => errorItem.Message !== NO_DATA_FOUND
        ); 
        dispatch({
          type: UPDATE_USER_GROUP_FAILURE,
        });
        dispatch(setErrors(errors));
      } else {
        dispatch({
          type: UPDATE_USER_GROUP_SUCCESS,
          payload: response?.data,
        });
        dispatch(searchUserGroupList("", () => {
          dispatch(searchUserGroupFeatureList(body?.UserGroupId));
        }));
      }
    } catch {
      dispatch({
        type: UPDATE_USER_GROUP_FAILURE,
      });
    }
}

export const SET_OPEN_USER_GROUP_SIDESHEET = '@@clientProfileUserGroup/SET_OPEN_USER_GROUP_SIDESHEET';

export const setOpenUserGroupSideSheet = ( value = false ) => async (dispatch) => {
  dispatch({
    type: SET_OPEN_USER_GROUP_SIDESHEET,
    payload: value
  })
}

export const SET_USER_GROUP_FORM_EDITABLE = "@@clientProfileUserGroup/SET_USER_GROUP_FORM_EDITABLE";

export const setUserGroupFormEditable = (isEditable = false) => async (dispatch) => {
  dispatch({
    type: SET_USER_GROUP_FORM_EDITABLE,
    isEditable
  })
}

export const SET_IS_NEW_USER_GROUP = "@@clientProfileUserGroup/SET_IS_NEW_USER_GROUP";

export const setIsNewUserGroup = (isNewGroup = false) => async (dispatch) => {
  dispatch({
    type: SET_IS_NEW_USER_GROUP,
    isNewGroup
  })
}

export const SEARCH_USER_GROUP_FEATURE_LIST_LOADING = "@@clientProfileUserGroup/SEARCH_USER_GROUP_FEATURE_LIST_LOADING";
export const SEARCH_USER_GROUP_FEATURE_LIST_SUCCESS = "@@clientProfileUserGroup/SEARCH_USER_GROUP_FEATURE_LIST_SUCCESS";
export const SEARCH_USER_GROUP_FEATURE_LIST_FAILURE = "@@clientProfileUserGroup/SEARCH_USER_GROUP_FEATURE_LIST_FAILURE";

  export const searchUserGroupFeatureList = (groupId, callback) => async (dispatch) => {
    dispatch({ 
      type: SEARCH_USER_GROUP_FEATURE_LIST_LOADING 
    });
  
    const body = searchUserGroupFeatureListBody(groupId)

    try {
      const response = await axios.post(`/usergroup/feature/search`, body);
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter(
          (errorItem) => errorItem.Message !== NO_DATA_FOUND
        ); 
        dispatch({
          type: SEARCH_USER_GROUP_FEATURE_LIST_FAILURE,
        });
        dispatch(setErrors(errors));
      } else {
        dispatch({
          type: SEARCH_USER_GROUP_FEATURE_LIST_SUCCESS,
          payload: response?.data?.Data?.Rows,
        });
        if(callback) {
          callback();
        }
      }
    } catch {
      dispatch({
        type: SEARCH_USER_GROUP_FEATURE_LIST_FAILURE,
      });
    }
}