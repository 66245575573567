import { toast } from 'react-toastify';
import {
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  SEARCH_CONTRACT_CONFIGURATION_DETAILS_FAILURE,
  SEARCH_CONTRACT_CONFIGURATION_DETAILS_SUCCESS,
  SEARCH_CONTRACT_CONFIGURATION_DETAILS_LOADING,
  GET_SWAGGER_DOC_LOADING,
  GET_SWAGGER_DOC_SUCCESS,
  GET_SWAGGER_DOC_FAILURE,
} from '../actions/client';
import { OPEN_API_VERSION } from 'constants/constants';

const initialState = {
  loading: false,
  contractLoading: false,
  error: '',
  clientId: '',
  clientName: '',
  taxIdNumber: '',
  domainName: '',
  registeredBusinessName: '',
  registeredBusinessAddress: '',
  primaryContactFirstName: '',
  primaryContactLastName: '',
  primaryContactEmail: '',
  latestContractConfiguration: {},
  swaggerDoc: {},
};

const renameKeys = (obj) => {
  const keyValues = Object.entries(obj).map(([key, value]) => {
    let newKey1 = key?.substring(0, 1);
    let newKey2 = key?.substring(1);
    let newKey = newKey1.toLowerCase() + newKey2;
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      value = renameKeys(value);
    }
    return [newKey, value];
  });
  return Object.fromEntries(keyValues);
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_CONTRACT_CONFIGURATION_DETAILS_LOADING: {
      return {
        ...state,
        contractLoading: true,
      };
    }

    case GET_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }

    case GET_CLIENT_FAIL: {
      toast.error(payload.message);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_CONTRACT_CONFIGURATION_DETAILS_SUCCESS: {
      return {
        ...state,
        contractLoading: false,
        latestContractConfiguration: payload,
      };
    }
    case SEARCH_CONTRACT_CONFIGURATION_DETAILS_FAILURE: {
      return {
        ...state,
        contractLoading: false,
      };
    }

    case GET_SWAGGER_DOC_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SWAGGER_DOC_SUCCESS: {
      payload.openapi = OPEN_API_VERSION;
      const data = renameKeys(payload);
      return {
        ...state,
        loading: false,
        swaggerDoc: data,
      };
    }

    case GET_SWAGGER_DOC_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
