import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Stack, Box, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    menuPanelWrapper,
    menuPanelLinksWrapper,
    navigationWrapper,
    navigationLinkLable,
    quickActionsBtn,
    buttonLabelStyles,
    actionButtonStyle,
} from './NavBarStyles';
import { getIcon } from './util';
import { setMenuPanelId } from 'actions/navbar';
import { useDispatch } from 'react-redux';
import useHasClaims from 'hooks/useHasClaims';
import { USER_CLAIMS } from 'constants/userClaims';
import { ASSESSMENT_OVERVIEW, PROGRAM_METRICS, REPORT_INCIDENT_BUTTON, REQUEST_ASSESSMENT } from 'constants/constants';

const MenuPanel = ({ subMenuList, openReportIncidentPopup }) => {
    const dispatch = useDispatch();
    const [showMenuPanel, setShowMenuPanel] = useState(true);
    const [menuPanelSubMenuList, setMenuPanelSubMenuList] = useState([])

    const hasAssessmentStatusReadAccess = useHasClaims([USER_CLAIMS.ASSESSMENT_STATUS_READ]);
    const hasAssessmentRequestCreateAccess = useHasClaims([USER_CLAIMS.ASSESSMENT_REQUEST_CREATE]);
    const hasAssessmentQueueReadAccess = useHasClaims([USER_CLAIMS.ASSESSMENT_REQUEST_READ]);
    const hasVendorEditAccess = useHasClaims([USER_CLAIMS.VENDOR_UPDATE]);

    useEffect(() => {
        let tempSubmenuList = subMenuList;
        if (!hasAssessmentStatusReadAccess) {
            tempSubmenuList = subMenuList?.filter(menu => menu.AppMenuName?.toLowerCase() !== PROGRAM_METRICS)
        }
        if (!hasAssessmentQueueReadAccess && !hasAssessmentStatusReadAccess) {
            tempSubmenuList = tempSubmenuList?.filter(menu => menu.AppMenuName?.toLowerCase() !== ASSESSMENT_OVERVIEW)
        }
        setMenuPanelSubMenuList(tempSubmenuList)
    }, [hasAssessmentStatusReadAccess, hasAssessmentQueueReadAccess, hasAssessmentStatusReadAccess])

    const reportIncidentClicked = (e) => {
        setShowMenuPanel(false);
        openReportIncidentPopup();
        e.stopPropagation();
    };

    const onClickSuMenu = (e, menuPanelValue) => {
        setShowMenuPanel(menuPanelValue);
        e.stopPropagation();
    };

    return (
        <>
            <Stack sx={(theme) => menuPanelWrapper(theme, { showMenuPanel })} onMouseLeave={() => dispatch(setMenuPanelId(0))} onClick={(e) => e.stopPropagation()}>
                <Box sx={menuPanelLinksWrapper}>
                    {menuPanelSubMenuList?.map((submenu, index) => {
                        const { AppMenuName, MenuRoute, ButtonLabel, ButtonRoute, ButtonActionClaim, ButtonIcon } = submenu;
                        return (
                            <Box sx={navigationWrapper} key={index}>
                                <Link to={MenuRoute} onClick={(e) => onClickSuMenu(e, false)}>
                                    <Typography variant="h3" sx={navigationLinkLable}>
                                        {AppMenuName}
                                    </Typography>
                                </Link>
                                {ButtonActionClaim &&
                                    ButtonLabel &&
                                    (ButtonLabel !== '+ REPORT INCIDENT' ? (
                                        (ButtonLabel.toLowerCase() !== REQUEST_ASSESSMENT || hasAssessmentRequestCreateAccess) && <Link to={ButtonRoute} onClick={(e) => onClickSuMenu(e, false)}>
                                            <Button variant="contained" size="small" sx={actionButtonStyle} className="activeNavBarButton"
                                                startIcon={<FontAwesomeIcon icon={getIcon(ButtonIcon)} />}
                                            >
                                                <Typography sx={buttonLabelStyles}>{ButtonLabel}</Typography>
                                            </Button>
                                        </Link>
                                    ) : (
                                        (ButtonLabel.toLowerCase() === REPORT_INCIDENT_BUTTON && hasVendorEditAccess) && (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                startIcon={<FontAwesomeIcon icon={getIcon(ButtonIcon)} />}
                                                sx={quickActionsBtn}
                                                onClick={reportIncidentClicked}
                                            >
                                                {ButtonLabel}
                                            </Button>
                                        )
                                    ))}
                            </Box>
                        );
                    })}
                </Box>
            </Stack>
        </>
    );
};

export default MenuPanel;
