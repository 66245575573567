import { DATE_FORMAT } from 'constants/constants';
import {
  GET_CLIENT_DOCUMENTED_INCIDENTS_FAILURE,
  GET_CLIENT_DOCUMENTED_INCIDENTS_LOADING,
  GET_CLIENT_DOCUMENTED_INCIDENTS_SUCCESS,
  SET_SELECTED_INCIDENTS_FAILURE,
  SET_SELECTED_INCIDENTS_LOADING,
  SET_SELECTED_INCIDENTS_SUCCESS,
} from '../actions/documentedIncidents';
import { convertToLocalDateTime } from '../components/Common/TimeFunctions';
import { isNil } from 'lodash';

const initialState = {
  loading: false,
  documentedIncidents: [],
  selectedIncidents: [],
};

export const documentedIncidents = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENT_DOCUMENTED_INCIDENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CLIENT_DOCUMENTED_INCIDENTS_SUCCESS: {
      // eslint-disable-next-line no-unused-vars
      const newArray = payload.slice(0, 5);
      payload.forEach((item, index) => {
        payload[index] = { ...payload[index], index };
      });
      return {
        ...state,
        loading: false,
        documentedIncidents: payload.map((item) => {
          return {
            ...item,
            DateAdded: !isNil(item.DateFirstReported) && convertToLocalDateTime(item.DateFirstReported, DATE_FORMAT),
            DateUpdated: !isNil(item.UpdateTimeStamp) && convertToLocalDateTime(item.UpdateTimeStamp, DATE_FORMAT),
          };
        }),
      };
    }
    case GET_CLIENT_DOCUMENTED_INCIDENTS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_SELECTED_INCIDENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_SELECTED_INCIDENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedIncidents: payload,
      };
    }
    case SET_SELECTED_INCIDENTS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};
