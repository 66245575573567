import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { NO_DASHBOARD_DATA_FOUND, NO_DATA_FOUND } from '../constants/errorMessage';

export const POST_DYNAMIC_VENDOR_ROSTER_LOADING = '@@dynamicVendorRoster/POST_DYNAMIC_VENDOR_ROSTER_LOADING';
export const POST_DYNAMIC_VENDOR_ROSTER_SUCCESS = '@@dynamicVendorRoster/POST_DYNAMIC_VENDOR_ROSTER_SUCCESS';
export const POST_DYNAMIC_VENDOR_ROSTER_FAILURE = '@@dynamicVendorRoster/POST_DYNAMIC_VENDOR_ROSTER_FAILURE';

export const getDynamicVendorRoster = () => async (dispatch) => {
  dispatch({ type: POST_DYNAMIC_VENDOR_ROSTER_LOADING });
  const body = JSON.stringify({ QuicksightDashboardId: 2 });
  try {
    const response = await axios.post('app/customer/dashboard/action/geturl', body);
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors[0].Message === NO_DATA_FOUND) {
        dispatch(setErrors(NO_DASHBOARD_DATA_FOUND));
      } else {
        dispatch(setErrors(response?.data?.Errors));
      }
      dispatch({
        type: POST_DYNAMIC_VENDOR_ROSTER_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: POST_DYNAMIC_VENDOR_ROSTER_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_DYNAMIC_VENDOR_ROSTER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
