import axios from 'axios';
import { getClientProfileIncidentDetailsBody } from '../utils/incidentDetailsUtils';

export const GET_CLIENT_INCIDENT_DETAILS_LOADING = '@@clientProfileIncidentDetails/GET_CLIENT_INCIDENT_DETAILS_LOADING';
export const GET_CLIENT_INCIDENT_DETAILS_SUCCESS = '@@clientProfileIncidentDetails/GET_CLIENT_INCIDENT_DETAILS_SUCCESS';
export const GET_CLIENT_INCIDENT_DETAILS_FAILURE = '@@clientProfileIncidentDetails/GET_CLIENT_INCIDENT_DETAILS_FAILURE';

export const getClientProfileIncidentDetails = (incidentId) => async (dispatch) => {
  dispatch({
    type: GET_CLIENT_INCIDENT_DETAILS_LOADING,
  });

  var body = getClientProfileIncidentDetailsBody(incidentId);

  try {
    const response = await axios.post(`/incident/search`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_CLIENT_INCIDENT_DETAILS_FAILURE,
        payload: {},
      });
      // dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: GET_CLIENT_INCIDENT_DETAILS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: GET_CLIENT_INCIDENT_DETAILS_FAILURE,
      payload: {},
    });
  }
};

export const SET_INCIDENT_LOADING = '@@clientProfileIncidentDetails/SET_INCIDENT_LOADING';
export const SET_INCIDENT_SUCCESS = '@@clientProfileIncidentDetails/SET_INCIDENT_SUCCESS';
export const SET_INCIDENT_FAILURE = '@@clientProfileIncidentDetails/SET_INCIDENT_FAILURE';

export const setincident = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_INCIDENT_LOADING,
  });
  try {
    dispatch({
      type: SET_INCIDENT_SUCCESS,
      payload: payload,
    });
    if (callback) {
      callback();
    }
  } catch {
    dispatch({
      type: SET_INCIDENT_FAILURE,
      payload: {},
    });
  }
};
