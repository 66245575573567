import { isEmpty } from 'lodash';
import { CLIENT_APP_ID } from '../constants/users';

export const searchClientUsersBody = (searchItem) => {
  const criterion = [];
  if (!isEmpty(searchItem)) {
    criterion.push({
      field: 'FirstName',
      operator: 'startswith',
      value: searchItem,
    });
  }
  return {
    intent: '',
    fields: [
      'UserId',
      'UserEmail',
      'FirstName',
      'MiddleName',
      'LastName',
      'NamePrefix',
      'NameSuffix',
      'ProfilePictureUrl',
      'ExternalIdpId',
      'ServiceProviderUserList',
      'ServiceProviderOrgName',
      'Title',
      "ContactId",
      "CellPhone",
      "OfficePhone",
      "MainPhone",
      "PhoneExtension",
      "UserRoleId",
      "UserRoleName",
      "UserGroupId",
      "UserGroupName"
    ],
    criterion: criterion,
    sort: [
      {
        field: 'FirstName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchUserRolesListBody = (searchItem) => {
  const criterion = [];
  if (!isEmpty(searchItem)) {
    criterion.push({
      field: 'UserRoleName',
      operator: 'startswith',
      value: searchItem,
    });
  }
  return {
    intent: '',
    fields: ['UserRoleId', 'AppId', 'UserRoleName', 'AppName'],
    criterion: [
      ...criterion,
      {
        field: 'AppId',
        operator: '=',
        value: CLIENT_APP_ID,
      },
      {
        field: 'Active',
        operator: '=',
        value: 1,
      },
    ],
    sort: [],
  };
};

export const searchUserRoleListOfUserBody = (userId) => {
  return {
    intent: '',
    fields: ['UserRoleId', 'AppId', 'UserRoleName', 'AppName'],
    criterion: [
      {
        field: 'UserId',
        operator: '=',
        value: userId,
      },
    ],
    sort: [],
  };
};

export const addUserRoleToUserBody = (userRoleId, userId, oldUserRoleId) => {
  return {
    UserRoleId: userRoleId,
    UserId: userId,
    OldUserRoleId: oldUserRoleId,
  };
};

export const searchUserGroupListBody = (searchItem) => {
  const criterion = [];
  if (!isEmpty(searchItem)) {
    criterion.push({
      "field": "UserGroupName",
      "operator": "contains",
      "value": searchItem
    })
  }
  return {
    "intent": "",
    "fields": [
      "UserGroupId",
      "UserGroupName",
      "IsSystem"
    ],
    "criterion": [
      ...criterion,
    ],
    "sort": []
  }
}

export const searchUserGroupFeatureListBody = (groupId) => {
  return {
    "intent": "AssessmentAndTpir",
    "fields": [
        "AppRoleId",
        "AppRoleSystemName",
        "UserGroupName",
        "Active",
        "IsSystem"
    ],
    "criterion": [{
      "field": "UserGroupId",
      "operator": "=",
      "value": groupId ?? 1
    },{
      "field": "AppId",
      "operator": "=",
      "value": 3
    } ],
    "sort": [],
    "page": 0,
    "pageSize": 0
  }
}