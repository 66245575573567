export const USERS_TAB_OPTIONS = {
  USERS: 'USERS',
  USER_GROUPS: 'USER GROUPS',
};

export const USERS_TAB_LIST = [
  {
    label: USERS_TAB_OPTIONS.USERS,
    value: USERS_TAB_OPTIONS.USERS,
  },
  {
    label: USERS_TAB_OPTIONS.USER_GROUPS,
    value: USERS_TAB_OPTIONS.USER_GROUPS,
  },
];

export const ADMIN_ROLE = 'admin';
export const GLOBAL_ADMIN = 'Global Admin';
export const GROUP_MANAGER = 'Group Manager' ;
