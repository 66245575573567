import axios from 'axios';
import { getClientProfileDocumentedIncidentsBody } from '../utils/documentedIncidentsUtils';

export const GET_CLIENT_DOCUMENTED_INCIDENTS_LOADING = '@@clientProfileDocumentedIncidents/GET_CLIENT_DOCUMENTED_INCIDENTS_LOADING';
export const GET_CLIENT_DOCUMENTED_INCIDENTS_SUCCESS = '@@clientProfileDocumentedIncidents/GET_CLIENT_DOCUMENTED_INCIDENTS_SUCCESS';
export const GET_CLIENT_DOCUMENTED_INCIDENTS_FAILURE = '@@clientProfileDocumentedIncidents/GET_CLIENT_DOCUMENTED_INCIDENTS_FAILURE';

export const getClientProfileDocumentedIncidents = () => async (dispatch) => {
  dispatch({
    type: GET_CLIENT_DOCUMENTED_INCIDENTS_LOADING,
  });

  var body = getClientProfileDocumentedIncidentsBody();

  try {
    const response = await axios.post(`incident/search`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_CLIENT_DOCUMENTED_INCIDENTS_FAILURE,
        payload: {},
      });
      // dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: GET_CLIENT_DOCUMENTED_INCIDENTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: GET_CLIENT_DOCUMENTED_INCIDENTS_FAILURE,
      payload: {},
    });
  }
};

export const SET_SELECTED_INCIDENTS_LOADING = '@@clientProfileDocumentedIncidents/SET_SELECTED_INCIDENTS_LOADING';
export const SET_SELECTED_INCIDENTS_SUCCESS = '@@clientProfileDocumentedIncidents/SET_SELECTED_INCIDENTS_SUCCESS';
export const SET_SELECTED_INCIDENTS_FAILURE = '@@clientProfileDocumentedIncidents/SET_SELECTED_INCIDENTS_FAILURE';

export const setSelectedIncidents = (incidents) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_INCIDENTS_LOADING,
  });
  try {
    dispatch({
      type: SET_SELECTED_INCIDENTS_SUCCESS,
      payload: incidents,
    });
  } catch {
    dispatch({
      type: SET_SELECTED_INCIDENTS_FAILURE,
      payload: {},
    });
  }
};
