export const EDIT = 'edit';
export const NEW = 'new';

export const YES = 'Yes';
export const NO = 'No';

export const LOGGED = 'Logged';
export const OUTREACH = 'Outreach';
export const INREVIEW = 'In Review';
export const MONITORING = 'Monitoring';
export const CLOSED = 'Closed';

export const WAITING_ON_SUPPLIER = 'WAITING ON VENDOR';
export const UNREVIEWED = 'UNREVIEWED';
export const QUESTIONNAIRE_STATUS_QA_APPROVED = 23;

export const NO_ROLES_ASSIGNED_MESSAGE = 'No roles are assigned to the user.';
