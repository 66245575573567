export const LOGIN_FORM = 'LOGIN_FORM';
export const COMPANY_INFORMATION_FORM = 'COMPANY_INFORMATION_FORM';
export const CLIENT_CONTACT_INFORMATION_FORM = 'CLIENT_CONTACT_INFORMATION_FORM';
export const INCIDENT_NOTIFICATION_FORM = 'INCIDENT_NOTIFICATION_FORM';
export const VIEW_AS_VENDOR_FORM = 'VIEW_QUESTIONS_FORM';
export const OUTREACH_FORM = 'OUTREACH_FORM';
export const USERS_FORM = 'USERS_FORM';
export const ASSESSMENT_REQUEST_FORM = 'ASSESSMENT_REQUEST_FORM';
export const ASSESSMENT_REQUEST_REVIEW_FORM = 'ASSESSMENT_REQUEST_REVIEW_FORM';
export const ASSESSMENTS_OVERVIEW_FILTERS_FORM = 'ASSESSMENTS_OVERVIEW_FILTERS_FORM';
export const ASSESSMENTS_HELP_TICKET_FORM = 'ASSESSMENTS_HELP_TICKET_FORM';
export const CREATE_SUPPORT_TICKET_FORM = 'CREATE_SUPPORT_TICKET_FORM';
export const SUPPORT_TICKET_DETAILS_FORM = 'SUPPORT_TICKET_DETAILS_FORM';
export const FOLLOW_UP_SUPPORT_TICKET_FORM = 'FOLLOW_UP_SUPPORT_TICKET_FORM';
export const VENDORS_AND_PRODUCTS_FILTERS_FORM = 'VENDORS_AND_PRODUCTS_FILTERS_FORM';
export const VENDOR_TABLE_SIDESHEET_FORM = 'VENDOR_TABLE_SIDESHEET_FORM';
export const DELETE_CONFIRMATION_POPUP_FORM = 'DELETE_CONFIRMATION_POPUP_FORM';
export const ADD_VENDORS_AND_PRODUCTS_FORM = 'ADD_VENDORS_AND_PRODUCTS_FORM';
export const REMEDIATION_SIDE_SHEET_FORM = 'REMEDIATION_SIDE_SHEET_FORM';
export const EXPORT_FILE_TYPE_FORM = 'EXPORT_FILE_TYPE_FORM';
export const ES_FEEDBACK_FORM = 'ES_FEEDBACK_FORM';
export const API_MANAGER_SIDESHEET_FORM = 'API_MANAGER_SIDESHEET_FORM';
export const API_MANAGER_CREATE_NEW_KEY_FORM = 'API_MANAGER_CREATE_NEW_KEY_FORM';
export const USERS_SIDESHEET_FORM = "USERS_SIDESHEET_FORM";
export const USER_GROUP_SIDESHEET_FORM = "USER_GROUP_SIDESHEET_FORM";
export const GRC_API_DOCUMENT_FORM = 'GRC_API_DOCUMENT_FORM';
export const UPDATE_ASSESSMENT_QUEUE_FORM = 'UPDATE_ASSESSMENT_QUEUE_FORM';
export const VENDORS_CATALOG_FILTERS_FORM = 'VENDORS_CATALOG_FILTERS_FORM';
export const PRODUCT_TABLE_SIDESHEET_FORM = 'PRODUCT_TABLE_SIDESHEET_FORM';
