export const getProductListBody = (productVendorId) => {
  return {
    Intent: '',
    Fields: [
      'ProductName',
      'ProductVersion',
      'ProductType',
      'ProductGroup',
      'Description',
      'ProductVendorId',
      'CustomerVendorId',
      'CustomerVendorProductId',
      'Oem',
    ],
    Criterion: [
      {
        Field: 'ProductVendorId',
        Operator: '=',
        Value: productVendorId,
      },
    ],
    Sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    Page: 0,
    PageSize: 0,
  };
};
