export const getClientProfileActiveOutreachesBody = () => {
  return {
    Intent: '',
    Fields: [
      'RequestorOrgId',
      'OutreachId',
      'OutreachName',
      'Description',
      'StartDate',
      'EndDate',
      'IncidentId',
      'CountOfRecipientOrgsSentAssessment',
      'CountOfRecipientOrgsResponded',
      'IsTPIRSubscribed',
      'IsCORLCleared',
    ],
    Criterion: [
      {
        Field: 'OutreachStatus',
        Operator: '=',
        Value: '3' /* In Progress */,
      },
      {
        Field: 'OutreachTypeId',
        Operator: '=',
        Value: '1' /* Incident Outreach */,
      },
    ],
    Sort: [
      {
        Field: 'StartDate',
        Order: 'DESC',
      },
    ],
    Page: 0,
    PageSize: 0,
  };
};

export const getOutreachBody = (OutreachId) => {
  return {
    Intent: '',
    Fields: ['OutreachId', 'OutreachRequestorId', 'RequestorOrgId', 'RequestorOrgName'],
    Criterion: [
      {
        Field: 'OutreachId',
        Operator: '=',
        Value: OutreachId,
      },
      {
        Field: 'OutreachStatus',
        Operator: 'in',
        Value: '2, 3',
      },
      {
        Field: 'OutreachTypeId',
        Operator: '=',
        Value: 1,
      },
    ],
    Sort: [{ field: 'RequestorOrgName', order: 'ASC' }],
    Page: 0,
    PageSize: 0,
  };
};
