import axios from 'axios';
import { getErrorObject } from 'utils/apiUtils';
import { getAssessmentActivityBody, getAssessmentAgeBody, getAssessmentMetricsBody, getAssessmentRiskRatingBody, getEscalatedAssessmentBody } from 'utils/commandCenterUtils';

export const SEARCH_ASSESSMENT_ACTIVITY_LOADING = '@commandCenter/SEARCH_ASSESSMENT_ACTIVITY_LOADING';
export const SEARCH_ASSESSMENT_ACTIVITY_SUCCESS = '@commandCenter/SEARCH_ASSESSMENT_ACTIVITY_SUCCESS';
export const SEARCH_ASSESSMENT_ACTIVITY_FAILURE = '@commandCenter/SEARCH_ASSESSMENT_ACTIVITY_FAILURE';

export const getAssessmentActivityList = () => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_ACTIVITY_LOADING });
  try {
    const body = getAssessmentActivityBody();
    const response = await axios.post(`/legacy/assessment/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_ACTIVITY_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_ACTIVITY_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_ACTIVITY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_AGE_LOADING = '@commandCenter/SEARCH_ASSESSMENT_AGE_LOADING';
export const SEARCH_ASSESSMENT_AGE_SUCCESS = '@commandCenter/SEARCH_ASSESSMENT_AGE_SUCCESS';
export const SEARCH_ASSESSMENT_AGE_FAILURE = '@commandCenter/SEARCH_ASSESSMENT_AGE_FAILURE';

export const getAssessmentAgeList = () => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_AGE_LOADING });
  try {
    const body = getAssessmentAgeBody();
    const response = await axios.post(`/legacy/assessment/commandcenter/assessmentage/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_AGE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_AGE_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_AGE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_RISK_RATING_LOADING = '@commandCenter/SEARCH_ASSESSMENT_RISK_RATING_LOADING';
export const SEARCH_ASSESSMENT_RISK_RATING_SUCCESS = '@commandCenter/SEARCH_ASSESSMENT_RISK_RATING_SUCCESS';
export const SEARCH_ASSESSMENT_RISK_RATING_FAILURE = '@commandCenter/SEARCH_ASSESSMENT_RISK_RATING_FAILURE';

export const getAssessmentRiskRatingList = () => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_RISK_RATING_LOADING });
  try {
    const body = getAssessmentRiskRatingBody();
    const response = await axios.post(`/legacy/assessment/commandcenter/riskrating/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_RISK_RATING_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_RISK_RATING_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_RISK_RATING_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_METRICS_LOADING = '@commandCenter/SEARCH_ASSESSMENT_METRICS_LOADING';
export const SEARCH_ASSESSMENT_METRICS_SUCCESS = '@commandCenter/SEARCH_ASSESSMENT_METRICS_SUCCESS';
export const SEARCH_ASSESSMENT_METRICS_FAILURE = '@commandCenter/SEARCH_ASSESSMENT_METRICS_FAILURE';

export const getAssessmentMetricsList = () => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_METRICS_LOADING });
  try {
    const body = getAssessmentMetricsBody();
    const response = await axios.post(`/legacy/assessment/commandcenter/metrics/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_METRICS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_METRICS_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_METRICS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const SEARCH_ESCALATED_ASSESSMENT_LIST_LOADING = '@commandCenter/SEARCH_ESCALATED_ASSESSMENT_LIST_LOADING';
export const SEARCH_ESCALATED_ASSESSMENT_LIST_SUCCESS = '@commandCenter/SEARCH_ESCALATED_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_ESCALATED_ASSESSMENT_LIST_FAILURE = '@commandCenter/SEARCH_ESCALATED_ASSESSMENT_LIST_FAILURE';

export const getEscalatedAssessmentList = () => async (dispatch) => {
    dispatch({ type: SEARCH_ESCALATED_ASSESSMENT_LIST_LOADING });
    try {
      const body = getEscalatedAssessmentBody();
      const response = await axios.post(`/legacy/assessment/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ESCALATED_ASSESSMENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ESCALATED_ASSESSMENT_LIST_SUCCESS,
          payload: response.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ESCALATED_ASSESSMENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };