import { isArray } from 'lodash';
import { useSelector } from 'react-redux';

const useHasClaims = (claims) => {
  const userClaimList = useSelector((state) => state?.session?.details?.FeatureActionClaimList);
  return hasClaims(claims, userClaimList);
};

export default useHasClaims;

export const hasClaims = (claims, userClaimList, shouldHaveAllClaims = false) => {
  if (isArray(userClaimList)) {
    if (shouldHaveAllClaims) {
      // Check if all claims are present in userClaimList
      for (const claim of claims) {
        if (!userClaimList.some(userClaim => userClaim?.ActionKey?.toUpperCase() === claim?.toUpperCase())) {
          return false; // If any claim is missing, return false
        }
      }
      return true; // All claims are present
    } else {
      // Check if at least one claim is present in userClaimList
      for (const claim of claims) {
        if (userClaimList.filter((userClaim) => userClaim?.ActionKey?.toUpperCase() === claim?.toUpperCase()).length > 0) {
          return true;
        }
      }
      return false; // No claim found
    }
  }

  return false; // userClaimList is not an array or empty
}

