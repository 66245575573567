import { ESCALATED_DELIVERY_STATUS } from "constants/constants";

export const getAssessmentActivityBody = () => {
  let criterion = [];

  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'LastUpdated',
      'CommandCenter',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'Source',
      'DisplayExecutiveSummaryLink'
    ],
    criterion: criterion,
    sort: [
      {
        field: 'LastUpdated',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getEscalatedAssessmentBody = () => {
  let criterion = [
    {
      field: "DeliveryStatus",
      operator: "=",
      value: ESCALATED_DELIVERY_STATUS
    }
  ];

  return {
    intent: 'Dynamic',
    fields: [
      "ClientOrgId",
      "AssessmentName",
      "ProductId",
      "ProductName",
      "VendorId",
      "VendorName",
      "MasterStepName",
      "AssessmentStage",
      "AssessmentStartDate",
      "AssessmentCompletedDate",
      "LastUpdated",
      "CommandCenter",
      "DeliveryStatus",
      "DeliveryStatusName",
      "ParentAssessmentId",
      "Source"
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  }
}

export const getAssessmentAgeBody = () => {
  let criterion = [];

  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'LastUpdated',
      'ParentAssessmentId',
      'AssessmentStage',
      'AssessmentDisplayStatus',
      'Owner',
      'CountOfActivelyAssessed',
      'CountOfCompletelyAssessed',
      'CountOfCompletedAssessedLessThanYear',
      'CountOfCompletedAssessedOneTwoYear',
      'CountOfCompletedAssessedGreaterThanTwoYear',
      'CountOfWaitingOnVendorResponse',
      'CountOfCorlReviewing'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentMetricsBody = () => {
  let criterion = [];

  return {
    intent: '',
    fields: [
      'MasterStepName',
      'Owner',
      'AssessmentStage',
      'DisplayResultAndEvidence',
      'AssessmentDisplayStatus',
      'LastUpdated',
      'ParentAssessmentId',
      'CountOfWaitingOnVendors',
      'CountOfWaitingOnVendors14DaysAgo',
      'CountOfNeedYourFeedback',
      'CountOfNeedYourFeedback7DaysAgo',
      'CountOfAssessmentResultsDelivered',
      'CountOfAssessmentResultsDelivered30DaysAgo',
      'CountOfAssessmentResultsDeliveredThisWeek',
      'CountOfQuestionnaireResponse',
      'CountOfRemediationResponse',
      'CountOfAssessmentResultsDelivered12MonthsAgo'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentRiskRatingBody = () => {
  let criterion = [];

  return {
    intent: '',
    fields: [
      'TotalCountOfRiskRating',
      'PercentageOfManagedRiskRating',
      'PercentageOfLowRiskRating',
      'PercentageOfMediumRiskRating',
      'PercentageOfHighRiskRating',
      'PercentageOfVeryHighRiskRating',
      'ManagedRiskRatingRecords',
      'LowRiskRatingRecords',
      'MediumRiskRatingRecords',
      'HighRiskRatingRecords',
      'VeryHighRiskRatingRecords'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
