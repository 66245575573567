export const ASSESSMENT_REQUEST_READER = 'ASSESSMENT_REQUEST_READER';
export const ASSESSMENT_REQUEST_EDITOR = 'ASSESSMENT_REQUEST_EDITOR';
export const ASSESSMENT_STATUS_READER = 'ASSESSMENT_STATUS_READER';
export const ASSESSMENT_STATUS_EDITOR = 'ASSESSMENT_STATUS_EDITOR';
export const ASSESSMENT_RESULTS_READER = 'ASSESSMENT_RESULTS_READER';
export const ASSESSMENT_VENDOR_RESPONSES_READER = 'ASSESSMENT_VENDOR_RESPONSES_READER';
export const REMEDIATION_READER = 'REMEDIATION_READER';
export const REMEDIATION_EDITOR = 'REMEDIATION_EDITOR';
export const TPIR_VENDOR_READER = 'VENDOR_READER';
export const TPIR_VENDOR_EDITOR = 'VENDOR_EDITOR';

export const ASSESSMENT_REQUEST_PERMISSIONS = [ ASSESSMENT_REQUEST_READER, ASSESSMENT_REQUEST_EDITOR ]
export const ASSESSMENT_STATUS_PERMISSIONS = [ ASSESSMENT_STATUS_READER, ASSESSMENT_STATUS_EDITOR ]
export const ASSESSMENT_RESULTS_PERMISSIONS = [ ASSESSMENT_RESULTS_READER ]
export const ASSESSMENT_VENDOR_RESPONSES_PERMISSIONS  = [ ASSESSMENT_VENDOR_RESPONSES_READER ]
export const REMEDIATION_PERMISSIONS = [ REMEDIATION_READER, REMEDIATION_EDITOR ]
export const TPIR_VENDOR_PERMISSIONS = [ TPIR_VENDOR_READER, TPIR_VENDOR_EDITOR ]

export const REQUESTS_CHECKBOX_LIST = [
    {
      id: 1,
      value: ASSESSMENT_REQUEST_READER
    },
    {
      id: 2,
      value: ASSESSMENT_REQUEST_EDITOR
    }
]

export const STATUS_CHECKBOX_LIST = [
    {
        id: 1,
        value: ASSESSMENT_STATUS_READER
    },
    {
        id: 2,
        value: ASSESSMENT_STATUS_EDITOR
    }
]

export const RESULTS_CHECKBOX_LIST = [
    {
      id: 1,
      value: ASSESSMENT_RESULTS_READER
    },
]

export const VENDOR_RESPONSES_CHECKBOX_LIST = [
    {
      id: 1,
      value: ASSESSMENT_VENDOR_RESPONSES_READER
    },
]

export const REMEDIATION_CHECKBOX_LIST = [
    {
        id: 1,
        value: REMEDIATION_READER
    },
    {
        id: 2,
        value: REMEDIATION_EDITOR
    }
]

export const TPIR_CHECKBOX_LIST = [
    {
        id: 1,
        value: TPIR_VENDOR_READER
    },
    {
        id: 2,
        value: TPIR_VENDOR_EDITOR
    }
]

export const groupNamePermissionFieldHeaders = [
    {
        label: "Requests"
    },
    {
        label: "Status"
    },
    {
        label: "Results"
    },
    {
        label: "Vendor Responses"
    },
    {
        label: "Remediation"
    }
]

export const SYSTEM_USER_GROUP = 1;
export const ACTIVE = 1;