import { isNil } from 'lodash';

export const getVendorInformationSearchBody = (vendorOrgId) => {
  return {
    intent: '',
    fields: [
      'VendorOrgId',
      'VendorOrgName',
      'DomainName',
      'YearFounded',
      'NumberOfEmployeesRange',
      'RegisteredBusinessAddressLine1',
      'RegisteredBusinessAddressLine2',
      'RegisteredBusinessAddressCity',
      'RegisteredBusinessAddressState',
      'RegisteredBusinessAddressCountry',
      'RegisteredBusinessAddressZip',
      'Phone',
    ],
    criterion: [
      {
        field: 'VendorOrgId',
        operator: isNil(vendorOrgId) ? 'isnull' : '=',
        value: isNil(vendorOrgId) ? '' : vendorOrgId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
