import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { isNil } from 'lodash';

const CheckboxesGroup = (props) => {
  const [checkedValues, setCheckedValues] = useState(props.value || []);

  const handleChange = (e) => {
    const { value } = e.target;
    const { onChange } = props;

    if(!isNil(checkedValues)) {
      let updatedOptions = [...checkedValues];
      if (checkedValues.includes(value)) {
        updatedOptions = checkedValues.filter((item) => item !== value);
      } else {
        updatedOptions = [...checkedValues, value];
      }
  
      setCheckedValues(updatedOptions);
      onChange(updatedOptions);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
    {props.options?.map((item) => {
     return (
        <FormControlLabel
        key={item.id}
        control={<Checkbox />}
        label={item.label}
        value={item.value}
        checked={checkedValues.includes(item.value.toString())}
        onChange={props?.onChangeSelection ? (e) => props?.onChangeSelection(e, props, setCheckedValues) : handleChange}        
        disabled={props.disabled}
        className="checkbox-item"
      />
      )}
    )}
  </div>
  );
};

export default CheckboxesGroup;
