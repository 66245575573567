import { Box, Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import Seperator from '../../assets/icons/breadcrumbs-seperator.svg';
import { bannerContainerWrap, bannerTitleStyles, breadcrumbLabel, buttonsGrid, breadCrumbs } from './bannerStyle';

const Banner = ({ title, buttonLabel, breadcrumbs, onClickButton, icon }) => {
  return (
    <>
      <Box sx={bannerContainerWrap}>
        <Grid container>
          <Grid item xs={8}>
            {breadcrumbs && (
              <Breadcrumbs aria-label="breadcrumb" sx={breadCrumbs} separator={<img alt="" src={Seperator} />}>
                {breadcrumbs &&
                  breadcrumbs.map((breadcrumb, i) => {
                    return (
                      <Link key={i} underline="hover" color="inherit" href={breadcrumb.url} sx={breadcrumbLabel}>
                        {breadcrumb.text}
                      </Link>
                    );
                  })}
              </Breadcrumbs>
            )}
            {title && <Typography variant='display3' sx={(theme) => bannerTitleStyles(theme)}>{title}</Typography>}
          </Grid>

          <Grid item xs={4} sx={buttonsGrid}>
            {buttonLabel && (
              <Button variant="contained" onClick={onClickButton} startIcon={icon}>
                <Typography variant='caption'>{buttonLabel}</Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Banner;
