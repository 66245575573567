export const autoCompleteStyles = () => {
  return {
    boxShadow: '-4px -4px 40px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    '.MuiInputBase-input': {
      marginRight: '72px',
    },
    '.MuiInputLabel-root': {
      marginTop: '4px !important',
      fontSize: '14px',
      color: '#999',
    },
    '.MuiInputBase-root': {
      padding: '2px',
      margin: '0px',
      height: '62px',
      '.MuiChip-root': {
        height: '25px',
        padding: '5px',
        maxWidth: '50%',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: '0',
    },
    '.MuiButtonBase-root': {
      marginRight: '4px',
    },
    '& button.MuiButtonBase-root': {
      visibility: 'hide',
    },
    '.MuiBox-root': {
      gap: '4px',
      flexWrap: 'nowrap',
      marginRight: '8px',
    },
    '.MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap !important',
      paddingRight: '0 !important',
    },
    '.MuiAutocomplete-root': {
      paddingRight: '0px !important',
    },
    '.MuiAutocomplete-endAdornment': {
      maxWidth: '24%',
      display: 'flex',
    },
  };
};
