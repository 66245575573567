export const getPreferencesListBody = () => {
  let criterion = [];

  return {
    intent: '',
    fields: [
        'CommunicationPreferenceMasterId',
        'AppId',
        'CommunicationName',
        'CommunicationPreferenceList',
        'DisplayEventName',
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
