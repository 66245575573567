import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Drawer, Typography } from "@mui/material";
import { drawerBoxStyle, sidesheetDivider, typoHeaderStyle, xmarkStyle } from "./styles";

const Sidesheet = (props) => {
    const {
        open,
        onClose,
        anchor = 'right',
        title = '',
        children,
        style = {}
    } = props;

    return (
        <Drawer open={open} onClose={onClose} anchor={anchor}>
            <Box sx={{ ...drawerBoxStyle(), ...style }}>
                <Box sx={typoHeaderStyle}>
                    <Typography variant="display4">
                        {title}
                    </Typography>
                    <FontAwesomeIcon icon={faXmark} onClick={onClose} style={xmarkStyle()} />
                </Box>
                <Divider sx={sidesheetDivider} />
                {children}
            </Box>
        </Drawer>
    );
}

export default Sidesheet;