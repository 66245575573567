import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { radioGroupLabel, radioGroupOptions } from './RadioGroupStyles';

const mapRadioButton = (props) => (item) =>
  (
    <FormControlLabel
      value={item.value}
      disabled={item.disabled}
      control={<Radio size={props.size ? props.size : 'small'} className={props.className} />}
      label={item.label}
    />
  );

const RadioButtonsGroup = (props) => {
  const { inline = true } = props;
  return (
    <FormControl>
      {props.title && (
        <InputLabel shrink sx={radioGroupLabel}>
          {props.title}
        </InputLabel>
      )}
      <RadioGroup {...props} onChange={(event, value) => props.onChange(event, value)}>
        <div style={inline ? radioGroupOptions() : {}}>{props.options && props.options.map(mapRadioButton(props))}</div>
      </RadioGroup>
      {props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </FormControl>
  );
};
export default RadioButtonsGroup;
