import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import Sidesheet from 'components/Sidesheet';
import {
  closeNotification,
  dismissAllButton,
  markAsReadButton,
  notificationMessage,
  notificationSidesheetContainerStyle,
  notificationWrapper,
  notificationsWrapper,
  scrollBarHeight,
  scrollbarWrapper,
  timeSpanStyles,
  timeSpanText,
  timeSpanWrapper,
  unread,
  unreadNotificationStyles,
  viewNotificationStyles,
} from './notificationStyles';
import { convertToDateToFormat } from 'utils/dateAndTimeUtils';
import { isEmpty, isNil } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { dismissEventNotification, markAsReadNotification, searchEventNotificationList } from 'actions/userProfile';
import { Loading } from 'components';
import { useHistory } from 'react-router-dom';

const Notification = ({ item, viewNotification, onDismiss }) => {
  return (
    <Box sx={(theme) => notificationWrapper(theme)} onClick={(e) => viewNotification(e, item)}>
      <Stack gap={1}>
        <Tooltip placement="top" title={item?.TextMessage}>
          <Typography sx={notificationMessage} variant="body2">{item?.TextMessage}</Typography>
        </Tooltip>
        <Box>
          {!isNil(item?.EventNotificationDate) && (
            <Typography variant="caption">{convertToDateToFormat(item?.EventNotificationDate, 'MM/dd/yyyy @ hh:mm a')}</Typography>
          )}
        </Box>
      </Stack>
      <Stack alignItems="flex-end" justifyContent="space-between" height="100%">
        <Box sx={(theme) => closeNotification(theme)}>
          <FontAwesomeIcon icon={faXmark} onClick={(e) => onDismiss(e, item)} />
        </Box>
        <Button variant="text" size="small" color="secondary" sx={viewNotificationStyles} startIcon={<FontAwesomeIcon icon={faArrowRight} />} onClick={(e) => viewNotification(e, item)}>
          VIEW
        </Button>
      </Stack>
    </Box>
  );
};

const Notifications = (props) => {
  
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.userReducer.loading)
  const eventNotificationList = useSelector((state) => state.userReducer.eventNotificationList)
  const unreadEventNotificationList = useSelector((state) => state.userReducer.unreadEventNotificationList)
  const timeSpans = useSelector((state) => state.userReducer.timeSpans)

  useEffect(() => {
      dispatch(searchEventNotificationList());
  }, [dispatch])

  const viewNotification = (e, item) => {
    if(!isNil(item?.LinkToPortalPage) && !isEmpty(item?.LinkToPortalPage)) {
      onClose();
      dispatch(markAsReadNotification(item?.EventNotificationInstanceId, () => {
        history.push(item?.LinkToPortalPage);
      }));
    }
    e.stopPropagation();
  };

  const onDismiss = (e, item) => {
    e.stopPropagation();
    dispatch(dismissEventNotification(item?.EventNotificationInstanceId));
  };

  const markAsRead = (item) => {
    dispatch(markAsReadNotification(item?.EventNotificationInstanceId));
  };

  const markAllAsRead = () => {
    dispatch(markAsReadNotification());
  }

  const dismissAll = () => {
    if(!isEmpty(eventNotificationList)) {
      dispatch(dismissEventNotification(null, () => {
          onClose();
      }));
    }
  };

  return (
    <div>
    {loading && <Loading />}
    <Sidesheet open={open} onClose={onClose} anchor="right" title="Notifications" style={notificationSidesheetContainerStyle()}>
      <Box sx={(theme) => scrollbarWrapper(theme)}>
        <Scrollbars style={scrollBarHeight()}>
          <Stack sx={(theme) => notificationsWrapper(theme)}>
            <Box width="100%" onClick={() => unreadEventNotificationList?.length > 0 ? markAllAsRead() : {}}>
              <Typography sx={(theme) => markAsReadButton(theme)} variant="caption">
                {unreadEventNotificationList?.length > 0 ? 'Mark all as read' : 'No new notifications'}
              </Typography>
            </Box>
            {unreadEventNotificationList?.length > 0 &&
              unreadEventNotificationList?.map((item, index) => (
                <Box key={index} sx={unreadNotificationStyles}>
                  <Tooltip title={"Mark as read"} placement="right-end">
                    <Box onClick={() => markAsRead(item)} sx={unread} />
                  </Tooltip>
                  <Notification item={item} viewNotification={viewNotification} onDismiss={onDismiss} />
                </Box>
              ))}
            {timeSpans?.length > 0 &&
              timeSpans?.map((timeSpan, index) => (
                <Stack key={index} width="100%" gap="16px">
                  <Box sx={timeSpanWrapper}>
                    <Box sx={(theme) => timeSpanStyles(theme)} />
                    <Box sx={(theme) => timeSpanText(theme)}>
                      <Typography variant="caption">{timeSpan}</Typography>
                    </Box>
                    <Box sx={(theme) => timeSpanStyles(theme)} />
                  </Box>
                  {eventNotificationList?.length > 0 &&
                    eventNotificationList?.map((item, index) => {
                      if (item?.ReadableDate === timeSpan) {
                        return <Notification key={index} item={item} viewNotification={viewNotification} onDismiss={onDismiss} />;
                      }
                    })}
                </Stack>
              ))}
          </Stack>
        </Scrollbars>
      </Box>
      <Box sx={dismissAllButton} onClick={dismissAll}>
        <Typography sx={(theme) => markAsReadButton(theme)} variant="caption">
          Dismiss all
        </Typography>
      </Box>
    </Sidesheet>
    </div>
  );
};

export default Notifications;
