import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { headerLogo, clientLogocontainer, clientLogo, logo } from './NavBarStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import headerLogoImg from '../../assets/images/corl-logo-color.svg';
import { isNil, isEmpty } from 'lodash';

const Branding = () => {
  const details = useSelector((state) => state.session.details);

  return (
    <>
      <Box sx={headerLogo}>
        <Link to={'/'} className="logoContainer">
          {!isNil(details?.OrgLogoUrl) && !isEmpty(details?.OrgLogoUrl) && (
            <Box sx={clientLogocontainer}>
              <Box sx={(theme) => clientLogo(theme, details?.OrgLogoUrl)} />
            </Box>
          )}
          <Box component="img" alt="CORL logo" src={headerLogoImg} sx={(theme) => logo(theme, details?.OrgLogoUrl)} />
        </Link>
      </Box>
    </>
  );
};

export default Branding;
