export const drawerBoxStyle = () => {
    return {
      width: '420px',
      padding: '24px 16px',
    };
  };
  
  export const xmarkStyle = () => {
    return {
      cursor: 'pointer',
    };
  };
  
  export const typoHeaderStyle = () => {
    return {
      display: 'flex',
      justifyContent: 'space-between',
    };
  };

  export const sidesheetDivider = () => {
    return {
      margin: '16px 0',
    };
  }