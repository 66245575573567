export const extraSelectedChipStyles = () => {
  return {
    padding: 0,
  };
};

export const chipWrapper = () => {
  return {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '4px',
  };
};
export const defaultChipWrapper = () => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
  };
};

export const multiSelectLabelStyles = () => {
  return {
    paddingBottom: '8px !important',
    height: 'inherit',
  };
};
