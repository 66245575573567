export const getAssessmentResponsesSearchBody = (assessmentId) => {
  return {
    intent: '',
    fields: [
      'SecurityControlResponseId',
      'QuestionnaireTemplateId',
      'EvidenceReviewId',
      'Comments',
      'VendorResponse',
      'ClientNotes',
      'VendorNotes',
      'EvidenceProvided',
      'LastUpdated',
      'CreatedDate',
      'QuestionText',
      'ControlGroup',
      'ControlName',
      'KeyControlId',
      'KeyControlName',
      'Outcome',
      'Outcome_Value',
      'ControlNumber'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: assessmentId,
      },
    ],
    sort: [
      {
        field: 'ControlOrder',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentResponsesSearchBodyForPortal = (assessmentId) => {
  return {
    Intent: '',
    Fields: [
      'AssessmentQuestionnaireId',
      'QuestionnaireTemplateQuestionId',
      'QuestionId',
      'QuestionResponseCategory',
      'QuestionResponseText',
      'QuestionResponseComment',
      'ResponseScore',
      'DisplayOrder',
      'IsRequired',
      'QuestionText',
      'AllowCommentInResponse',
      'QuestionType',
      'QuestionResponseType',
      'ResponseOptionList',
      'ChildQuestionList',
      'QuestionLogicRuleList',
      'UpdateTimestamp',
      'AllowFileUpload',
      'RequirementId',
      'QuestionPlainText',
      'ResponseAttachmentList',
      'SecurityControlResponseId',
      'RequirementGroup',
      'RequirementNumber',
      'RequirementName',
      'UpdateTimestamp',
      'Adequacy'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: assessmentId,
      },
      {
        Field: 'ParentQuestionId',
        Operator: '=',
        Value: '0',
      },
    ],
    Sort: [
      {
        Field: 'DisplayOrder',
        Order: 'ASC',
      },
    ],
    Page: 0,
    PageSize: 0,
  };
};

export const getAssessmentBody = (id) => {
  return {
    intent: '',
    fields: ['AssessmentId', 'AssessmentName', 'Source'],
    criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
