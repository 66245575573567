import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { searchContractSubscriptionDetailsBody } from 'utils/clientUtils';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';

export const GET_CLIENT_REQUEST = '@@client/GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = '@@client/GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAIL = '@@client/GET_CLIENT_FAIL';

export const getClient = (id) => async (dispatch) => {
  dispatch({ type: GET_CLIENT_REQUEST });
  try {
    const res = await axios.get(`/client/${id}`);
    dispatch({
      type: GET_CLIENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CLIENT_FAIL,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_CONTRACT_CONFIGURATION_DETAILS_LOADING = '@@client/SEARCH_CONTRACT_CONFIGURATION_DETAILS_LOADING';
export const SEARCH_CONTRACT_CONFIGURATION_DETAILS_SUCCESS = '@@client/SEARCH_CONTRACT_CONFIGURATION_DETAILS_SUCCESS';
export const SEARCH_CONTRACT_CONFIGURATION_DETAILS_FAILURE = '@@client/SEARCH_CONTRACT_CONFIGURATION_DETAILS_FAILURE';

export const searchContractSubscriptionDetails = () => async (dispatch) => {
  dispatch({
    type: SEARCH_CONTRACT_CONFIGURATION_DETAILS_LOADING,
  });

  var body = searchContractSubscriptionDetailsBody();

  try {
    const response = await axios.post(`/client/current/contract/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_CONTRACT_CONFIGURATION_DETAILS_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_CONTRACT_CONFIGURATION_DETAILS_SUCCESS,
        payload: response?.data?.Data,
      });
    }
  } catch {
    dispatch({
      type: SEARCH_CONTRACT_CONFIGURATION_DETAILS_FAILURE,
      payload: {},
    });
  }
};

export const GET_SWAGGER_DOC_LOADING = '@@client/GET_SWAGGER_DOC_LOADING';
export const GET_SWAGGER_DOC_SUCCESS = '@@client/GET_SWAGGER_DOC_SUCCESS';
export const GET_SWAGGER_DOC_FAILURE = '@@client/GET_SWAGGER_DOC_FAILURE';

export const getSwaggerDocumentation = () => async (dispatch) => {
  dispatch({ type: GET_SWAGGER_DOC_LOADING });
  try {
    const res = await axios.get(`/client/swagger`);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: GET_SWAGGER_DOC_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: GET_SWAGGER_DOC_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SWAGGER_DOC_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
