export const getClientProfileIncidentDetailsBody = (searchValue) => {
  return {
    Intent: '',
    Fields: [
      'IncidentName',
      'Description',
      'DateFirstReported',
      'UpdateTimeStamp',
      'IncidentStatusForClient',
      'Severity',
      'UpdateTimeStamp',
      'OutreachId',
      'OutreachName',
      'OutreachStatus',
      'OutreachQuestionnaireTemplateId',
      'QuestionnaireTemplateId',
      'QuestionnaireTemplateStatus',
    ],
    Criterion: [
      {
        Field: 'IncidentId',
        Operator: '=',
        Value: searchValue /* Should be IncidentId of the chosen incident */,
      },
    ],
    Sort: [
      {
        Field: 'DateFirstReported',
        Order: 'desc',
      },
    ],
    Page: 0,
    PageSize: 0,
  };
};
