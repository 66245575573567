import axios from 'axios';
import { ACTIVE, ACTIVE_STAGE, COMPLETED_STAGE, DEFAULT_ROWS_PER_PAGE, PORTAL_2, REMEDIATION, REMEDIATION_STAGE } from 'constants/constants';
import { isEmpty, isNil } from 'lodash';
import { getErrorObject, getErrorPayload } from 'utils/apiUtils';
import {
  getActiveAssessmentListSearchBody,
  getAssessmentListBody,
  getCompletedAssessmentListSearchBody,
  getRemediationAssessmentListSearchBody,
  getReservedAssessmentListSearchBody,
  getRequestedAssessmentSearchBody,
  getAssessmentConfigurationBody,
  getAssessmentNotesBody,
  getAssessmentByIdBody,
  getQuestionnaireInfoBody,
  getAssessmentListCountBody,
  getStatusHistory,
  getQueuedAssessmentsSearchBody,
  getQueuedAssessmentTableListCountBody,
  getQueuedAssessmentsModalSearchBody,
  getQuestionnaireInfoBodyForAssessment,
  getStatusHistoryForPortal,
  getAssessmentDetailsByActiveStatusBody,
  getAssessmentDetailsByRemediationStatusBody,
  getAssessmentDetailsByCompletedStatusBody,
  getAssessmentSearchBody,
  getAssessmentQuestionnaireRequirementGroupSearchBody,
  getAssessmentQuestionnaireProgressSearchBody,
  getAssessmentFileUploadBody,
} from 'utils/assessmentsOverviewUtils';
import { setErrors } from './errorHandler';
import { ASSESSMENT_OVERVIEW_TAB_OPTIONS, DEFAULT_ASSESSMENT_QUEUE_PAGE_NO, DEFAULT_ASSESSMENT_QUEUE_ROWS_PER_PAGE } from 'pages/AssessmentsOverview/constants';
import { getAssessmentOverviewBodyForPortal } from 'utils/executiveSummaryUtils';
import { NO_DATA_FOUND } from 'constants/errorMessage';

export const SEARCH_REMEDIATION_ASSESSMENT_LIST_LOADING = '@assessmentsOverview/SEARCH_REMEDIATION_ASSESSMENT_LIST_LOADING';
export const SEARCH_REMEDIATION_ASSESSMENT_LIST_SUCCESS = '@assessmentsOverview/SEARCH_REMEDIATION_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_REMEDIATION_ASSESSMENT_LIST_FAILURE = '@assessmentsOverview/SEARCH_REMEDIATION_ASSESSMENT_LIST_FAILURE';

export const getRemediationAssessmentListData =
  (searchData = {}) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_REMEDIATION_ASSESSMENT_LIST_LOADING });
      try {
        const body = getRemediationAssessmentListSearchBody(searchData);
        const response = await axios.post(`/legacy/assessment/search/?stage=${REMEDIATION}`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_REMEDIATION_ASSESSMENT_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_REMEDIATION_ASSESSMENT_LIST_SUCCESS,
            payload: response.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_REMEDIATION_ASSESSMENT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ACTIVE_ASSESSMENT_LIST_LOADING = '@assessmentsOverview/SEARCH_ACTIVE_ASSESSMENT_LIST_LOADING';
export const SEARCH_ACTIVE_ASSESSMENT_LIST_SUCCESS = '@assessmentsOverview/SEARCH_ACTIVE_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_ACTIVE_ASSESSMENT_LIST_FAILURE = '@assessmentsOverview/SEARCH_ACTIVE_ASSESSMENT_LIST_FAILURE';

export const getActiveAssessmentListData =
  (searchData = {}) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ACTIVE_ASSESSMENT_LIST_LOADING });
      try {
        const body = getActiveAssessmentListSearchBody(searchData);
        const response = await axios.post(`/legacy/assessment/search/?stage=${ACTIVE}`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ACTIVE_ASSESSMENT_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ACTIVE_ASSESSMENT_LIST_SUCCESS,
            payload: response.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ACTIVE_ASSESSMENT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_RESERVED_ASSESSMENT_LIST_LOADING = '@assessmentsOverview/SEARCH_RESERVED_ASSESSMENT_LIST_LOADING';
export const SEARCH_RESERVED_ASSESSMENT_LIST_SUCCESS = '@assessmentsOverview/SEARCH_RESERVED_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_RESERVED_ASSESSMENT_LIST_FAILURE = '@assessmentsOverview/SEARCH_RESERVED_ASSESSMENT_LIST_FAILURE';

export const getReservedAssessmentListData =
  (searchData = {}) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_RESERVED_ASSESSMENT_LIST_LOADING });
      try {
        const body = getReservedAssessmentListSearchBody(searchData);
        const response = await axios.post(`/legacy/assessment/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_RESERVED_ASSESSMENT_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_RESERVED_ASSESSMENT_LIST_SUCCESS,
            payload: response.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_RESERVED_ASSESSMENT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_COMPLETED_ASSESSMENT_LIST_LOADING = '@assessmentsOverview/SEARCH_COMPLETED_ASSESSMENT_LIST_LOADING';
export const SEARCH_COMPLETED_ASSESSMENT_LIST_SUCCESS = '@assessmentsOverview/SEARCH_COMPLETED_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_COMPLETED_ASSESSMENT_LIST_FAILURE = '@assessmentsOverview/SEARCH_COMPLETED_ASSESSMENT_LIST_FAILURE';

export const getCompletedAssessmentListData =
  (searchData = {}) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_COMPLETED_ASSESSMENT_LIST_LOADING });
      try {
        const body = getCompletedAssessmentListSearchBody(searchData);
        const response = await axios.post(`/legacy/assessment/completed/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_COMPLETED_ASSESSMENT_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_COMPLETED_ASSESSMENT_LIST_SUCCESS,
            payload: response.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_COMPLETED_ASSESSMENT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const POST_ASSESSMENT_TICKET_LOADING = '@assessmentsOverview/POST_ASSESSMENT_TICKET_LOADING';
export const POST_ASSESSMENT_TICKET_SUCCESS = '@assessmentsOverview/POST_ASSESSMENT_TICKET_SUCCESS';
export const POST_ASSESSMENT_TICKET_FAILURE = '@assessmentsOverview/POST_ASSESSMENT_TICKET_FAILURE';

export const addAssessmentTicket = (formData, assessmentName, assessmentId) => async (dispatch) => {
  dispatch({
    type: POST_ASSESSMENT_TICKET_LOADING,
  });

  let tempFormData = {
    ...formData,
    AssessmentName: assessmentName,
    AssessmentId: assessmentId
  };

  delete tempFormData['UploadedFileList'];

  let body = new FormData();

  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });
  formData?.UploadedFileList.forEach((assessmentTicketFile) => {
    body.append('UploadedFileList', assessmentTicketFile.file);
  });

  try {
    const res = await axios.post(`/assessmentticket`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_ASSESSMENT_TICKET_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: POST_ASSESSMENT_TICKET_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_ASSESSMENT_TICKET_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_LOADING = '@assessmentsOverview/SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_LOADING';
export const SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_SUCCESS = '@assessmentsOverview/SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_SUCCESS';
export const SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FAILURE = '@assessmentsOverview/SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FAILURE';

export const setTotalCorlClearedActiveAssessments =
  (activeList = [], clientContractConfig = {}) =>
    async (dispatch) => {
      dispatch({ type: SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_LOADING });
      try {
        const totalActive = activeList?.map((it, index) => {
          return {
            ...it,
            SeqNo: `${index + 1}/${clientContractConfig?.AssessmentLaneLimit}`,
          };
        });
        if (!isEmpty(totalActive) && totalActive?.length > 0) {
          dispatch({
            type: SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_SUCCESS,
            payload: totalActive,
          });
        } else {
          dispatch({
            type: SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FAILURE,
            payload: [],
          });
        }
      } catch (err) {
        dispatch({
          type: SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FOR_AUDIT = '@assessmentsOverview/SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FOR_AUDIT';

export const setTotalCorlClearedActiveAssessmentsForAudit =
  (activeForAuditList = []) =>
    async (dispatch) => {
      if (!isEmpty(activeForAuditList) && activeForAuditList?.length > 0) {
        dispatch({
          type: SET_TOTAL_CORLCLEARED_ACTIVE_ASSESSMENTS_FOR_AUDIT,
          payload: activeForAuditList,
        });
      }
    }

export const SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_LOADING = '@assessmentsOverview/SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_LOADING';
export const SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_SUCCESS = '@assessmentsOverview/SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_SUCCESS';
export const SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_FAILURE = '@assessmentsOverview/SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_FAILURE';

export const setTotalStandardActiveAssessments =
  (list = []) =>
    async (dispatch) => {
      dispatch({ type: SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_LOADING });
      try {
        const totalActive = list?.map((it, index) => {
          return {
            ...it,
            SeqNo: `${index + 1}/${list?.length}`,
          };
        });
        if (!isEmpty(totalActive) && totalActive?.length > 0) {
          dispatch({
            type: SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_SUCCESS,
            payload: totalActive,
          });
        } else {
          dispatch({
            type: SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_FAILURE,
            payload: [],
          });
        }
      } catch (err) {
        dispatch({
          type: SET_TOTAL_STANDARD_ACTIVE_ASSESSMENTS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_REQUESTED_ASSESSMENTS_LOADING = '@assessmentsOverview/SEARCH_REQUESTED_ASSESSMENTS_LOADING';
export const SEARCH_REQUESTED_ASSESSMENTS_SUCCESS = '@assessmentsOverview/SEARCH_REQUESTED_ASSESSMENTS_SUCCESS';
export const SEARCH_REQUESTED_ASSESSMENTS_FAILURE = '@assessmentsOverview/SEARCH_REQUESTED_ASSESSMENTS_FAILURE';

export const getRequestedAssessmentList =
  (searchData = {}) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_REQUESTED_ASSESSMENTS_LOADING });
      try {
        const body = getRequestedAssessmentSearchBody(searchData);
        const response = await axios.post(`client/assessmentrequest/search`, body);

        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_REQUESTED_ASSESSMENTS_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_REQUESTED_ASSESSMENTS_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_REQUESTED_ASSESSMENTS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_LIST_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_LIST_LOADING';
export const SEARCH_ASSESSMENT_LIST_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_LIST_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_LIST_FAILURE';

export const getAssessmentTableListData =
  (searchData = {}, page, rowPerPage, sortModel = []) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_LIST_LOADING });
      try {
        const body = getAssessmentListBody(searchData, page, rowPerPage, sortModel);
        const response = await axios.post(`/legacy/assessment/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_LIST_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_LIST_COUNT_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_LIST_COUNT_LOADING';
export const SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS';
export const SEARCH_ASSESSMENT_LIST_COUNT_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_LIST_COUNT_FAILURE';

export const getAssessmentTableListCount =
  (searchData = {}) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_LIST_COUNT_LOADING });
      try {
        const body = getAssessmentListCountBody(searchData);
        const response = await axios.post(`/legacy/assessment/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_CONFIG_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_CONFIG_LOADING';
export const SEARCH_ASSESSMENT_CONFIG_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_CONFIG_SUCCESS';
export const SEARCH_ASSESSMENT_CONFIG_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_CONFIG_FAILURE';

export const getAssessmentConfiguration = () => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_CONFIG_LOADING });
  try {
    const body = getAssessmentConfigurationBody();
    const response = await axios.post(`/legacy/assessment/configuration/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_CONFIG_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_CONFIG_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_CONFIG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const RESET_ACTIVE_ASSESSMENTS = '@assessmentsOverview/RESET_ACTIVE_ASSESSMENTS';

export const resetActiveAssessmentsList = () => async (dispatch) => {
  dispatch({
    type: RESET_ACTIVE_ASSESSMENTS,
  });
};

export const RESET_REMEDIATION_ASSESSMENTS = '@assessmentsOverview/RESET_REMEDIATION_ASSESSMENTS';

export const resetRemediationAssessmentsList = () => async (dispatch) => {
  dispatch({
    type: RESET_REMEDIATION_ASSESSMENTS,
  });
};

export const RESET_COMPLETED_ASSESSMENTS = '@assessmentsOverview/RESET_COMPLETED_ASSESSMENTS';

export const resetCompletedAssessmentsList = () => async (dispatch) => {
  dispatch({
    type: RESET_COMPLETED_ASSESSMENTS,
  });
};

export const SET_ASSESSMENT_TAB_VALUE = '@assessmentsOverview/SET_ASSESSMENT_TAB_VALUE';

export const setAssessmentTabValue =
  (value = ASSESSMENT_OVERVIEW_TAB_OPTIONS.BOARD) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_TAB_VALUE,
        payload: value,
      });
    };

export const SET_SELECTED_ASSESSMENT_DETAILS_CARD = '@assessmentsOverview/SET_SELECTED_ASSESSMENT_DETAILS_CARD';

export const setSelectedAssessmentCardValue = (card) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_ASSESSMENT_DETAILS_CARD,
    payload: card,
  });
};

export const SET_OPEN_ASSESSMENT_HELP_MODAL = '@assessmentsOverview/SET_OPEN_ASSESSMENT_HELP_MODAL';

export const setOpenAssessmentHelpModal = (value) => async (dispatch) => {
  dispatch({
    type: SET_OPEN_ASSESSMENT_HELP_MODAL,
    payload: value,
  });
};

export const SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET = '@assessmentsOverview/SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET';

export const setOpenAssessmentDetailsSidesheet = (value) => async (dispatch) => {
  dispatch({
    type: SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET,
    payload: value,
  });
};

export const GET_ASSESSMENT_NOTES_LOADING = '@assessmentsOverview/GET_ASSESSMENT_NOTES_LOADING';
export const GET_ASSESSMENT_NOTES_SUCCESS = '@assessmentsOverview/GET_ASSESSMENT_NOTES_SUCCESS';
export const GET_ASSESSMENT_NOTES_FAILURE = '@assessmentsOverview/GET_ASSESSMENT_NOTES_FAILURE';

export const getAssessmentNotes =
  (searchData = {}) =>
    async (dispatch) => {
      dispatch({ type: GET_ASSESSMENT_NOTES_LOADING });
      try {
        const body = getAssessmentNotesBody(searchData);
        const response = await axios.post(`/legacy/assessment/notes/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: GET_ASSESSMENT_NOTES_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: GET_ASSESSMENT_NOTES_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: GET_ASSESSMENT_NOTES_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const ADDITIONAL_NOTES_SEARCH_LOADING = '@@assessmentsOverview/ADDITIONAL_NOTES_SEARCH_LOADING';
export const ADDITIONAL_NOTES_SEARCH_SUCCESS = '@@assessmentsOverview/ADDITIONAL_NOTES_SEARCH_SUCCESS';
export const ADDITIONAL_NOTES_SEARCH_FAILURE = '@@assessmentsOverview/ADDITIONAL_NOTES_SEARCH_FAILURE';

export const searchAdditionalNotes =
  (assessmentId = null) =>
    async (dispatch) => {
      dispatch({
        type: ADDITIONAL_NOTES_SEARCH_LOADING,
      });
      try {
        const res = await axios.get(`/assessment/${assessmentId}/additionalnotes/`);
        if (res?.data?.ErrorCount > 0) {
          dispatch({
            type: ADDITIONAL_NOTES_SEARCH_FAILURE,
            payload: {},
          });
          if (res?.data?.Errors[0].Message !== NO_DATA_FOUND) {
            dispatch(setErrors(res?.data?.Errors));
          }
        } else {
          dispatch({
            type: ADDITIONAL_NOTES_SEARCH_SUCCESS,
            payload: res?.data?.Data,
          });
        }
      } catch (err) {
        dispatch({
          type: ADDITIONAL_NOTES_SEARCH_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const GET_QUESTIONNAIRE_INFO_LOADING = '@assessmentsOverview/GET_QUESTIONNAIRE_INFO_LOADING';
export const GET_QUESTIONNAIRE_INFO_SUCCESS = '@assessmentsOverview/GET_QUESTIONNAIRE_INFO_SUCCESS';
export const GET_QUESTIONNAIRE_INFO_FAILURE = '@assessmentsOverview/GET_QUESTIONNAIRE_INFO_FAILURE';

export const getQuestionnaireInformation =
  (searchData = null, source = "", callback) =>
    async (dispatch) => {
      dispatch({ type: GET_QUESTIONNAIRE_INFO_LOADING });
      try {
        let response;
        if (source === PORTAL_2) {
          const body = getQuestionnaireInfoBodyForAssessment(searchData);
          response = await axios.post(`/assessment/search`, body);
        } else {
          const body = getQuestionnaireInfoBody(searchData);
          response = await axios.post(`/legacy/assessment/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: GET_QUESTIONNAIRE_INFO_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: GET_QUESTIONNAIRE_INFO_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
          if (callback) {
            callback();
          }
        }
      } catch (err) {
        dispatch({
          type: GET_QUESTIONNAIRE_INFO_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_BY_ID_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_BY_ID_LOADING';
export const SEARCH_ASSESSMENT_BY_ID_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_BY_ID_SUCCESS';
export const SEARCH_ASSESSMENT_BY_ID_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_BY_ID_FAILURE';

export const getAssessmentById = (id, source = "") => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_BY_ID_LOADING });
  try {
    let response;
    if (source === PORTAL_2) {
      const body = getAssessmentOverviewBodyForPortal(id);
      response = await axios.post(`assessment/search`, body);
    } else {
      const body = getAssessmentByIdBody(id);
      response = await axios.post(`/legacy/assessment/search`, body);
    }
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_BY_ID_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_BY_ID_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_BY_ID_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_ASSESSMENT_STATUS_HISTORY_LOADING = '@assessmentsOverview/GET_ASSESSMENT_STATUS_HISTORY_LOADING';
export const GET_ASSESSMENT_STATUS_HISTORY_SUCCESS = '@assessmentsOverview/GET_ASSESSMENT_STATUS_HISTORY_SUCCESS';
export const GET_ASSESSMENT_STATUS_HISTORY_FAILURE = '@assessmentsOverview/GET_ASSESSMENT_STATUS_HISTORY_FAILURE';

export const getAssessmentStatusHistory = (assessmentId, source = "") => async (dispatch) => {
  dispatch({ type: GET_ASSESSMENT_STATUS_HISTORY_LOADING });
  try {
    let response;
    if (source === PORTAL_2) {
      const body = getStatusHistoryForPortal(assessmentId);
      response = await axios.post(`/assessment/statushistory/search`, body);
    } else {
      const body = getStatusHistory(assessmentId);
      response = await axios.post(`/legacy/assessment/statushistory/search`, body);
    }
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_ASSESSMENT_STATUS_HISTORY_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_STATUS_HISTORY_SUCCESS,
        payload: response?.data?.Data?.Rows || [],
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_STATUS_HISTORY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const RESET_QUESTIONAIRE_SUMMARY = '@assessmentsOverview/RESET_QUESTIONAIRE_SUMMARY';

export const resetQuestionaireSummary = () => async (dispatch) => {
  dispatch({
    type: RESET_QUESTIONAIRE_SUMMARY,
  });
};

export const SET_ASSESSMENT_PAGE_NO = '@@assessmentsOverview/SET_ASSESSMENT_PAGE_NO';

export const setAssessmentPageNo =
  (page = 0) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_PAGE_NO,
        payload: page,
      });
    };

export const SET_ASSESSMENT_ROW_PER_PAGE = '@@assessments/SET_ASSESSMENT_ROW_PER_PAGE';

export const setAssessmentRowPerPage =
  (page = DEFAULT_ROWS_PER_PAGE, callback) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_ROW_PER_PAGE,
        payload: page,
      });
      if (callback) {
        callback(page);
      }
    };

export const SEARCH_ASSESSMENT_QUEUE_LIST_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_QUEUE_LIST_LOADING';
export const SEARCH_ASSESSMENT_QUEUE_LIST_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_QUEUE_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_QUEUE_LIST_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_QUEUE_LIST_FAILURE';

export const getQueuedAssessmentsList = (page, rowPerPage) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_QUEUE_LIST_LOADING });
    try {
      const body = getQueuedAssessmentsSearchBody(page, rowPerPage);
      const response = await axios.post(`/legacy/queuedassessment/search`, body);

      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUEUE_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_QUEUE_LIST_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_QUEUE_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const POST_UPDATED_QUEUE_ORDER_LOADING = '@assessmentsOverview/POST_UPDATED_QUEUE_ORDER_LOADING';
export const POST_UPDATED_QUEUE_ORDER_SUCCESS = '@assessmentsOverview/POST_UPDATED_QUEUE_ORDER_SUCCESS';
export const POST_UPDATED_QUEUE_ORDER_FAILURE = '@assessmentsOverview/POST_UPDATED_QUEUE_ORDER_FAILURE';

export const updateAssessmentsQueueOrder = (formData) =>
  async (dispatch) => {
    dispatch({ type: POST_UPDATED_QUEUE_ORDER_LOADING });
    try {
      const response = await axios.post(`/client/queuedassesment/action/submitticket`, formData);

      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: POST_UPDATED_QUEUE_ORDER_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: POST_UPDATED_QUEUE_ORDER_SUCCESS,
          payload: response?.data,
        });
        dispatch(getQueuedAssessmentsList(DEFAULT_ASSESSMENT_QUEUE_PAGE_NO, DEFAULT_ASSESSMENT_QUEUE_ROWS_PER_PAGE));
      }
    } catch (err) {
      dispatch({
        type: POST_UPDATED_QUEUE_ORDER_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_LOADING = '@assessmentsOverview/SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_LOADING';
export const SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_SUCCESS = '@assessmentsOverview/SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_SUCCESS';
export const SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_FAILURE = '@assessmentsOverview/SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_FAILURE';

export const getQueuedAssessmentTableListCount =
  () =>
    async (dispatch) => {
      dispatch({ type: SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_LOADING });
      try {
        const body = getQueuedAssessmentTableListCountBody();
        const response = await axios.post(`/legacy/queuedassessment/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_QUEUED_ASSESSMENT_LIST_COUNT_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SET_QUEUED_ASSESSMENT_PAGE_NO = '@assessmentsOverview/SET_QUEUED_ASSESSMENT_PAGE_NO';

export const setQueuedAssessmentPageNo =
  (page = 0) =>
    async (dispatch) => {
      dispatch({
        type: SET_QUEUED_ASSESSMENT_PAGE_NO,
        payload: page,
      });
    };

export const SET_QUEUED_ASSESSMENT_ROW_PER_PAGE = '@assessmentsOverview/SET_QUEUED_ASSESSMENT_ROW_PER_PAGE';

export const setQueuedAssessmentRowPerPage =
  (page = DEFAULT_ASSESSMENT_QUEUE_ROWS_PER_PAGE, callback) =>
    async (dispatch) => {
      dispatch({
        type: SET_QUEUED_ASSESSMENT_ROW_PER_PAGE,
        payload: page,
      });
      if (callback) {
        callback(page);
      }
    };

export const SEARCH_ASSESSMENT_QUEUE_FULL_LIST_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_QUEUE_FULL_LIST_LOADING';
export const SEARCH_ASSESSMENT_QUEUE_FULL_LIST_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_QUEUE_FULL_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_QUEUE_FULL_LIST_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_QUEUE_FULL_LIST_FAILURE';

export const getQueuedAssessmentsFullList = () =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_QUEUE_FULL_LIST_LOADING });
    try {
      const body = getQueuedAssessmentsModalSearchBody();
      const response = await axios.post(`/legacy/queuedassessment/search`, body);

      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUEUE_FULL_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_QUEUE_FULL_LIST_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_QUEUE_FULL_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const CLEAR_QUEUED_ASSESSMENT_FULL_LIST = '@assessmentsOverview/CLEAR_QUEUED_ASSESSMENT_FULL_LIST';

export const clearQueuedAssessmentFullList =
  (data = []) =>
    async (dispatch) => {
      dispatch({
        type: CLEAR_QUEUED_ASSESSMENT_FULL_LIST,
        payload: data,
      });
    };

export const SET_ASSESSMENT_NOTES = '@@assessmentsOverview/SET_ASSESSMENT_NOTES';

export const setAssessmentNotes =
  (data = []) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_NOTES,
        payload: data,
      });
    };

export const SEARCH_ASSESSMENT_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_LOADING';
export const SEARCH_ASSESSMENT_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_SUCCESS';
export const SEARCH_ASSESSMENT_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_FAILURE';

export const getAssessment =
  (id = null, status = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_LOADING });
      try {
        let response;
        let body;
        if (isNil(status)) {
          body = getAssessmentSearchBody(id);
          response = await axios.post(`/legacy/assessment/search`, body);
        } else {
          if (status === ACTIVE_STAGE) {
            body = getAssessmentDetailsByActiveStatusBody(id);
            response = await axios.post(`/legacy/assessment/search/?stage=${ACTIVE}`, body);
          } else if (status === REMEDIATION_STAGE) {
            body = getAssessmentDetailsByRemediationStatusBody(id);
            response = await axios.post(`/legacy/assessment/search/?stage=${REMEDIATION}`, body);
          } else if (status === COMPLETED_STAGE) {
            body = getAssessmentDetailsByCompletedStatusBody(id);
            response = await axios.post(`/legacy/assessment/completed/search`, body);
          }
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_FAILURE,
            payload: {},
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_SUCCESS,
            payload: response.data || {},
            status: status
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_DETAILS_BY_STATUS_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_DETAILS_BY_STATUS_LOADING';
export const SEARCH_ASSESSMENT_DETAILS_BY_STATUS_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_DETAILS_BY_STATUS_SUCCESS';
export const SEARCH_ASSESSMENT_DETAILS_BY_STATUS_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_DETAILS_BY_STATUS_FAILURE';

export const getAssessmentDetailsByStatus =
  (assessmentId = null, status) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_DETAILS_BY_STATUS_LOADING });
    try {
      let response;
      let body;
      if (isNil(status)) {
        body = getAssessmentSearchBody(assessmentId);
        response = await axios.post(`/legacy/assessment/search`, body);
      } else {
        if (status === ACTIVE_STAGE) {
          body = getAssessmentDetailsByActiveStatusBody(assessmentId);
          response = await axios.post(`/legacy/assessment/search/?stage=${ACTIVE}`, body);
        } else if (status === REMEDIATION_STAGE) {
          body = getAssessmentDetailsByRemediationStatusBody(assessmentId);
          response = await axios.post(`/legacy/assessment/search/?stage=${REMEDIATION}`, body);
        } else if (status === COMPLETED_STAGE) {
          body = getAssessmentDetailsByCompletedStatusBody(assessmentId);
          response = await axios.post(`/legacy/assessment/completed/search`, body);
        }
      }
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_DETAILS_BY_STATUS_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_DETAILS_BY_STATUS_SUCCESS,
          payload: response.data?.Data?.Rows[0] || {},
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_DETAILS_BY_STATUS_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SET_SELECTED_ASSESSMENT_ID = '@executivesummary/SET_SELECTED_ASSESSMENT_ID';

export const setSelectedAssessmentId = (id = null) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_ASSESSMENT_ID,
    payload: id,
  });
};

export const GET_SELECTED_ASSESSMENT_ID = '@executivesummary/GET_SELECTED_ASSESSMENT_ID';

export const getSelectedAssessmentId = () => async (dispatch) => {
  dispatch({
    type: GET_SELECTED_ASSESSMENT_ID,
  });
};

export const CLEAR_SELECTED_ASSESSMENT_ID = '@executivesummary/CLEAR_SELECTED_ASSESSMENT_ID';

export const clearSelectedAssessmentId = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_ASSESSMENT_ID,
  });
};

export const SET_ASSESSMENT_OVERVIEW_DATA = '@executivesummary SET_ASSESSMENT_OVERVIEW_DATA';

export const setAssessmentOverviewData = (data = null) => async (dispatch) => {
  dispatch({
    type: SET_ASSESSMENT_OVERVIEW_DATA,
    payload: data,
  });
};

export const SET_SELECTED_QUESTIONNAIRE = '@assessmentsOverview/SET_SELECTED_QUESTIONNAIRE';

export const setSelectedQuestionnaire = (data = null) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_QUESTIONNAIRE,
    payload: data,
  });
};

export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING = '@@assessmentsOverview/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS = '@@assessmentsOverview/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE = '@@assessmentsOverview/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE';

export const getAssessmentQuestionnaireRequirement =
  (assessmentQuestionnaireId = null) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING });
    // keep below code as backup in case of any issue
    // const body = getAssessmentQuestionnaireRequirementSearchBody(assessmentQuestionnaireId);
    try {
      const response = await axios.get(`assessment-questionnaires/${assessmentQuestionnaireId}/requirements`);
      dispatch({
        type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
        payload: response?.data?.Data || [],
      });
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
        payload: getErrorPayload(err),
      });
    }
  };

export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING = '@@assessmentsOverview/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS = '@@assessmentsOverview/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE = '@@assessmentsOverview/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE';

export const getAssessmentQuestionnaireRequirementGroup =
  (assessmentQuestionnaireId = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING });
      const body = getAssessmentQuestionnaireRequirementGroupSearchBody(assessmentQuestionnaireId);
      try {
        const response = await axios.post(`/assessment/questionnaire/requirement/group/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_LOADING = '@@assessmentsOverview/SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_LOADING';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_SUCCESS = '@@assessmentsOverview/SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_SUCCESS';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE = '@@assessmentsOverview/SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE';

export const getAssessmentQuestionnaireProgress =
  (assessmentId = null, assessmentQuestionnaireId = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_LOADING });
      const body = getAssessmentQuestionnaireProgressSearchBody(assessmentId, assessmentQuestionnaireId);
      try {
        const response = await axios.post(`assessment/${assessmentId}/questionnairetemplateprogress/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_QUESTIONNAIRE_PROGRESS_LOADING = '@@assessmentsOverview/SEARCH_QUESTIONNAIRE_PROGRESS_LOADING';
export const SEARCH_QUESTIONNAIRE_PROGRESS_SUCCESS = '@@assessmentsOverview/SEARCH_QUESTIONNAIRE_PROGRESS_SUCCESS';
export const SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE = '@@assessmentsOverview/SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE';

export const getQuestionnaireProgress =
  (assessmentId = null, assessmentQuestionnaireId = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_QUESTIONNAIRE_PROGRESS_LOADING });
      const body = getAssessmentQuestionnaireProgressSearchBody(assessmentId, assessmentQuestionnaireId);
      try {
        const response = await axios.post(`assessment/${assessmentId}/questionnairetemplateprogress/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_QUESTIONNAIRE_PROGRESS_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const GET_ASSESSMENT_FILE_UPLOADED_LOADING = '@@assessmentsOverview/GET_ASSESSMENT_FILE_UPLOADED_LOADING';
export const GET_ASSESSMENT_FILE_UPLOADED_SUCCESS = '@@assessmentsOverview/GET_ASSESSMENT_FILE_UPLOADED_SUCCESS';
export const GET_ASSESSMENT_FILE_UPLOADED_FAILURE = '@@assessmentsOverview/GET_ASSESSMENT_FILE_UPLOADED_FAILURE';

export const getAssessmentFileUploaded =
  (assessmentId = null, uploadedBy = null, sort = []) =>
    async (dispatch) => {
      dispatch({ type: GET_ASSESSMENT_FILE_UPLOADED_LOADING });
      const body = getAssessmentFileUploadBody(assessmentId, uploadedBy, sort);
      try {
        const response = await axios.post(`assessment/${assessmentId}/fileUploads`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: GET_ASSESSMENT_FILE_UPLOADED_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: GET_ASSESSMENT_FILE_UPLOADED_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: GET_ASSESSMENT_FILE_UPLOADED_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const UPDATE_ASSESSMENT_OPTION_LOADING = '@@assessments/UPDATE_ASSESSMENT_OPTION_LOADING';
export const UPDATE_ASSESSMENT_OPTION_SUCCESS = '@@assessments/UPDATE_ASSESSMENT_OPTION_SUCCESS';
export const UPDATE_ASSESSMENT_OPTION_FAILURE = '@@assessments/UPDATE_ASSESSMENT_OPTION_FAILURE';

export const updateAssessmentOption = (assessmentId, optionId) => async (dispatch) => {
  dispatch({ type: UPDATE_ASSESSMENT_OPTION_LOADING });
  try {
    const body = {
      AssessmentId: assessmentId,
      EditAssessmentOptionId: optionId,
    };
    const response = await axios.post(`/assessment/${assessmentId}/action/updateassessmentoption`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors;
      dispatch(setErrors(errors));
      dispatch({
        type: UPDATE_ASSESSMENT_OPTION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: UPDATE_ASSESSMENT_OPTION_SUCCESS,
        payload: response?.data.Data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: UPDATE_ASSESSMENT_OPTION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
