import axios from 'axios';
import {
  getOutreachAssociatedVendorsBody,
  getoutreachInformationSummaryBody,
  searchOutreachAssociatedVendorsBody,
  getReviewStatusRecipientAssessmentBody,
} from '../utils/outreachInformationUtils';
import { isNil } from 'lodash';

export const GET_OUTREACH_INFORMATION_LOADING = '@@outreachInformation/GET_OUTREACH_INFORMATION_LOADING';
export const GET_OUTREACH_INFORMATION_SUCCESS = '@@outreachInformation/GET_OUTREACH_INFORMATION_SUCCESS';
export const GET_OUTREACH_INFORMATION_FAILURE = '@@outreachInformation/GET_OUTREACH_INFORMATION_FAILURE';

export const getoutreachInformationSummary = (outreachId) => async (dispatch) => {
  dispatch({
    type: GET_OUTREACH_INFORMATION_LOADING,
  });

  var body = getoutreachInformationSummaryBody();

  try {
    const response = await axios.post(`/app/customer/outreach/${outreachId}/search`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_OUTREACH_INFORMATION_FAILURE,
        payload: {},
      });
      // dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: GET_OUTREACH_INFORMATION_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: GET_OUTREACH_INFORMATION_FAILURE,
      payload: {},
    });
  }
};

export const GET_OUTREACH_ASSOCIATED_VENDORS_LOADING = '@@outreachAssociatedVendors/GET_OUTREACH_ASSOCIATED_VENDORS_LOADING';
export const GET_OUTREACH_ASSOCIATED_VENDORS_SUCCESS = '@@outreachAssociatedVendors/GET_OUTREACH_ASSOCIATED_VENDORS_SUCCESS';
export const GET_OUTREACH_ASSOCIATED_VENDORS_FAILURE = '@@outreachAssociatedVendors/GET_OUTREACH_ASSOCIATED_VENDORS_FAILURE';

export const getOutreachAssociatedVendors = (outreachId, vendorName) => async (dispatch) => {
  dispatch({
    type: GET_OUTREACH_ASSOCIATED_VENDORS_LOADING,
  });

  var body = vendorName ? searchOutreachAssociatedVendorsBody(vendorName) : getOutreachAssociatedVendorsBody();

  try {
    const response = await axios.post(`/app/customer/outreach/${outreachId}/recipient/search`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_OUTREACH_ASSOCIATED_VENDORS_FAILURE,
        payload: {},
      });
      // dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: GET_OUTREACH_ASSOCIATED_VENDORS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: GET_OUTREACH_ASSOCIATED_VENDORS_FAILURE,
      payload: {},
    });
  }
};

export const POST_ASSESSMENT_QUESTIONNAIRE_REVIW_LOG_LOADING = '@@outreachAssociatedVendors/POST_ASSESSMENT_QUESTIONNAIRE_REVIW_LOG_LOADING';
export const POST_ASSESSMENT_QUESTIONNAIRE_REVIW_LOG_SUCCESS = '@@outreachAssociatedVendors/POST_ASSESSMENT_QUESTIONNAIRE_REVIW_LOG_SUCCESS';
export const POST_ASSESSMENT_QUESTIONNAIRE_REVIW_LOG_FAILURE = '@@outreachAssociatedVendors/POST_ASSESSMENT_QUESTIONNAIRE_REVIW_LOG_FAILURE';

export const addOutreachRequestorAssessmentQuestionnaireReviewLog =
  (outreachId, outreachRequestorAssessmentQuestionnaireReviewLogId, reqBody) => async (dispatch) => {
    dispatch({
      type: POST_ASSESSMENT_QUESTIONNAIRE_REVIW_LOG_LOADING,
    });

    var body = JSON.stringify(reqBody);

    try {
      let response;

      if (!isNil(outreachRequestorAssessmentQuestionnaireReviewLogId)) {
        response = await axios.put(`/app/customer/outreach/${outreachId}/requestorreviewlog`, body);
      } else {
        response = await axios.post(`/app/customer/outreach/${outreachId}/requestorreviewlog`, body);
      }

      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: POST_ASSESSMENT_QUESTIONNAIRE_REVIW_LOG_FAILURE,
        });
        // dispatch(setErrors(response?.data?.Errors));
      } else {
        dispatch({
          type: POST_ASSESSMENT_QUESTIONNAIRE_REVIW_LOG_SUCCESS,
        });
      }
    } catch {
      dispatch({
        type: POST_ASSESSMENT_QUESTIONNAIRE_REVIW_LOG_FAILURE,
      });
    }
  };

export const GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_LOADING = '@@outreachAssociatedVendors/GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_LOADING';
export const GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_SUCCESS = '@@outreachAssociatedVendors/GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_SUCCESS';
export const GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_FAILURE = '@@outreachAssociatedVendors/GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_FAILURE';

export const getReviewStatusRecipientAssessment = (assessmentId, assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({
    type: GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_LOADING,
  });

  var body = getReviewStatusRecipientAssessmentBody(assessmentQuestionnaireId);

  try {
    const response = await axios.post(`/assessment/${assessmentId}/questionnaire/search`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_FAILURE,
        payload: {},
      });
      // dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_SUCCESS,
        payload: response?.data,
      });
    }
  } catch {
    dispatch({
      type: GET_REVIEW_STATUS_RECIPIENT_ASSESSMENT_FAILURE,
      payload: {},
    });
  }
};
