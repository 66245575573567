export const getoutreachInformationSummaryBody = () => {
  return {
    Intent: '',
    Fields: [
      'RequestorOrgId',
      'OutreachId',
      'OutreachName',
      'Description',
      'CountOfRecipientOrgsSentAssessment',
      'CountOfRecipientOrgsResponded',
      'CountOfRecipientOrgsReviewed',
      'CountOfRecipientOrgsWithNegativeAnswers',
    ],
    Criterion: [],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getOutreachAssociatedVendorsBody = () => {
  return {
    Intent: '',
    Fields: ['OutreachId', 'OutreachName', 'RecipientOrgId', 'RecipientOrgName', 'RecipientRequestorOrgId', 'RecipientRequestorOrgName', 'RecipientOrgSummary'],
    Criterion: [],
    Sort: [
      {
        Field: 'RecipientOrgName',
        Order: 'ASC',
      },
    ],
    Page: 0,
    PageSize: 0,
  };
};

export const searchOutreachAssociatedVendorsBody = (searchValue) => {
  return {
    Intent: '',
    Fields: ['OutreachId', 'OutreachName', 'RecipientOrgId', 'RecipientOrgName', 'RecipientRequestorOrgId', 'RecipientRequestorOrgName', 'RecipientOrgSummary'],
    Criterion: [
      {
        Field: 'RecipientOrgName',
        Operator: 'contains',
        Value: searchValue,
      },
    ],
    Sort: [
      {
        Field: 'RecipientOrgName',
        Order: 'ASC',
      },
    ],
    Page: 0,
    PageSize: 0,
  };
};

export const getReviewStatusRecipientAssessmentBody = (assessmentQuestionnaireId) => {
  return {
    intent: '',
    fields: ['RecipientOrgId', 'RecipientOrgName', 'AssessmentName', 'OutreachId', 'SumOfPositiveResponseScore', 'SumOfNegativeResponseScore'],
    criterion: [
      {
        Field: 'AssessmentQuestionnaireId',
        Operator: '=',
        Value: assessmentQuestionnaireId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
