export const sidebarMenu = () => {
  return {
    marginBottom: '20px',
  };
};

export const sectionTitle = (theme) => {
  return {
    paddingBottom: '24px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  };
};

export const questionnaireContainer = () => {
  return {
    padding: '40px',
  };
};

export const sidebarWrapper = () => {
  return {
    padding: '10px',
    boxShadow: '-4px -4px 40px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginRight: '40px',
    width: '120px',
  };
};

export const rightSidePanelWrapper = () => {
  return {
    width: 'calc(100% - 160px)',
  };
};

export const generalInformation = () => {
  return {
    padding: '24px 0',
  };
};

export const buttonFooter = (theme) => {
  return {
    padding: '16px 0',
    borderTop: `2px solid ${theme.palette.primary.main}`,
  };
};

export const buttonFooterAction = () => {
  return {
    marginRight: '12px',
    backgroundColor: '#0083E9',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#0083e9d6',
    },
  };
};

export const questionListSection = () => {
  return {
    margin: '16px 0',
    '& .activeQuestion': {
      backgroundColor: '#e2e2e2',
    },
  };
};

export const questionListItem = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    paddingRight: '0px',
    marginBottom: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    '& .reorder': {
      marginRight: '8px',
    },
  };
};

export const questionListItemName = () => {
  return {
    fontSize: '14px',
    flexGrow: 1,
  };
};

export const iconWrapper = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f1f1f1',
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    marginRight: '8px',
    '& .icon': {
      fontSize: '12px',
    },
  };
};

export const questionHeader = () => {
  return {
    padding: '32px 0',
    '& .requireButton': {
      marginRight: '8px',
    },
  };
};

export const answerTypes = (theme) => {
  return {
    display: 'flex',
    '& .answerTypeOption': {
      height: '120px',
      width: '120px',
      marginRight: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      color: theme.palette.grey[700],
      backgroundColor: '#FFF',
      border: `1px solid ${theme.palette.grey[300]}`,
      textAlign: 'center',
    },
    '& .answerTypeOptionActive': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.grey[300],
    },
  };
};

export const responseInput = () => {
  return {
    marginBottom: '24px',
  };
};

export const responseSection = () => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
  };
};

export const responseSectionActions = () => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
};

export const responseSectionActionsCancel = (theme) => {
  return {
    marginRight: '16px',
    backgroundColor: `${theme.palette.grey[700]}`,
  };
};

export const listItemStyle = (theme) => {
  return {
    backgroundColor: theme.palette.background.default,
    padding: '24px',
    borderRadius: '8px',
    marginBottom: '29px',
  };
};

export const addQuestionstyles = () => {
  return {
    width: 'unset',
    borderRadius: '4px',
    border: '2px solid',
    '&:hover': {
      border: '2px solid',
    },
    marginRight: '10px',
  };
};

export const iconbuttonStyles = () => {
  return {
    padding: '8px 10px',
    height: 27,
    width: 30,
    borderRadius: '5px',
    minWidth: 'unset',
  };
};

export const questioncontent = () => {
  return {
    width: 'calc(100% - 70px)',
    display: 'flex',
  };
};

export const questionstree = (theme, ownerState) => {
  return {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-block',
    opacity: () => {
      if (ownerState) {
        return 0.5;
      } else {
        return 'unset';
      }
    },
    pointerEvents: () => {
      if (ownerState) {
        return 'none';
      } else {
        return 'unset';
      }
    },
  };
};

export const scoringIconsWrapper = () => {
  return {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridGap: '15px',
    marginRight: '30px',
  };
};

export const scoringIcons = (active, state) => {
  let backgroundColor = '#FFF',
    color = '#FFF';
  if (active) {
    if (state === 'positive') {
      backgroundColor = '#2C9543';
    } else if (state === 'negative') {
      backgroundColor = '#9D2727';
    }
  } else {
    color = 'inherit';
  }
  return {
    cursor: 'pointer',
    width: '25px',
    height: '25px',
    backgroundColor,
    padding: '7px',
    borderRadius: '8px',
    border: '2px solid transparent',
    color,
  };
};

export const questionnaireStatusContainer = (theme) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '36px',
    '& .success': {
      backgroundColor: `${theme.palette.success.main}`,
      color: '#FFF',
    },
    '& .error': {
      backgroundColor: '#852C2C',
      color: '#FFF',
    },
  };
};

export const questionnaireStatusWrapper = (theme) => {
  return {
    backgroundColor: `${theme.palette.primary.main}`,
    color: '#FFF',
    boxShadow: '0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%)',
    padding: '12px 18px',
    borderRadius: '8px',
    textTransform: 'Uppercase',
  };
};

export const questionnaireStatusBox = () => {
  return {
    marginBottom: '42px',
  };
};

export const addCommentsTitle = () => {
  return {
    margin: '32px 0 0px',
  };
};

export const commentBox = () => {
  return {
    backgroundColor: '#FFF',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '-4px -4px 40px rgb(0 0 0 / 10%)',
    '& .MuiInputBase-root': {
      legend: {
        width: '0',
      },
    },
  };
};

export const errorText = () => {
  return {
    color: '#9D2727',
    lineHeight: '1.5',
    fontSize: '10px!important',
    fontWeight: '700',
    textAlign: 'left',
    marginTop: '3px',
    marginRight: '14px',
    marginBottom: '0',
    marginLeft: '14px',
  };
};
export const dateRangePickerStyle = (theme,ownerState) => {
  return {
    '.rmdp-input': {
    border: 'unset',
    height: '62px',
    borderRadius: '6px',
    margin: '0px',
    backgroundColor: theme.palette.common.white,
    fontSize: '14px',
    cursor: 'pointer',
    color: ownerState ? theme.palette.text.placeholder : theme.palette.text.default,
    boxShadow: '-4px -4px 40px rgba(0, 0, 0, 0.1)',
    width: '108%',
    fontFamily: 'Roboto',
    },
    '.placeHolder': {
      color: theme.palette.grey[600],
    },
  };
};
export const dateRangeCalendarIcon = () => {
  return {
    left: '182px',
    top: '24px',
    position: 'absolute',
  };
};