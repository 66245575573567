import { SEARCH_PREFERENCES_LIST_FAILURE, SEARCH_PREFERENCES_LIST_LOADING, SEARCH_PREFERENCES_LIST_SUCCESS, SET_COMMUNICATION_PREFERENCE_FAILURE, SET_COMMUNICATION_PREFERENCE_LOADING, SET_COMMUNICATION_PREFERENCE_SUCCESS } from 'actions/communicationPreferences';
import { isEmpty, isNil } from 'lodash';

const initialState = {
    loading: false,
    loadingSetPreference: false,
    preferences: []
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SEARCH_PREFERENCES_LIST_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case SEARCH_PREFERENCES_LIST_SUCCESS: {
            const list = [];
            !isNil(payload) && !isEmpty(payload) && payload?.forEach((item) => {
                list.push({
                    ...item,
                    Preference: item?.CommunicationPreferenceList[0]?.EnableStatus | 0
                });
            });
            return {
                ...state,
                loading: false,
                preferences: list
            }
        }
        case SEARCH_PREFERENCES_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                preferences: []
            }
        }
        case SET_COMMUNICATION_PREFERENCE_LOADING: {
            return {
                ...state,
                loadingSetPreference: true,
            };
        }
        case SET_COMMUNICATION_PREFERENCE_SUCCESS: {
            return {
                ...state,
                loadingSetPreference: false
            }
        }
        case SET_COMMUNICATION_PREFERENCE_FAILURE: {
            return {
                ...state,
                loadingSetPreference: false
            }
        }
        default:
            return state;
    }
};
