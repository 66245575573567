export const getvendorQuestionnaireAnswersBody = (assessmentQuestionnaireId) => {
  return {
    Intent: '',
    Fields: [
      'AssessmentQuestionnaireId',
      'QuestionnaireTemplateQuestionId',
      'QuestionId',
      'QuestionResponseCategory',
      'QuestionResponseText',
      'QuestionResponseComment',
      'ResponseScore',
      'DisplayOrder',
      'IsRequired',
      'QuestionText',
      'AllowCommentInResponse',
      'QuestionType',
      'QuestionResponseType',
      'ResponseOptionList',
      'PositiveResponseScore',
      'NegativeResponseScore',
    ],
    Criterion: [
      {
        Field: 'AssessmentQuestionnaireId',
        Operator: '=',
        Value: assessmentQuestionnaireId,
      }
    ],
    Sort: [
      {
        Field: 'DisplayOrder',
        Order: 'ASC',
      },
    ],
    Page: 0,
    PageSize: 0,
  };
};
