export const formLabelFieldStyles = () => {
    return {
        fontSize: '12px',
        margin: '-6px 0px 4px !important'
    };
};

export const countryCodeComponentStyles = (isDisabled) => {
    return {
        width: '152px !important',
        '.MuiFormControl-root': {
        },
        '.MuiInputBase-root': {
            '.MuiInputAdornment-root': {
                pointerEvents: isDisabled ? 'none' : 'auto', 
                opacity: isDisabled ? 0.5 : 1, 
            },
            '.MuiPhoneNumber-flagButton': {
            },
            '.MuiInputBase-input ': {
            }
        }
    };
};