import axios from 'axios';
import { getClientProfileActiveOutreachesBody, getOutreachBody } from '../utils/clientProfileActiveOutreachUtils';
import { isNil } from 'lodash';

export const GET_CLIENT_ACTIVE_OUTREACH_LOADING = '@@clientProfileActiveOutreach/GET_CLIENT_ACTIVE_OUTREACH_LOADING';
export const GET_CLIENT_ACTIVE_OUTREACH_SUCCESS = '@@clientProfileActiveOutreach/GET_CLIENT_ACTIVE_OUTREACH_SUCCESS';
export const GET_CLIENT_ACTIVE_OUTREACH_FAILURE = '@@clientProfileActiveOutreach/GET_CLIENT_ACTIVE_OUTREACH_FAILURE';
export const GET_OUTREACH_SUCCESS = '@@clientProfileActiveOutreach/GET_OUTREACH_SUCCESS';

export const getClientProfileActiveOutreaches = (outreachId) => async (dispatch) => {
  dispatch({
    type: GET_CLIENT_ACTIVE_OUTREACH_LOADING,
  });

  var body = !isNil(outreachId) ? getOutreachBody(outreachId) : getClientProfileActiveOutreachesBody();

  try {
    const response = await axios.post(`/app/customer/outreach/search`, body);

    if (response?.data?.ErrorCount > 0 && isNil(outreachId)) {
      dispatch({
        type: GET_CLIENT_ACTIVE_OUTREACH_FAILURE,
        payload: {},
      });
      // dispatch(setErrors(response?.data?.Errors));
    } else if (response?.data?.ErrorCount > 0 && !isNil(outreachId)) {
      dispatch({
        type: GET_OUTREACH_SUCCESS,
        payload: true,
      });
    } else {
      dispatch({
        type: GET_CLIENT_ACTIVE_OUTREACH_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: GET_CLIENT_ACTIVE_OUTREACH_FAILURE,
      payload: {},
    });
  }
};

export const GET_CLIENT_OUTREACH_LOADING = '@@clientProfileActiveOutreach/GET_CLIENT_OUTREACH_LOADING';
export const GET_CLIENT_OUTREACH_SUCCESS = '@@clientProfileActiveOutreach/GET_CLIENT_OUTREACH_SUCCESS';
export const GET_CLIENT_OUTREACH_FAILURE = '@@clientProfileActiveOutreach/GET_CLIENT_OUTREACH_FAILURE';

export const getClientOutreaches = (formData, referenceId, isJoinOutreach) => async (dispatch) => {
  dispatch({
    type: GET_CLIENT_OUTREACH_LOADING,
  });

  var body = {
    Title: formData.title,
    ClientOutreachRequestId: 0,
    OutreachRequestTypeId: isJoinOutreach ? 2 : 1,
    ReferenceId: referenceId,
    Description: formData.description,
  };

  try {
    const response = await axios.post(`/client/outreachrequest`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_CLIENT_OUTREACH_FAILURE,
        payload: {},
      });
      // dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: GET_CLIENT_OUTREACH_SUCCESS,
        payload: response?.data?.Data,
      });
    }
  } catch {
    dispatch({
      type: GET_CLIENT_OUTREACH_FAILURE,
      payload: {},
    });
  }
};
