import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { getIncidentNotificationBody } from '../utils/vendorIncidentUtils';

export const SEARCH_INCIDENT_NOTIFICATION_LOADING = '@@vendorIncidentNotification/SEARCH_INCIDENT_NOTIFICATION_LOADING';
export const SEARCH_INCIDENT_NOTIFICATION_SUCCESS = '@@vendorIncidentNotification/SEARCH_INCIDENT_NOTIFICATION_SUCCESS';
export const SEARCH_INCIDENT_NOTIFICATION_FAILURE = '@@vendorIncidentNotification/SEARCH_INCIDENT_NOTIFICATION_FAILURE';

export const searchIncidentNotification = () => async (dispatch) => {
  dispatch({
    type: SEARCH_INCIDENT_NOTIFICATION_LOADING,
  });

  const body = getIncidentNotificationBody();

  try {
    const response = await axios.post(`/client/incidentnotification/search`, body);
    dispatch({
      type: SEARCH_INCIDENT_NOTIFICATION_SUCCESS,
      payload: response?.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_INCIDENT_NOTIFICATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_INCIDENT_NOTIFICATION_LOADING = '@@vendorIncidentNotification/POST_INCIDENT_NOTIFICATION_LOADING';
export const POST_INCIDENT_NOTIFICATION_SUCCESS = '@@vendorIncidentNotification/POST_INCIDENT_NOTIFICATION_SUCCESS';
export const POST_INCIDENT_NOTIFICATION_FAILURE = '@@vendorIncidentNotification/POST_INCIDENT_NOTIFICATION_FAILURE';

export const postIncidentNotification = (formData) => async (dispatch) => {
  dispatch({
    type: POST_INCIDENT_NOTIFICATION_LOADING,
  });

  try {
    const response = await axios.post(`/client/incidentnotification`, formData);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({ type: POST_INCIDENT_NOTIFICATION_FAILURE });
      dispatch(searchIncidentNotification());
    } else {
      dispatch({
        type: POST_INCIDENT_NOTIFICATION_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchIncidentNotification());
    }
  } catch (err) {
    dispatch({
      type: POST_INCIDENT_NOTIFICATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_INCIDENT_ALERT_POPUP = '@@vendorIncidentNotification/SET_INCIDENT_ALERT_POPUP';

export const setIncidentAlertPopup = (value) => async (dispatch) => {
  dispatch({
    type: SET_INCIDENT_ALERT_POPUP,
    payload: value,
  });
};
