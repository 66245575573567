import {
  POST_CONTACT_TO_LIST_LOADING,
  POST_CONTACT_TO_LIST_SUCCESS,
  POST_CONTACT_TO_LIST_FAILURE,
  DELETE_CONTACT_FROM_LIST_LOADING,
  DELETE_CONTACT_FROM_LIST_SUCCESS,
  DELETE_CONTACT_FROM_LIST_FAILURE,
  PUT_CONTACT_TO_LIST_LOADING,
  PUT_CONTACT_TO_LIST_SUCCESS,
  PUT_CONTACT_TO_LIST_FAILURE,
  SET_CONTACTS_LOADING,
  SET_CONTACTS_SUCCESS,
  SET_CONTACTS_FAILURE,
  GET_CLIENT_PROFILE_CONTACTS_LOADING,
  GET_CLIENT_PROFILE_CONTACTS_SUCCESS,
  GET_CLIENT_PROFILE_CONTACTS_FAILURE,
  SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_LOADING,
  SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_SUCCESS,
  SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_FAILURE,
} from '../actions/clientProfileCompanyContacts';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { DELETED_SUCCESSFULLY, SAVED_SUCCESSFULLY } from '../constants/errorMessage';

const initialState = {
  loading: false,
  contactDetails: {},
  existingEmailCheck: null,
  existingEmail: [],
};

export const clientProfileCompanyContacts = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENT_PROFILE_CONTACTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CLIENT_PROFILE_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          clientContactDetailsList: payload,
        },
      };
    }
    case GET_CLIENT_PROFILE_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          clientContactDetailsList: [],
        },
      };
    }
    case SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_SUCCESS: {
      const { checkerValue } = action;

      if (checkerValue === 'FirstName') {
        return {
          ...state,
          loading: false,
          existingEmailCheck: null,
          contactDetails: {
            ...state.contactDetails,
            clientContactDetailsList: payload,
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          existingEmail: payload,
          existingEmailCheck: payload.length > 0 ? true : false,
        };
      }
    }
    case SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          clientContactDetailsList: [],
        },
      };
    }
    case POST_CONTACT_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_CONTACT_TO_LIST_SUCCESS: {
      let contactDetailsClone = cloneDeep(state.contactDetails);
      let contactList = contactDetailsClone.clientContactDetailsList ?? [];
      contactList.push(payload);
      toast.success(SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          clientContactDetailsList: contactList,
          contactToBeSelected: payload.ClientContactId,
        },
      };
    }
    case POST_CONTACT_TO_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_CONTACT_FROM_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_CONTACT_FROM_LIST_SUCCESS: {
      let contactDetailsClone = cloneDeep(state.contactDetails);
      let contactList = contactDetailsClone.clientContactDetailsList ?? [];
      let updatedList = contactList.filter((item, index) => index !== payload.formData.itemToBeDeletedIndex);
      toast.success(DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          clientContactDetailsList: updatedList,
          contactToBeSelected: null,
        },
      };
    }
    case DELETE_CONTACT_FROM_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_CONTACT_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_CONTACT_TO_LIST_SUCCESS: {
      let contactDetailsClone = cloneDeep(state.contactDetails);
      let contactList = contactDetailsClone.clientContactDetailsList ?? [];
      contactList[payload.formData.itemToBeUpdatedIndex] = payload.formData.formData;
      toast.success(SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          clientContactDetailsList: contactList,
          contactToBeSelected: null,
        },
      };
    }
    case PUT_CONTACT_TO_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_CONTACTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_CONTACTS_SUCCESS: {
      return {
        ...state,
        existingEmailCheck: null,
        contactDetails: {
          ...state.contactDetails,
          ...payload,
        },
      };
    }
    case SET_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
