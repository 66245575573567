export const NO_DATA_FOUND = 'No data found';
export const NO_CONTACTS_FOUND = [
  {
    Type: 'I',
    Message: 'No Contacts found, please add Contacts.',
  },
];
export const NO_USERS_FOUND = [
  {
    Type: 'I',
    Message: 'No Users found, please add Users.',
  },
];
export const NO_VENDOR_DATA_FOUND = [
  {
    Type: 'I',
    Message: 'No information has been provided by the vendor.',
  },
];
export const NO_DASHBOARD_DATA_FOUND = [
  {
    Type: 'I',
    Message: 'Dashboard is in the making.',
  },
];

export const SAVED_SUCCESSFULLY = 'Saved successfully';
export const DELETED_SUCCESSFULLY = 'Deleted successfully';
export const ERROR_MESSAGE = 'Something went wrong, Please try again';
export const COMMENTS_ADDED_SUCCESSFULLY = 'Comments Added Successfully';
export const CLOSE_SUPPORT_TICKET_SUCCESSFULLY = 'Support Ticket Closed Successfully';
export const CREATE_SUPPORT_TICKET_SUCCESSFULLY = 'Support Ticket Created Successfully';
export const UPDATED_SUCCESSFULLY = 'Updated successfully';

export const NO_CONTACTS_FOUND_ON_LIST = 'No Contacts Found';

export const REACT_TO_PDF_ERROR = 'Target ref must be used or informed.';

export const MANUAL_ADD_VENDOR_TO_CLIENT_SUCCESSFULLY = 'Request Submitted Successfully';
export const ADD_VENDOR_TO_CLIENT_SUCCESSFULLY = 'Vendor Added Successfully';
export const PROVIDE_ALTERNATE_GUIDANCE_SUCCESSFULLY = 'Alternate Guidance Provided Successfully';
export const ASSESSMENT_QUEUE_ORDER_SUCCESSFULLY = 'Assessment(s) Queue re-ordering Request Submitted Successfully';

export const ASSOCIATION_SUCCESSFULLY = 'Associated Successfully';