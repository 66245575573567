import { TRUE } from 'constants/constants';
import { format } from 'date-fns';
import { isArray, isEmpty, isNil, isString } from 'lodash';
import { escapeSpecialCharacters } from './specialCharactersUtils';

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (key === 'VendorOrgId') {
        criterion.push({
          Field: key,
          Operator: '=',
          Value: searchData[key],
        });
    }
    else if(key !== 'VendorOrgId'){
    if (isString(searchData[key])) {
      criterion.push({
        Field: key,
        Operator: 'contains',
        Value: escapeSpecialCharacters(searchData[key]),
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  }
  });
  return criterion;
};

const getMappedProductsSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key])) {
      criterion.push({
        Field: key,
        Operator: '=',
        Value: searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

export const getVendorsListBody = (searchData = {}) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: [
      'CustomerVendorId',
      'CustomerVendorLogId',
      'CustomerOrgId',
      'CustomerOrgName',
      'VendorOrgId',
      'VendorOrgName',
      'VendorAddressLine1',
      'VendorAddressLine2',
      'VendorAddressCity',
      'VendorAddressState',
      'VendorAddressCountry',
      'VendorWebsite',
      'IsTPIR',
      'VendorAlias',
      'EndOfRelationShipDate',
      'Status',
      'DynCustomerVendorId',
      'VendorIdForCustomer',
      'DunsNumber',
      'IsVerified',
      'DynVendorAccountId'
    ],
    criterion: criterion,
    sort: [
      {
        field: 'VendorOrgName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const updateVendorDetailsSearchBody = (formData) => {
  return {
    VendorOrgId: formData?.VendorOrgId,
    CustomerVendorId: formData?.CustomerVendorId,
    CustomerVendorLog: {
      CustomerVendorLogId: isNil(formData?.CustomerVendorLogId) ? 0 : formData?.CustomerVendorLogId,
    },
    VendorNameForCustomer: formData?.VendorAlias,
    Status: formData?.Status,
    EndOfRelationShipDate:
      isNil(formData?.EndOfRelationShipDate) || isEmpty(formData?.EndOfRelationShipDate)
        ? null
        : format(new Date(formData?.EndOfRelationShipDate), "yyyy-MM-dd'T'HH:mm:ss"),
    IsTPIR: parseInt(formData?.IsTPIR),
    DynCustomerVendorId: formData?.DynCustomerVendorId,
    VendorIdForCustomer: formData?.VendorIdForCustomer,
  };
};

export const getVendorSearchListBody = (formData = {}) => {
  let criteria = [];
  if ((!isNil(formData?.VendorOrgName) && !isNil(formData?.DomainName)) || !isNil(formData?.VendorOrgName)) {
    let domainURL = !isNil(formData?.DomainName) ? formData?.DomainName : '';
    criteria = [
      {
        Field: 'Org_DomainName',
        Operator: '=',
        Value: `+${formData?.VendorOrgName} ${domainURL}`,
      },
    ];
  } else if (!isNil(formData?.DomainName)) {
    criteria = [
      {
        Field: 'DomainName',
        Operator: 'contains',
        Value: `${formData?.DomainName}`,
      },
    ];
  }
  return {
    Intent: '',
    Fields: [
      'VendorOrgId',
      'VendorOrgName',
      'DomainName',
      'RegisteredBusinessAddressLine1',
      'RegisteredBusinessAddressLine2',
      'RegisteredBusinessAddressCity',
      'RegisteredBusinessAddressState',
      'RegisteredBusinessAddressCountry',
      'RegisteredBusinessAddressZip',
      'NumberOfEmployeesRange',
      'YearFounded',
      'FullTextSearch',
      'IsAssociatedWithCustomer',
      'CustomerVendorRowLimit',
      'VendorIdForCustomer',
    ],
    Criterion: [
      ...criteria,
      {
        Field: 'IsVerified',
        Operator: '=',
        Value: TRUE,
      },
      {
        Field: 'Active',
        Operator: '=',
        Value: TRUE,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};


export const getVendorInformationSearchBody = (vendorOrgName) => {
  return {
    intent: '',
    fields: [
      'VendorOrgId',
      'VendorOrgName',
      'DomainName',
    ],
    criterion: [
      {
        field: 'VendorOrgName',
        operator: "=",
        value: vendorOrgName,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getProductListSearchBody = (searchData = {}) => {
  let criterion = [];
  if (!isNil(searchData)) {
    criterion = [...criterion, ...getMappedProductsSearchCriterion(searchData)];
  }
  return{
    "intent": "",
    "fields": [
      "ProductName",
      "ProductType",
      "ProductVendorRole",
      "ProductGroup",
      "CustomerVendorId",
      "ProductVendorId",
      "CustomerVendorProductId",
      "CCDynVRASId",
      "Oem",
      "CORLClearedStatus",
      "Description",
      "ProductNameForCustomerVendor",
      "ProductIdForCustomerVendor",
      "StartOfRelationShipDate",
      "EndOfRelationShipDate"
    ],
    "criterion": criterion,
    "sort": [
      {
        "field": "ProductName",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0
  }
}

export const getCustomerVendorProductLookupSearchBody = () => {
  return{
    "intent": "",
    "fields": [
      "ProductName",
      "CustomerVendorId", 
      "Oem"    
    ],
    "criterion": [],
    "sort": [
      {
        "field": "ProductName",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0
  }
}
