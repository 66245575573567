export const LIKELIHOOD_DEFINITIONS = [
  {
    grade: '(A) Managed',
    description: `The assessment identified a security program that is implemented and aligned with reasonable industry practice. The likelihood of a breach is minimized to a level at which no remediation is necessary.`,
    gradeColor: '#2C9543',
    impactColor: '#B3E2BE',
  },
  {
    grade: '(B) Low',
    description: `The assessment identified a security program that is implemented and mostly aligned with reasonable industry practice. Some remediation may be necessary, but there is a low likelihood of an immediate breach.`,
    gradeColor: '#0083E9',
    impactColor: '#9EC9EB',
  },
  {
    grade: '(C) Medium',
    description: `The assessment identified a security program that is partially implemented and partially aligned with reasonable industry practice. Remediation activities should be identified and planned with corrections implemented in the near term to avoid a potential breach in the future.`,
    gradeColor: '#FFC107',
    impactColor: '#FFE16A',
  },
  {
    grade: '(D) High',
    description: `The assessment identified a security program that is minimally implemented and minimally aligned with industry best practice. Remediation activities should be identified and planned with corrections implemented as there is a high likelihood of an imminent breach`,
    gradeColor: '#F78D2A',
    impactColor: '#F7DAA2',
  },
  {
    grade: '(F) Very High',
    description: `The assessment identified a vulnerability that indicates an existing or imminent breach. Incident response activities should be activated.`,
    gradeColor: '#9D2727',
    impactColor: '#F2B5B5',
  },
];

export const IMPACT_DEFINITIONS = [
  {
    grade: 'Very High',
    description: `A vendor who receives all regulated or sensitive data on patients, employees, or internal company operations or who provides mission-critical or life-critical services. The compromise, failure, or disruption of the vendor's products or services would have an extreme impact on the operations, finances, or reputation of the client's organization.`,
    gradeColor: '#9D2727',
    impactColor: '#F2B5B5',
  },
  {
    grade: 'High',
    description: `A vendor who receives most regulated or sensitive data on patients, employees, or internal company operations or who provides highly business-critical or highly patient-care-critical services. The compromise, failure, or disruption of the vendor's products or services would have a significant impact on the operations, finances, or reputation of the client’s organization.`,
    gradeColor: '#F78D2A',
    impactColor: '#F7DAA2',
  },
  {
    grade: 'Medium',
    description: `A vendor who receives a sub-set of regulated or sensitive data on patients, employees, or internal company operations or who provides moderately business-critical or moderately patient-care-critical services. The compromise, failure, or disruption of the vendor's products or services would cause some inconvenience or disruption for the client, but it would not necessarily cause significant impact on the operations, finances, or reputation of the client’s organization. `,
    gradeColor: '#FFC107',
    impactColor: '#FFE16A',
  },
  {
    grade: 'Low',
    description: `A vendor who receives only non-regulated or non-sensitive client data or who provides low business-critical or low patient-care-critical services. The compromise, failure, or disruption of the vendor's products or services would have minimal impact on the operations, finances, or reputation of the client’s organization and could likely be easily replaced or worked around. `,
    gradeColor: '#0083E9',
    impactColor: '#9EC9EB',
  },
  {
    grade: 'Very Low',
    description: `A vendor who does not receive data or who provides non-essential business services or services that have no patient care impact. The compromise, failure, or disruption of the vendor's products or services would have no material impact on the operations, finances, or reputation of the client’s organization and could be easily replaced or worked around.`,
    gradeColor: '#2C9543',
    impactColor: '#B3E2BE',
  },
];

export const ADEQUATE_VALUE = 2;
export const INADEQUATE_VALUE = 1;
export const NOT_APPLICABLE_VALUE = 3;

export const VALIDATION_OPTIONS = {
  ALL: 'ALL',
  MET: 'MET',
  UNMET: 'UNMET',
  NOT_APPLICABLE: 'N/A',
};

export const VALIDATION_FILTER_LIST = [
  {
    label: VALIDATION_OPTIONS.ALL,
    value: VALIDATION_OPTIONS.ALL,
  },
  {
    label: VALIDATION_OPTIONS.MET,
    value: VALIDATION_OPTIONS.MET,
  },
  {
    label: VALIDATION_OPTIONS.UNMET,
    value: VALIDATION_OPTIONS.UNMET,
  },
  {
    label: VALIDATION_OPTIONS.NOT_APPLICABLE,
    value: VALIDATION_OPTIONS.NOT_APPLICABLE,
  },
];

export const RISK_RATING_SUMMARY_OPTIONS = {
  ALL: 'ALL',
  MET: 'MET',
  UNMET: 'UNMET',
  NOT_APPLICABLE: 'N/A',
  CLIENT_ACCEPTED: 'CLIENT ACCEPTED',
};

export const RISK_RATING_SUMMARY_LIST = [
  {
    label: RISK_RATING_SUMMARY_OPTIONS.ALL,
    value: RISK_RATING_SUMMARY_OPTIONS.ALL,
  },
  {
    label: RISK_RATING_SUMMARY_OPTIONS.MET,
    value: RISK_RATING_SUMMARY_OPTIONS.MET,
  },
  {
    label: RISK_RATING_SUMMARY_OPTIONS.UNMET,
    value: RISK_RATING_SUMMARY_OPTIONS.UNMET,
  },
  {
    label: RISK_RATING_SUMMARY_OPTIONS.CLIENT_ACCEPTED,
    value: RISK_RATING_SUMMARY_OPTIONS.CLIENT_ACCEPTED,
  },
  {
    label: RISK_RATING_SUMMARY_OPTIONS.NOT_APPLICABLE,
    value: RISK_RATING_SUMMARY_OPTIONS.NOT_APPLICABLE,
  },
];

export const RISK_RATING_SUMMARY_NOT_APPLICABLE_STATUS_LIST = ['Control Not Applicable'];

export const RISK_RATING_SUMMARY_MET_STATUS_LIST_LEGACY = [
  'Control Satisfied'
];

export const RISK_RATING_SUMMARY_MET_STATUS_LIST_PORTAL2 = [
  'Closed - Remediated'
];

export const RISK_RATING_SUMMARY_MET_STATUS_LIST = RISK_RATING_SUMMARY_MET_STATUS_LIST_LEGACY + RISK_RATING_SUMMARY_MET_STATUS_LIST_PORTAL2;

export const RISK_RATING_SUMMARY_UNMET_STATUS_LIST_LEGACY = [
  'Vendor Nonresponsive',
  'Control Not Satisfied',
  'Remediation Not in Progress',
  'Not Accepted',
  'Sent to Vendor',
  'Evidence Requested',
  'Open',
  'Accepted',
  'Vendor Follow Up'
];

export const RISK_RATING_SUMMARY_UNMET_STATUS_LIST_PORTAL2 = [
  'Open',
  'Pending Vendor Response',
  'Pending Remediation Update',
  'Disputed by the Vendor / under audit review',
  'Remediation Declined by Vendor'
];

export const RISK_RATING_SUMMARY_UNMET_STATUS_LIST = RISK_RATING_SUMMARY_UNMET_STATUS_LIST_LEGACY + RISK_RATING_SUMMARY_UNMET_STATUS_LIST_PORTAL2;

export const RISK_RATING_SUMMARY_CLIENT_ACCEPTED_STATUS_LIST = ['Client Accepted Risk', 'Deferred'];

//These constants will be used in Executive Summary - Risk Rating Overview section
export const REPORT_IN_EXECUTIVE_SUMMARY = 1;
export const THRESHOLD_DESCRIPTION = 'CORL Pre-Assessment';

export const RATING_GOOD = 'GOOD';
export const RATING_FAIR = 'FAIR';
export const RATING_POOR = 'POOR';

export const MIN_GOOD_GRADE_LIMIT = '0.75';
export const MAX_POOR_GRADE_LIMIT = '0.50';

export const VENDOR_COLLABORATION_KEY_TO_TILE_HEADER_TITLE = {
  OverallCollaborationScore: 'OVERALL COLLABORATION SCORE',
  ResponseTimeliness: 'RESPONSE TIMELINESS',
  QuestionnaireParticipation: 'QUESTIONNAIRE PARTICIPATION',
  RemediationTimeliness: 'REMEDIATION TIMELINESS',
  TPIRProfileCompleteness: 'TPIR PROFILE COMPLETENESS',
};

export const RISK_FINDING_SUMMARY_PRIORITY_DETAILS = {
  HIGH_PRIORITY: 1,
  MEDIUM_PRIORITY: 2,
  LOW_PRIORITY: 3,
};

export const VENDOR_COLLABORATION_SCORE_ENABLED = 2;

export const ASSESSMENT_RESPONSES = 'Assessment Responses';
export const EVIDENCE_PROVIDED = 'Evidence Provided';
export const REMEDIATION_GUIDANCE = 'Remediation Guidance';
export const HISTORICAL_COMMENTS = 'View Historical Results';
export const SUBMIT_FEEDBACK = 'Submit Feedback';

export const VALIDATED = 'validated';
export const UNVALIDATED = 'unvalidated';

export const NO_VALIDATION_EVIDENCE_REVIEWS = 'No Validation Evidence Reviews';
export const NO_SEC_CERT_EVIDENCE_REVIEWS = 'No Security Certification Evidence Reviews';

export const RISK_RATING_INDEX = 0;
export const IMPACT_INDEX = 1;
export const LIKELIHOOD_INDEX = 2;

export const AGREED_UPON_DATE_TITLE = 'Agreed Upon Date';