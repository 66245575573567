import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { getVendorContactListSearchBody, getVendorContactListByFirstNameSearchBody } from '../utils/vendorContactInformationUtil';
import { isNil } from 'lodash';

export const SEARCH_VENDOR_CONTACT_LIST_LOADING = '@@vendorcontactsinformation/SEARCH_VENDOR_CONTACT_LIST_LOADING';
export const SEARCH_VENDOR_CONTACT_LIST_SUCCESS = '@@vendorcontactsinformation/SEARCH_VENDOR_CONTACT_LIST_SUCCESS';
export const SEARCH_VENDOR_CONTACT_LIST_FAILURE = '@@vendorcontactsinformation/SEARCH_VENDOR_CONTACT_LIST_FAILURE';

export const getVendorContactList = (vendorOrgId, searchVendorName) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_CONTACT_LIST_LOADING });
  try {
    const body = isNil(searchVendorName)
      ? getVendorContactListSearchBody(vendorOrgId, searchVendorName)
      : getVendorContactListByFirstNameSearchBody(vendorOrgId, searchVendorName);

    const response = await axios.post(`/vendor/contact/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_VENDOR_CONTACT_LIST_FAILURE,
        payload: response?.data,
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_CONTACT_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_CONTACT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
