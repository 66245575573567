import { isNil } from 'lodash';

export const getVendorContactListSearchBody = (vendorOrgId) => {
  return {
    intent: '',
    fields: ['FirstName', 'LastName', 'Title', 'Email', 'CellPhone', 'OfficePhone', 'ContactId', 'IsTeamContact', 'FullName'],
    criterion: [
      {
        field: 'VendorOrgId',
        operator: isNil(vendorOrgId) ? 'isnull' : '=',
        value: isNil(vendorOrgId) ? '' : vendorOrgId,
      },
    ],
    sort: [
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorContactListByFirstNameSearchBody = (vendorOrgId, searchVendorName) => {
  return {
    intent: '',
    fields: ['FirstName', 'LastName', 'Title', 'Email', 'CellPhone', 'OfficePhone', 'ContactId', 'IsTeamContact', 'FullName'],
    criterion: [
      {
        field: 'VendorOrgId',
        operator: isNil(vendorOrgId) ? 'isnull' : '=',
        value: isNil(vendorOrgId) ? '' : vendorOrgId,
      },
      {
        field: 'FullName',
        operator: 'contains',
        value: searchVendorName,
      },
    ],
    sort: [
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
