import axios from 'axios';
import { getvendorQuestionnaireAnswersBody } from '../utils/vendorQuestionnaireAnswersUtils';

export const GET_VENDOR_QUESTIONNAIRE_RESPONSE_LOADING = '@@vendorQuestionnaireAnswers/GET_VENDOR_QUESTIONNAIRE_RESPONSE_LOADING';
export const GET_VENDOR_QUESTIONNAIRE_RESPONSE_SUCCESS = '@@vendorQuestionnaireAnswers/GET_VENDOR_QUESTIONNAIRE_RESPONSE_SUCCESS';
export const GET_VENDOR_QUESTIONNAIRE_RESPONSE_FAILURE = '@@vendorQuestionnaireAnswers/GET_VENDOR_QUESTIONNAIRE_RESPONSE_FAILURE';

export const getvendorQuestionnaireAnswers = (assessmentId, assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({
    type: GET_VENDOR_QUESTIONNAIRE_RESPONSE_LOADING,
  });

  var body = getvendorQuestionnaireAnswersBody(assessmentQuestionnaireId);

  try {
    const response = await axios.post(`/assessment/${assessmentId}/questionnaire/${assessmentQuestionnaireId}/response/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_VENDOR_QUESTIONNAIRE_RESPONSE_FAILURE,
        payload: {},
      });
      // dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: GET_VENDOR_QUESTIONNAIRE_RESPONSE_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: GET_VENDOR_QUESTIONNAIRE_RESPONSE_FAILURE,
      payload: {},
    });
  }
};

export const SET_RESPONSE_LOADING = '@@vendorQuestionnaireAnswers/SET_RESPONSE_LOADING';
export const SET_RESPONSE_SUCCESS = '@@vendorQuestionnaireAnswers/SET_RESPONSE_SUCCESS';
export const SET_RESPONSE_FAILURE = '@@vendorQuestionnaireAnswers/SET_RESPONSE_FAILURE';

export const setResponse = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_RESPONSE_LOADING,
  });
  try {
    dispatch({
      type: SET_RESPONSE_SUCCESS,
      payload: payload,
    });
    if (callback) {
      callback();
    }
  } catch {
    dispatch({
      type: SET_RESPONSE_FAILURE,
      payload: {},
    });
  }
};
