import {
  ASSESSMENT_REQUEST_QUESTIONS_LIST_FAILURE,
  ASSESSMENT_REQUEST_QUESTIONS_LIST_LOADING,
  ASSESSMENT_REQUEST_QUESTIONS_LIST_SUCCESS,
  POST_ASSESSMENT_REQUEST_FAILURE,
  POST_ASSESSMENT_REQUEST_LOADING,
  POST_ASSESSMENT_REQUEST_SUCCESS,
  PUT_ASSESSMENT_REQUEST_FAILURE,
  PUT_ASSESSMENT_REQUEST_LOADING,
  PUT_ASSESSMENT_REQUEST_SUCCESS,
  SET_ASSESSMENT_REQUEST_EDIT_SUCCESS,
  SET_ASSESSMENT_REQUEST_NAVIGATE_SUCCESS,
  SET_ASSESSMENT_REQUEST_RESPONSE_QUESTIONS_SUCCESS,
  SET_ASSESSMENT_REQUEST_STEP_SUCCESS,
  SET_ASSESSMENT_REQUEST_SUCCESS,
  SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_LOADING,
  SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_SUCCESS,
  SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_FAILURE,
  SET_SELECTED_VENDOR,
  SET_SELECTED_VENDOR_TOGGLE,
  SET_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST,
  SET_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_COUNT,
  SET_ASSESSMENT_REQUEST_MANUAL_ADD_VENDOR_BOX,
  SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_LOADING,
  SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_SUCCESS,
  SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE,
  SET_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST,
  SET_RAW_CUSTOMER_VENDOR_PRODUCT_LIST,
  SEARCH_ASSESSMENT_REQUEST_LOADING,
  SEARCH_ASSESSMENT_REQUEST_SUCCESS,
  SEARCH_ASSESSMENT_REQUEST_FAILURE,
  SEARCH_COMMENTS_SUCCESS,
  SEARCH_COMMENTS_LOADING,
  SEARCH_COMMENTS_FAILURE,
  CLEAR_COMMENTS,
  SEARCH_DEFAULT_QT_REQUEST,
  SEARCH_DEFAULT_QT_SUCCESS,
  SEARCH_DEFAULT_QT_FAILIURE,
  SET_QUESTIONNAIRE_TEMPLATES,
  SET_ASSESSMENT_REQUEST,
} from 'actions/assessmentRequest';
import { ERROR_MESSAGE, SAVED_SUCCESSFULLY } from 'constants/errorMessage';
import { ASSESSMENT_REQUEST_FINAL_SUBMISSION_SUCCESS, NO_VENDOR_PRODUCTS_FOUND_OPTION } from 'pages/Assessment/AssessmentRequest/constants';
import { toast } from 'react-toastify';
import { MULTI_SELECT_RESPONSE_ID } from 'pages/Assessment/AssessmentRequest/constants';
import { getSelectOptionsList } from 'utils/arrayUtils';
import { multiSelectFields, required, singleSelectFields } from 'pages/Assessment/AssessmentRequest/fields';
import { isEmpty, isNil } from 'lodash';
import { DEAFULT_US_PHONE_CODE } from 'pages/ClientProfile/Company/constants';

const initialState = {
  loading: false,
  searcAssessmentLoading: false,
  assessmentRequest: {},
  assessmentRequestStep: 1,
  questionsList: [],
  questionsDisplayFields: [],
  assessmentRequestQuestionResponseList: [],
  assessmentRequestVendorSearchList: [],
  assessmentRequestVendorSearchListCount: null,
  assessmentRequestVendorSearchListLoading: false,
  showManualAddVendorBox: false,
  selectedVendor: {},
  selectedVendorToggle: null,
  isSuccess: false,
  isEdit: false,
  isNavigate: false,
  customerVendorProductList: [],
  rawCustomerVendorProductList: [],
  comment: {
    showComment: false,
    list: [],
  },
  questionnaireTemplateLoading: false,
  questionnaireTemplateList: [],
  pageNo: 0,
};

export const assessmentRequest = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ASSESSMENT_REQUEST_STEP_SUCCESS: {
      return {
        ...state,
        assessmentRequestStep: payload,
      };
    }
    case SET_ASSESSMENT_REQUEST_RESPONSE_QUESTIONS_SUCCESS: {
      return {
        ...state,
        assessmentRequestQuestionResponseList: payload,
      };
    }
    case SET_ASSESSMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        isSuccess: payload,
      };
    }
    case SET_ASSESSMENT_REQUEST_EDIT_SUCCESS: {
      return {
        ...state,
        isEdit: payload,
      };
    }
    case SET_ASSESSMENT_REQUEST_NAVIGATE_SUCCESS: {
      return {
        ...state,
        isNavigate: payload,
      };
    }
    case POST_ASSESSMENT_REQUEST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_ASSESSMENT_REQUEST_SUCCESS: {
      toast.success(ASSESSMENT_REQUEST_FINAL_SUBMISSION_SUCCESS);
      return {
        ...state,
        loading: false,
        assessmentRequest: { ...payload },
        isSuccess: true,
        isNavigate: true,
      };
    }
    case POST_ASSESSMENT_REQUEST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        isSuccess: false,
      };
    }
    case PUT_ASSESSMENT_REQUEST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_ASSESSMENT_REQUEST_SUCCESS: {
      toast.success(SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        assessmentRequest: { ...payload },
      };
    }
    case PUT_ASSESSMENT_REQUEST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_REQUEST_QUESTIONS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_REQUEST_QUESTIONS_LIST_SUCCESS: {
      const fields = [];
      payload?.length > 0 &&
        payload?.forEach((question) => {
          const fieldType = question.AssessmentRequestQuestionResponseTypeId === MULTI_SELECT_RESPONSE_ID ? multiSelectFields[0] : singleSelectFields[0];
          fields.push({
            ...fieldType,
            questionId: question.AssessmentRequestQuestionId,
            label: question?.IsRequired ? `${question.QuestionText} *` : question.QuestionText,
            name: `ResponseText${question.AssessmentRequestQuestionId}`,
            required: question?.IsRequired,
            options: getSelectOptionsList(question.AssessmentRequestQuestionResponseOptionList, 'ResponseOptionText', 'ResponseOptionText'),
            validate: question?.IsRequired ? [required] : [],
          });
        });

      return {
        ...state,
        loading: false,
        questionsList: payload,
        questionsDisplayFields: fields,
      };
    }

    case ASSESSMENT_REQUEST_QUESTIONS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_LOADING: {
      return {
        ...state,
        assessmentRequestVendorSearchListLoading: true,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_SUCCESS: {
      return {
        ...state,
        assessmentRequestVendorSearchListLoading: false,
        assessmentRequestVendorSearchList: payload?.Data?.Rows,
        assessmentRequestVendorSearchListCount: payload?.Data?.Rows?.length,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_FAILURE: {
      return {
        ...state,
        assessmentRequestVendorSearchListLoading: false,
        assessmentRequestVendorSearchList: payload,
        assessmentRequestVendorSearchListCount: 0,
      };
    }

    case SET_SELECTED_VENDOR: {
      return {
        ...state,
        selectedVendor: payload,
      };
    }

    case SET_SELECTED_VENDOR_TOGGLE: {
      return {
        ...state,
        selectedVendorToggle: payload,
      };
    }

    case SET_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST: {
      return {
        ...state,
        assessmentRequestVendorSearchList: payload,
      };
    }

    case SET_ASSESSMENT_REQUEST_VENDOR_OVERVIEW_SEARCH_LIST_COUNT: {
      return {
        ...state,
        assessmentRequestVendorSearchListCount: payload,
      };
    }

    case SET_ASSESSMENT_REQUEST_MANUAL_ADD_VENDOR_BOX: {
      return {
        ...state,
        showManualAddVendorBox: payload,
      };
    }

    case SET_RAW_CUSTOMER_VENDOR_PRODUCT_LIST: {
      return {
        ...state,
        rawCustomerVendorProductList: payload,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        rawCustomerVendorProductList: payload?.Data?.Rows?.map((item) => ({
          ...item,
          label: item['ProductName'],
          value: item['ProductVendorId'],
        })),
        customerVendorProductList:
          payload?.Data?.Rows?.length > 0 ? getSelectOptionsList(payload?.Data?.Rows, 'ProductName', 'ProductVendorId') : [NO_VENDOR_PRODUCTS_FOUND_OPTION],
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        rawCustomerVendorProductList: [],
        customerVendorProductList: [NO_VENDOR_PRODUCTS_FOUND_OPTION],
      };
    }

    case SET_ASSESSMENT_REQUEST_CUSTOMER_VENDOR_PRODUCT_LIST: {
      return {
        ...state,
        customerVendorProductList: payload,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_LOADING: {
      return {
        ...state,
        loading: true,
        searcAssessmentLoading: true,
        assessmentRequest: {},
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_SUCCESS: {
      let ToggleKeys = [
        'AssessmentRequestCategoryId',
        'AssessmentRequestTypeId',
        'ScopingInformationId',
        'SecurityControlInformationId',
        'IsAssessmentValidated',
      ];
      let identifiersKeys = ['BusinessDomain', 'BusinessCapabilityGroup'];
      const tempAssessmentRequest = {};
      const fields = [];
      const responseText = {};
      Object.entries(payload).forEach((item) => {
        const [key, value] = item;
        if (ToggleKeys.includes(key) && !isNil(value)) {
          tempAssessmentRequest[`${key}`] = value.toString();
        } else if (identifiersKeys.includes(key) && !isNil(value)) {
          tempAssessmentRequest[`${key}`] = value.split(',');
        } else {
          tempAssessmentRequest[`${key}`] = value;
        }
      });
      const multiSelectRequired = (v) => (!isEmpty(v) ? undefined : '* Required');
      payload?.AssessmentRequestQuestionList?.length > 0 &&
        payload?.AssessmentRequestQuestionList?.forEach((question) => {
          const fieldType = question.AssessmentRequestQuestionResponseTypeId === MULTI_SELECT_RESPONSE_ID ? multiSelectFields[0] : singleSelectFields[0];
          fields.push({
            ...fieldType,
            questionId: question.AssessmentRequestQuestionId,
            label: question?.IsRequired ? `${question.QuestionText} *` : question.QuestionText,
            name: `ResponseText${question.AssessmentRequestQuestionId}`,
            required: question?.IsRequired,
            options: getSelectOptionsList(question.AssessmentRequestQuestionResponseOptionList, 'ResponseOptionText', 'ResponseOptionText'),
            validate: question?.IsRequired ? [multiSelectRequired] : [],
            optionSelected: question?.AssessmentRequestQuestionResponseList,
            DisplayOrder: question.DisplayOrder,
          });
          responseText[`ResponseText${question.AssessmentRequestQuestionId}`] = question?.AssessmentRequestQuestionResponseList[0]?.ResponseText?.split(',');
        });

      let tempProduct = {};
      tempProduct = {
        label: tempAssessmentRequest?.ProductName,
        value: 0,
      };

      return {
        ...state,
        loading: false,
        searcAssessmentLoading: false,
        assessmentRequest: {
          ...tempAssessmentRequest,
          ...responseText,
          AssessmentRequestUploadedFileList: payload?.AssessmentRequestUploadedFile,
          IsProductMedicalDevice: payload?.IsProductMedicalDevice?.toString(),
          Product: tempProduct,
          VendorContactCountryCode: isNil(payload?.VendorContactCountryCode) ? DEAFULT_US_PHONE_CODE : payload?.VendorContactCountryCode,
        },
        questionsList: payload,
        questionsDisplayFields: fields.sort((a, b) => a.DisplayOrder - b.DisplayOrder),
      };
    }

    case SET_ASSESSMENT_REQUEST: {
      return {
        ...state,
        assessmentRequest: payload,
      };
    }

    case SEARCH_ASSESSMENT_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
        searcAssessmentLoading: false,
      };
    }

    case SEARCH_COMMENTS_SUCCESS: {
      return {
        ...state,
        comment: {
          showComments: true,
          list: payload,
        }
      };
    }

    case SEARCH_COMMENTS_LOADING:
    case CLEAR_COMMENTS: {
      return {
        ...state,
        comment: {
          showComments: false,
          list: [],
        }
      };
    }

    case SEARCH_COMMENTS_FAILURE: {
      return {
        ...state,
        comment: {
          showComments: true,
          list: [],
        }
      };
    }

    case SEARCH_DEFAULT_QT_REQUEST: {
      return {
        ...state,
        questionnaireTemplateLoading: true,
      };
    }

    case SEARCH_DEFAULT_QT_SUCCESS: {
      return {
        ...state,
        questionnaireTemplateLoading: false,
        ...payload,
      };
    }

    case SEARCH_DEFAULT_QT_FAILIURE: {
      return {
        ...state,
        questionnaireTemplateLoading: false,
      }
    }

    case SET_QUESTIONNAIRE_TEMPLATES: {
      return {
        ...state,
        questionnaireTemplateLoading: false,
        questionnaireTemplateList: [],
        pageNo: 0,
      }
    }

    default:
      return state;
  }
};
