import {
  SEARCH_PRODUCT_LIST_LOADING,
  SEARCH_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_LIST_FAILURE,
  SET_PRODUCT_LIST
} from '../actions/clientProduct';

const initialState = {
  loading: false,
  clientProductDetails: [],
};

export default function clientProduct(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        clientProductDetails: payload?.Data?.Rows,
      };
    }

    case SEARCH_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        clientProductDetails: []
      };
    }

    case SET_PRODUCT_LIST: {
      return {
        ...state,
        clientProductDetails: payload
      }
    }

    default:
      return state;
  }
}
