import { toast } from 'react-toastify';
import {
  GET_CLIENT_ACTIVE_OUTREACH_FAILURE,
  GET_CLIENT_ACTIVE_OUTREACH_LOADING,
  GET_CLIENT_ACTIVE_OUTREACH_SUCCESS,
  GET_CLIENT_OUTREACH_FAILURE,
  GET_CLIENT_OUTREACH_LOADING,
  GET_CLIENT_OUTREACH_SUCCESS,
  GET_OUTREACH_SUCCESS,
} from '../actions/clientProfileActiveOutreach';
import { TICKET_SAVED_SUCCESSFULLY_MESSAGE } from '../constants/constants';

const initialState = {
  loading: false,
  activeOutreaches: {},
  showJoinOutreach: false,
  outreachType: null,
};

export const activeOutreach = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENT_ACTIVE_OUTREACH_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CLIENT_ACTIVE_OUTREACH_SUCCESS: {
      return {
        ...state,
        loading: false,
        activeOutreaches: {
          ...state.activeOutreaches,
          totalActiveOutreaches: payload,
        },
        showJoinOutreach: false,
      };
    }
    case GET_OUTREACH_SUCCESS: {
      return {
        ...state,
        loading: false,
        showJoinOutreach: true,
      };
    }

    case GET_CLIENT_ACTIVE_OUTREACH_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_CLIENT_OUTREACH_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CLIENT_OUTREACH_SUCCESS: {
      toast.success(TICKET_SAVED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        outreachType: payload,
      };
    }

    case GET_CLIENT_OUTREACH_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
