import { toast } from 'react-toastify';
import {
  SEARCH_VENDOR_LIST_LOADING,
  SEARCH_VENDOR_LIST_FAILURE,
  SEARCH_VENDOR_LIST_SUCCESS,
  SEARCH_VENDOR_ONBOARDING_TEMPLATE_LOADING,
  SEARCH_VENDOR_ONBOARDING_TEMPLATE_FAILURE,
  SEARCH_VENDOR_ONBOARDING_TEMPLATE_SUCCESS,
  POST_XLSX_VENDOR_CONTACT_UPLOAD_LOADING,
  POST_XLSX_VENDOR_CONTACT_UPLOAD_FAILURE,
  POST_XLSX_VENDOR_CONTACT_UPLOAD_SUCCESS,
  GET_VENDOR_PRODUCT_LIST_LOADING,
  GET_VENDOR_PRODUCT_LIST_FAILURE,
  GET_VENDOR_PRODUCT_LIST_SUCCESS,
  SEARCH_VENDOR_PRODUCT_CONTACT_LIST_LOADING,
  SEARCH_VENDOR_PRODUCT_CONTACT_LIST_FAILURE,
  SEARCH_VENDOR_PRODUCT_CONTACT_LIST_SUCCESS,
  GET_VENDOR_METRICS_LOADING,
  GET_VENDOR_METRICS_SUCCESS,
  GET_VENDOR_METRICS_FAILURE,
} from '../actions/vendors';
import {
  TEMPLATE_DOWNLOAD_SUCCESS_MESSAGE,
  VENDOR_CONTACTS_UPLOAD_SUCCESS_MESSAGE,
  VENDOR_CONTACTS_UPLOAD_FAILED_MESSAGE,
} from '../components/Vendors/constants';

const initialState = {
  loading: false,
  vendorList: [],
  vendorProductList: [],
  vendorProductContactList: [],
  vendorMetrics: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_VENDOR_LIST_LOADING:
    case SEARCH_VENDOR_ONBOARDING_TEMPLATE_LOADING:
    case POST_XLSX_VENDOR_CONTACT_UPLOAD_LOADING:
    case GET_VENDOR_PRODUCT_LIST_LOADING:
    case SEARCH_VENDOR_PRODUCT_CONTACT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_VENDOR_LIST_FAILURE:
    case SEARCH_VENDOR_ONBOARDING_TEMPLATE_FAILURE:
    case GET_VENDOR_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_VENDOR_ONBOARDING_TEMPLATE_SUCCESS: {
      toast.success(TEMPLATE_DOWNLOAD_SUCCESS_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case POST_XLSX_VENDOR_CONTACT_UPLOAD_SUCCESS: {
      toast.success(VENDOR_CONTACTS_UPLOAD_SUCCESS_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case POST_XLSX_VENDOR_CONTACT_UPLOAD_FAILURE: {
      toast.error(VENDOR_CONTACTS_UPLOAD_FAILED_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_VENDOR_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorList: payload?.Data?.Rows,
      };
    }
    case GET_VENDOR_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorProductList: payload?.Data,
      };
    }

    case SEARCH_VENDOR_PRODUCT_CONTACT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorProductContactList: payload?.Data?.Rows.map((item) => {
          return {
            ...item,
            id: item.VendorIncidentResponseContactId,
          };
        }),
      };
    }

    case SEARCH_VENDOR_PRODUCT_CONTACT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorProductContactList: [],
      };
    }
    case GET_VENDOR_METRICS_LOADING: {
      return {
        ...state,
        loading: true,
        vendorMetrics: {},
      };
    }
    case GET_VENDOR_METRICS_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorMetrics: payload?.Data,
      };
    }
    case GET_VENDOR_METRICS_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorMetrics: {},
      };
    }

    default:
      return state;
  }
}
