import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { getVendorInformationSearchBody } from '../utils/vendorInformationUtil';

export const SEARCH_VENDOR_INFORMATION_LOADING = '@@vendorInformation/SEARCH_VENDOR_INFORMATION_LOADING';
export const SEARCH_VENDOR_INFORMATION_SUCCESS = '@@vendorInformation/SEARCH_VENDOR_INFORMATION_SUCCESS';
export const SEARCH_VENDOR_INFORMATION_FAILURE = '@@vendorInformation/SEARCH_VENDOR_INFORMATION_FAILURE';

export const getVendorInformation = (vendorOrgId) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_INFORMATION_LOADING });
  try {
    const body = getVendorInformationSearchBody(vendorOrgId);
    const response = await axios.post(`/vendor/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: SEARCH_VENDOR_INFORMATION_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_INFORMATION_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_INFORMATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
