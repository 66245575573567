import { toast } from 'react-toastify';
import { 
      SET_USER_DATA,
      SEARCH_EVENT_NOTIFICATION_LIST_COUNT_LOADING,
      SEARCH_EVENT_NOTIFICATION_LIST_COUNT_FAILURE,
      SEARCH_EVENT_NOTIFICATION_LIST_COUNT_SUCCESS,
      SEARCH_EVENT_NOTIFICATION_LIST_LOADING,
      SEARCH_EVENT_NOTIFICATION_LIST_FAILURE,
      SEARCH_EVENT_NOTIFICATION_LIST_SUCCESS,
      MARK_AS_READ_EVENT_NOTIFICATION_LOADING,
      MARK_AS_READ_EVENT_NOTIFICATION_FAILURE,
      MARK_AS_READ_EVENT_NOTIFICATION_SUCCESS,
      DISMISS_EVENT_NOTIFICATION_LOADING,
      DISMISS_EVENT_NOTIFICATION_FAILURE,
      DISMISS_EVENT_NOTIFICATION_SUCCESS,
    } from '../actions/userProfile';
import userImage from '../assets/images/user-image.png';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { intlFormatDistance } from 'date-fns';
import { READ_NOTIFICATION_STATUS_ID } from 'constants/constants';
import { NOTIFICATION_STATUS_UPDATED_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { convertUTCDateToLocalDate } from 'utils/dateAndTimeUtils';

const initialState = {
  value: {
    image: userImage,
    name: 'Mark Quintana',
  },
  loading: false,
  eventNotificationListCount: 0,
  eventNotificationList: [],
  unreadEventNotificationList: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_DATA: {
      return {
        ...state,
      };
    }

    case SEARCH_EVENT_NOTIFICATION_LIST_COUNT_LOADING: 
    case SEARCH_EVENT_NOTIFICATION_LIST_LOADING: 
    case MARK_AS_READ_EVENT_NOTIFICATION_LOADING: 
    case DISMISS_EVENT_NOTIFICATION_LOADING: {
      return {
          ...state,
          loading: true
      }
    }

    case SEARCH_EVENT_NOTIFICATION_LIST_COUNT_FAILURE: {
        return {
            ...state,
            loading: false,
            eventNotificationListCount: 0
        }
    }

    case SEARCH_EVENT_NOTIFICATION_LIST_COUNT_SUCCESS: {
        return {
            ...state,
            loading: false,
            eventNotificationListCount: payload[0]?.CountOfUnreadNotifications || 0
        }
    }

    case SEARCH_EVENT_NOTIFICATION_LIST_FAILURE: {
        return {
            ...state,
            loading: false,
            eventNotificationList: [],
            unreadEventNotificationList: [],
            timeSpans: []
        }
    }

    case SEARCH_EVENT_NOTIFICATION_LIST_SUCCESS: {

      const updatedPayload = payload?.map((item) => ({
        ...item,
        ReadableDate: item.InAppStatusId === READ_NOTIFICATION_STATUS_ID ? intlFormatDistance(convertUTCDateToLocalDate(new Date(item?.EventNotificationDate)), new Date()) : null,
        EventNotificationDate: convertUTCDateToLocalDate(item?.EventNotificationDate)
      }));
      
      const timeSpans = [...new Set(updatedPayload
        ?.filter(item => item.InAppStatusId === READ_NOTIFICATION_STATUS_ID)
        ?.map(item => item.ReadableDate)
      )];

      return {
          ...state,
          loading: false,
          eventNotificationList: updatedPayload,
          unreadEventNotificationList: updatedPayload?.filter((item) => item.InAppStatusId !== READ_NOTIFICATION_STATUS_ID),
          timeSpans: timeSpans
      }
    }

    case MARK_AS_READ_EVENT_NOTIFICATION_FAILURE: {
      toast.error(ERROR_MESSAGE)
      return {
          ...state,
          loading: false
      }
    }

    case MARK_AS_READ_EVENT_NOTIFICATION_SUCCESS: {
        toast.success(NOTIFICATION_STATUS_UPDATED_SUCCESSFULLY_MESSAGE)
        return {
            ...state,
            loading: false,
        }
    }

    case DISMISS_EVENT_NOTIFICATION_FAILURE: {
          toast.error(ERROR_MESSAGE)
          return {
              ...state,
              loading: false
          }
    }

    case DISMISS_EVENT_NOTIFICATION_SUCCESS: {
        toast.success(NOTIFICATION_STATUS_UPDATED_SUCCESSFULLY_MESSAGE)
        return {
            ...state,
            loading: false,
        }
    }

    default:
      return state;
  }
}
