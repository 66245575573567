import { isEmpty } from 'lodash';
import { convertToLocalDateTime } from 'utils/dateAndTimeUtils';

export const RISK_RECON_SCORE_CONFIG = [
  {
    key: 'ApplicationSecurity',
    source: 'RiskRecon',
    riskType: 'Application Security',
  },
  {
    key: 'WebEncryption',
    source: 'RiskRecon',
    riskType: 'Web Encryption',
  },
  {
    key: 'SoftwarePatching',
    source: 'RiskRecon',
    riskType: 'Software Patching',
  },
  {
    key: 'SystemHosting',
    source: 'RiskRecon',
    riskType: 'System Hosting',
  },
  {
    key: 'SystemReputation',
    source: 'RiskRecon',
    riskType: 'System Reputation',
  },
  {
    key: 'EmailSecurity',
    source: 'RiskRecon',
    riskType: 'Email Security',
  },
  {
    key: 'DSNSecurity',
    source: 'RiskRecon',
    riskType: 'DSN Security',
  },
  {
    key: 'BreachEvent',
    source: 'RiskRecon',
    riskType: 'Breach Event',
  },
  {
    key: 'NetworkFiltering',
    source: 'RiskRecon',
    riskType: 'Network Filtering',
  },
];

export const BITSIGHT_SCORE_CONFIG = [
  {
    key: 'BitsightIPAddress',
    source: 'Bitsight',
    riskType: 'IP Address',
  },
  {
    key: 'BitsightUnsolicitedCommunication_Value',
    source: 'Bitsight',
    riskType: 'Unsolicited Communication',
  },
  {
    key: 'BitsightPotentiallyExploited_Value',
    source: 'Bitsight',
    riskType: 'Potentially Exploited',
  },
  {
    key: 'BitsightBotnetInfections_Value',
    source: 'Bitsight',
    riskType: 'Botnet Infections',
  },
  {
    key: 'BitsigntMalwareServers_Value',
    source: 'Bitsight',
    riskType: 'Malware Servers',
  },
  {
    key: 'BitsightSpamPropogation_Value',
    source: 'Bitsight',
    riskType: 'Spam Propogation',
  },
  {
    key: 'SPFDomains_Value',
    source: 'Bitsight',
    riskType: 'SPF Domains',
  },
  {
    key: 'DKIMRecords_Value',
    source: 'Bitsight',
    riskType: 'DKIM Records',
  },
  {
    key: 'TLSSSLConfigurations_Value',
    source: 'Bitsight',
    riskType: 'TLS/SSL Configurations',
  },
  {
    key: 'DNSSECRecords_Value',
    source: 'Bitsight',
    riskType: 'DNSSEC Records',
  },
  {
    key: 'WebApplicationHeaders_Value',
    source: 'Bitsight',
    riskType: 'Web Application Headers',
  },
  {
    key: 'InsecureSystems_Value',
    source: 'Bitsight',
    riskType: 'Insecure Systems',
  },
  {
    key: 'ButsightOpenPorts_Value',
    source: 'Bitsight',
    riskType: 'Open Ports',
  },
  {
    key: 'BitsightFileSharing_Value',
    source: 'Bitsight',
    riskType: 'File Sharing',
  },
  {
    key: 'TLLSSLCertificates_Value',
    source: 'Bitsight',
    riskType: 'TLS/SSL Certificates',
  },
  {
    key: 'PatchingCadence_Value',
    source: 'Bitsight',
    riskType: 'Patching Cadence',
  },
  {
    key: 'ServerSoftware_Value',
    source: 'Bitsight',
    riskType: 'Server Software',
  },
  {
    key: 'DesktopSoftware_Value',
    source: 'Bitsight',
    riskType: 'Desktop Software',
  },
  {
    key: 'MobileSoftware_Value',
    source: 'Bitsight',
    riskType: 'Mobile Software',
  },
  {
    key: 'MobileApplicationSecurity_Value',
    source: 'Bitsight',
    riskType: 'Mobile Application Security',
  },
  {
    key: 'BitsightBreaches_Value',
    source: 'Bitsight',
    riskType: 'Breaches',
  },
];

export const SECURITY_SCORECARD_SCORE_CONFIG = [
  {
    key: 'SSApplicationSecurity',
    source: 'Security Scorecard',
    riskType: 'Application Security',
  },
  {
    key: 'SSCubitScore',
    source: 'Security Scorecard',
    riskType: 'Cubit Score',
  },
  {
    key: 'SSDNSHealth',
    source: 'Security Scorecard',
    riskType: 'DNS Health',
  },
  {
    key: 'SSEndpointSecurity',
    source: 'Security Scorecard',
    riskType: 'Endpoint Security',
  },
  {
    key: 'SSHackerChatter',
    source: 'Security Scorecard',
    riskType: 'Hacker Chatter',
  },
  {
    key: 'SSIPReputation',
    source: 'Security Scorecard',
    riskType: 'IP Reputation',
  },
  {
    key: 'SSInformationLeak',
    source: 'Security Scorecard',
    riskType: 'Information Leak',
  },
  {
    key: 'SSNetworkSecurity',
    source: 'Security Scorecard',
    riskType: 'Network Security',
  },
  {
    key: 'SSPatchingCadence',
    source: 'Security Scorecard',
    riskType: 'Patching Cadence',
  },
  {
    key: 'SSSocialEngineering',
    source: 'Security Scorecard',
    riskType: 'Social Engineering',
  },
  {
    key: 'SecurityScoreCardVendorIndustry',
    source: 'Security Scorecard',
    riskType: 'Vendor Industry',
  },
];

export const constructCyberRiskScore = (data) => {
  const list = [];

  if (!isEmpty(data)) {
    let dateTime = convertToLocalDateTime(new Date(), 'MM/dd/yyyy-hh:mm a z');
    let configList = [];

    if (data?.RiskReconRating) {
      configList = RISK_RECON_SCORE_CONFIG;
      if (data?.RiskReconPreAssessmentDataLoadDate) {
        dateTime = convertToLocalDateTime(new Date(data?.RiskReconPreAssessmentDataLoadDate), 'MM/dd/yyyy-hh:mm a z');
      }
    } else if (data?.BitsightScore) {
      configList = BITSIGHT_SCORE_CONFIG;
      if (data?.BitSightPreAssessmentDataLoadDate) {
        dateTime = convertToLocalDateTime(new Date(data?.BitSightPreAssessmentDataLoadDate), 'MM/dd/yyyy-hh:mm a z');
      }
    } else if (data?.SecurityScoreCardOverallScore) {
      configList = SECURITY_SCORECARD_SCORE_CONFIG;
      if (data?.SecurityScorecardDateLoaded) {
        dateTime = convertToLocalDateTime(new Date(data?.SecurityScorecardDateLoaded), 'MM/dd/yyyy-hh:mm a z');
      }
    }

    if (configList.length > 0) {
      configList.forEach((it, index) => {
        const score = data[it.key];
        if (score) {
          list.push({
            Id: index + 1,
            Score: score,
            Source: it.source,
            RiskType: it.riskType,
            DateTime: dateTime,
          });
        }
      });
    }
  }

  return list;
};
