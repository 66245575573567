export const REMEDIATION_GUIDANCE_REASON_FREE_TEXT = 5;
export const ACCEPT_ALTERNATE_GUIDANCE_QUESTION =
  'The alternative guidance(s) provided shall be overwritten by CORL remediation guidance. Do you wish to proceed?';

export const CORL_REMEDIATION_GUIDANCE_ACCEPTED_SUCCESSFULLY = 'CORL Remediation Guidance Accepted Successfully';
export const ALTERNATE_REMEDIATION_GUIDANCE_SUBMITTED_SUCCESSFULLY = 'Alternate Remediation Guidance Submitted Successfully';

export const AFFORDANCE_FOR_ALTERNATE_GUIDANCE_NO = 0;
export const REMEDIATION_GUIDANCE_SUBMIT_VALUE = 1;
export const REMEDIATION_GUIDANCE_SUBMIT_TYPE_VALUE = 1;

export const REMEDIATION_PREFERENCE_CONFIG = ['high', 'medium'];
export const NON_REMEDIATION_PREFERENCE_CONFIG = ['low'];

export const ALTERNATE_REMEDIATION = 0;
export const CORL_REMEDIATION = 1;
