import React from 'react';
import { isEmpty } from 'lodash';
import { Form, reduxForm, initialize, getFormValues, submit } from 'redux-form';
import { INCIDENT_NOTIFICATION_FORM } from '../../../constants/formNames';
import DecoratedField from '../../../components/DecoratedField';
import FormField from '../../../components/FormField/FormField';
import { incidentNotificationFields } from '../../../components/ClientProfile/fields';
import { connect, useDispatch } from 'react-redux';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useEffect } from 'react';
import { modalStyle, buttonStyle } from './incidentNotificationPopupStyle';
import { faFloppyDisk, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postIncidentNotification } from '../../../actions/vendorIncidentNotification';

const mapSectionFields = (props) => (field, i) => {
  return <DecoratedField key={i + field.name} {...field} inputcomponent={FormField} options={props[field.name]} />;
};

const IncidentNotificationPopup = (props) => {
  const { formData, handleSubmit, openPopup, setClosePopup } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(formData)) {
      dispatch(initialize(INCIDENT_NOTIFICATION_FORM, {}));
    }
  }, []);

  const submitForm = () => {
    dispatch(submit(INCIDENT_NOTIFICATION_FORM));
  };

  const onSubmit = (formData, dispatch) => {
    dispatch(postIncidentNotification(formData));
    dispatch(initialize(INCIDENT_NOTIFICATION_FORM, {}));
    setClosePopup(false);
  };

  const onClose = () => {
    setClosePopup(false);
  };

  return (
    <div>
      <Modal open={openPopup}>
        <Box sx={modalStyle}>
          <Typography variant="h4">Incident Notification</Typography>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            {incidentNotificationFields.map(
              mapSectionFields({
                ...props,
              })
            )}
          </Form>
          <Box sx={buttonStyle}>
            <Button variant="outlined" color="info" onClick={onClose} startIcon={<FontAwesomeIcon icon={faClose} />}>
              Cancel
            </Button>
            <Button variant="contained" color="info" onClick={submitForm} startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: INCIDENT_NOTIFICATION_FORM,
  destroyOnUnmount: false,
})(
  connect((state) => ({
    formData: getFormValues(INCIDENT_NOTIFICATION_FORM)(state),
  }))(IncidentNotificationPopup)
);
