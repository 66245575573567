import { SEARCH_VENDOR_INFORMATION_LOADING, SEARCH_VENDOR_INFORMATION_SUCCESS, SEARCH_VENDOR_INFORMATION_FAILURE } from '../actions/vendorInformation';

const initialState = {
  loading: false,
  vendorInformation: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_VENDOR_INFORMATION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_VENDOR_INFORMATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorInformation: payload?.Data?.Rows.length > 0 && payload?.Data?.Rows[0],
      };
    }

    case SEARCH_VENDOR_INFORMATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
