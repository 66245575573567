export const REMOVE_SELECTED_FILE_FROM_CREATE_SUPPORT_TICKET = 'This action will remove the selected file from create suport ticket form. Are you sure?';
export const ACTIVE_TICKETS = 'ActiveTickets';
export const SOLVED_TICKETS = 'SolvedTickets';
export const ALL_TICKETS = 'AllTickets';
export const QUICK_LINKS_ACTIVE_TICKETS = 'ACTIVE TICKETS';
export const QUICK_LINKS_SOLVED_TICKETS = 'SOLVED TICKETS';
export const QUICK_LINKS_ALL_TICKETS = 'ALL TICKETS';
export const HOLD_STATUS_LOWER_CASE = 'hold';
export const HOLD_STATUS_UPPER_CASE = 'ON-HOLD';

export const ACTIVE_TICKET_INDEX = 0;
export const SOLVED_TICKET_INDEX = 1;

export const SUPPORT_ARTICLE_FILTER_REGEX_EXPRESSION = /[`~!@#$%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi;
