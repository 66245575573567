import { toast } from 'react-toastify';
import { 
  SEARCH_CLIENT_USERS_LOADING,
    SEARCH_CLIENT_USERS_FAILURE,
    SEARCH_CLIENT_USERS_SUCCESS,
    ADD_CLIENT_USER_LOADING,
    ADD_CLIENT_USER_FAILURE,
    ADD_CLIENT_USER_SUCCESS,
    UPDATE_CLIENT_USER_LOADING,
    UPDATE_CLIENT_USER_FAILURE,
    UPDATE_CLIENT_USER_SUCCESS,
    REMOVE_CLIENT_USER_LOADING,
    REMOVE_CLIENT_USER_FAILURE,
    REMOVE_CLIENT_USER_SUCCESS,
    SET_SELECTED_USER,
    SET_SELECTED_INDEX,
    SET_CLIENT_USER_FORM_EDITABLE,
    POST_RESEND_INVITE_TO_USER_LOADING,
    POST_RESEND_INVITE_TO_USER_FAILURE,
    POST_RESEND_INVITE_TO_USER_SUCCESS,
    SET_USERS_TAB_VALUE,
    SET_OPEN_USERS_SIDESHEET 
} from '../actions/clientProfileUsers';
import {
  DELETED_SUCCESSFULLY_MESSAGE,
  ERROR_MESSAGE,
  RESEND_INVITE_SUCCESSFULLY,
  SAVE_SUCCESSFULLY_MESSAGE,
  UPDATED_SUCCESSFULLY_MESSAGE,
} from '../components/Common/AlertMessages';
import { ADMIN_ROLE, USERS_TAB_OPTIONS } from 'pages/ClientProfile/Users/constants';

const initialState = {
  loading: false,
  userList: [],
  selectedUser: {},
  selectedIndex: null,
  isUserFormEditable: false,
  userToBeSelected: null,
  usersTabValue: USERS_TAB_OPTIONS.USERS,
  openUserSidesheet: false
};

export const clientProfileUsers = (state = initialState, action) => {
  const { type, payload, isEditable } = action;

  switch (type) {
    case ADD_CLIENT_USER_LOADING:
    case UPDATE_CLIENT_USER_LOADING:
    case REMOVE_CLIENT_USER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_CLIENT_USERS_LOADING: {
      return {
        ...state,
        loading: true,
        selectedUser: {},
        selectedIndex: null,
      };
    }
    case SEARCH_CLIENT_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        userList: [],
      };
    }
    case ADD_CLIENT_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_CLIENT_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case REMOVE_CLIENT_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_CLIENT_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userList: payload?.Data?.Rows.map((item) => {
          return {
            ...item,
            IsAdmin: item.UserRoleName?.toLowerCase().endsWith(ADMIN_ROLE)
          };
        }),
      };
    }
    case ADD_CLIENT_USER_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        isUserFormEditable: false,
        userToBeSelected: payload?.Data?.UserId,
      };
    }
    case UPDATE_CLIENT_USER_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        userToBeSelected: null,
      };
    }
    case REMOVE_CLIENT_USER_SUCCESS: {
      toast.success(DELETED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: payload || {},
      };
    }
    case SET_SELECTED_INDEX: {
      return {
        ...state,
        selectedIndex: payload,
      };
    }
    case SET_CLIENT_USER_FORM_EDITABLE: {
      return {
        ...state,
        isUserFormEditable: isEditable,
      };
    }
    case POST_RESEND_INVITE_TO_USER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_RESEND_INVITE_TO_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case POST_RESEND_INVITE_TO_USER_SUCCESS: {
      toast.success(RESEND_INVITE_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case SET_USERS_TAB_VALUE: {
      return {
        ...state,
        usersTabValue: payload,
      }
    }
    case SET_OPEN_USERS_SIDESHEET: {
      return {
        ...state,
        openUserSidesheet: payload
      }
    }
    default:
      return state;
  }
};
