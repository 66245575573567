export const VENDORS_AND_PRODUCTS_TAB_OPTIONS = {
  VENDORS: 'VENDORS',
  PRODUCTS: 'PRODUCTS',
};

export const VENDORS_AND_PRODUCTS_TAB_LIST = [
  {
    label: VENDORS_AND_PRODUCTS_TAB_OPTIONS.VENDORS,
    value: VENDORS_AND_PRODUCTS_TAB_OPTIONS.VENDORS,
  },
  {
    label: VENDORS_AND_PRODUCTS_TAB_OPTIONS.PRODUCTS,
    value: VENDORS_AND_PRODUCTS_TAB_OPTIONS.PRODUCTS,
  },
];
export const VENDOR_ACTIVE = 'Active';
export const VENDOR_INACTIVE = 'Inactive';

export const RELATIONSHIP_STATUS_RADIO_LIST = [
  {
    label: 'Active',
    value: VENDOR_ACTIVE,
  },
  {
    label: 'Inactive',
    value: VENDOR_INACTIVE,
  },
];

export const TPIR_STATUS_RADIO_LIST = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 0,
  },
];

export const INVALID_RELATIONSHIP_DELETE__CONFIRMATION = 'Is this relationship Invalid? Click here to delete';
export const CONTINUE = 'Continue';
export const CANCEL_ADD_VENDOR_MESSAGE = 'This action will cancel the progress of Addition of Vendor. Are you sure?';
export const DOWNLOAD_VENDOR_TEMPLATE_MESSAGE = 'Download a template which can be used for bulk import vendors.';
export const NO_PRODUCTS_ASSOCIATED = 'No Products Associated for this Vendor';
export const NO_VENDORS_AVAILABLE = 'No Vendors Available';

export const VENDOR_INVENTORY_DEFAULT_FILENAME = "VendorInventory"

export const PRODUCTS_LIST = 'Products List';
export const  PRODUCTS_WITHIN_VENDOR_LIST = 'Products Within Vendor List';