import {
  SEARCH_CLIENT_DASHBOARD_LOADING,
  SEARCH_CLIENT_DASHBOARD_SUCCESS,
  SEARCH_CLIENT_DASHBOARD_FAILURE,
  GET_POWER_BI_DASHBOARD_URL_LOADING,
  GET_POWER_BI_DASHBOARD_URL_SUCCESS,
  GET_POWER_BI_DASHBOARD_URL_FAILURE,
  GET_MANAGEMENT_DASHBOARD_URL_LOADING,
  GET_MANAGEMENT_DASHBOARD_URL_SUCCESS,
  GET_MANAGEMENT_DASHBOARD_URL_FAILURE,
} from '../actions/dashboard';

const initialState = {
  loading: false,
  dashboard: null,
  powerBIDashboard: {},
  managementDashboard: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_CLIENT_DASHBOARD_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_CLIENT_DASHBOARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        dashboard: payload?.Data,
      };
    }

    case SEARCH_CLIENT_DASHBOARD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_POWER_BI_DASHBOARD_URL_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_POWER_BI_DASHBOARD_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        powerBIDashboard: payload?.Data,
      };
    }

    case GET_POWER_BI_DASHBOARD_URL_FAILURE: {
      return {
        ...state,
        loading: false,
        powerBIDashboard: {},
      };
    }

    case GET_MANAGEMENT_DASHBOARD_URL_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_MANAGEMENT_DASHBOARD_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        managementDashboard: payload?.Data?.Rows ? payload?.Data?.Rows[0] : {},
      };
    }

    case GET_MANAGEMENT_DASHBOARD_URL_FAILURE: {
      return {
        ...state,
        loading: false,
        managementDashboard: {},
      };
    }

    default:
      return state;
  }
}
