import { INCIDENT_STATUS_QA_APPROVED } from '../constants/constants';
export const getClientProfileDocumentedIncidentsBody = () => {
  return {
    Intent: '',
    Fields: [
      'IncidentName',
      'Description',
      'DateFirstReported',
      'IncidentStatusForClient',
      'UpdateTimeStamp',
      'OutreachId',
      'OutreachName',
      'OutreachQuestionnaireTemplateId',
      'QuestionnaireTemplateId',
      'QuestionnaireTemplateName',
      'IsTPIRSubscribed',
      'IsCORLCleared',
    ],
    Criterion: [
      {
        Field: 'IncidentStatusId',
        Operator: '=',
        Value: INCIDENT_STATUS_QA_APPROVED,
      },
    ],
    Sort: [
      {
        Field: 'DateFirstReported',
        Order: 'desc',
      },
    ],
    Page: 0,
    PageSize: 0,
  };
};
