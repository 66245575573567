import {
  GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAILURE,
  GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST,
  GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS,
} from '../actions/viewQuestionnaire';

const initialState = {
  loading: false,
  questionnaireDetails: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_QUESTIONNAIRE_TEMPLATE_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        questionnaireDetails: payload?.Data,
      };
    }

    case GET_QUESTIONNAIRE_TEMPLATE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
