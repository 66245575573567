import { STATUS_ACTIVE } from 'pages/ApiKeyManager/constants';

export const getApiKeyManagerDetailsSearchBody = () => {
  return {
    intent: '',
    fields: ['UserId', 'ApiKeyValue', 'ApiKeyName', 'Status', 'CreateDate', 'ExpiryDate'],
    Criterion: [],
    sort: [
      {
        field: 'UserId',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const addApiKeyBody = (formData) => {
  return {
    ApiKeyName: formData?.ApiKeyName,
    Status: STATUS_ACTIVE,
  };
};

export const saveApiKeyManagerDetailsBody = (formData) => {
  return {
    Status: formData?.Status,
    ApiKeyName: formData?.ApiKeyName,
  };
};
