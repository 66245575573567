import {
  ADD_COMMENTS_SUPPORT_TICKET_DETAILS_FAILURE,
  ADD_COMMENTS_SUPPORT_TICKET_DETAILS_LOADING,
  ADD_COMMENTS_SUPPORT_TICKET_DETAILS_SUCCESS,
  CREATE_SUPPORT_TICKET_FAILURE,
  CREATE_SUPPORT_TICKET_LOADING,
  CREATE_SUPPORT_TICKET_SUCCESS,
  CLOSE_SUPPORT_TICKET_DETAILS_FAILURE,
  CLOSE_SUPPORT_TICKET_DETAILS_LOADING,
  CLOSE_SUPPORT_TICKET_DETAILS_SUCCESS,
  SEARCH_SUPPORT_TICKET_DETAILS_FAILURE,
  SEARCH_SUPPORT_TICKET_DETAILS_LOADING,
  SEARCH_SUPPORT_TICKET_DETAILS_SUCCESS,
  RESET_SUPPORT_TICKET_DETAILS
} from 'actions/supportTicket';
import { COMMENTS_ADDED_SUCCESSFULLY, ERROR_MESSAGE, CREATE_SUPPORT_TICKET_SUCCESSFULLY, CLOSE_SUPPORT_TICKET_SUCCESSFULLY } from 'constants/errorMessage';
import { toast } from 'react-toastify';

const initialState = {
  loading: false,
  supportTicketDetails: {},
};
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_SUPPORT_TICKET_DETAILS_LOADING:
    case CREATE_SUPPORT_TICKET_LOADING:
    case ADD_COMMENTS_SUPPORT_TICKET_DETAILS_LOADING:
    case CLOSE_SUPPORT_TICKET_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_SUPPORT_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        supportTicketDetails: payload?.map((item) => ({
          ...item,
          ExternalTicketId: parseInt(item?.ExternalTicketId, 10) || 0
        })),
      };
    }    
    case SEARCH_SUPPORT_TICKET_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        supportTicketDetails: {},
      };
    }
    case ADD_COMMENTS_SUPPORT_TICKET_DETAILS_SUCCESS: {
      toast.success(COMMENTS_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_COMMENTS_SUPPORT_TICKET_DETAILS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case CLOSE_SUPPORT_TICKET_DETAILS_SUCCESS: {
      toast.success(CLOSE_SUPPORT_TICKET_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case CLOSE_SUPPORT_TICKET_DETAILS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case CREATE_SUPPORT_TICKET_SUCCESS: {
      toast.success(CREATE_SUPPORT_TICKET_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case CREATE_SUPPORT_TICKET_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case RESET_SUPPORT_TICKET_DETAILS: {
      return {
        ...state,
        loading: false,
        supportTicketDetails: payload,
      };
    }

    default:
      return state;
  }
}
