import * as React from 'react';
import { TextField, Autocomplete, Checkbox, MenuItem, ListItemText, Box, Chip, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import { FormHelperText } from '@mui/material';
import { useState } from 'react';
import { autoCompleteStyles } from './multiSelectAutoCompleteStyles';
import { isEmpty, isNil, orderBy } from 'lodash';
import { useEffect } from 'react';
import { ArrowDropDown } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  formControl: {
    // minWidth: 240,
    width: '100%',
    height: '100%',
  },
  placeholder: {
    color: '#999',
  },
  standardVariant: {
    height: '88px',
  },
  select: {
    maxHeight: '370px',
  },
}));

export const AutocompleteBox = (props) => {
  const classes = useStyles();
  const autoCompleteCustomStyles = {
    ...autoCompleteStyles(),
    ...(isNil(props?.customStyles) ? {} : props?.customStyles),
  }
  const { input } = props;
  const [inputValue, setInputValue] = useState('');

  const [listboxNode, setListboxNode] = useState('');
  const [position, setPosition] = useState(0);
  const [optionList, setOptionsList] = useState([]);

  useEffect(() => {
    setOptionsList(props.options, 'value');
  }, [props.options]);

  useEffect(() => {
    if (listboxNode !== '') {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);

  const filterOptions = (options, state) => {
    let filteredOptions = [];
    options?.forEach((option) => {
      const optionItem = optionList?.find((item) => item?.value === option);
      if (optionItem) {
        if (optionItem?.label?.toLowerCase()?.startsWith(state.inputValue.toLowerCase())) {
          filteredOptions.push(option);
        }
      }
    });
    return filteredOptions;
  };

  async function loadMoreOptions(x) {
    const result = await props.onScroll();
    if (result) {
      setPosition(x);
    }
  }

  return (
    <FormControl className={classes.formControl} disabled={props.disabled} error={props.error} style={props.style} variant={props.variant} size="small">
      <Autocomplete
        multiple={true}
        popupIcon={<ArrowDropDown />}
        autoComplete
        autoHighlight
        disableCloseOnSelect={true}
        value={props?.autocompleteselectedvalue ? props?.autocompleteselectedvalue : (input?.value || undefined)}
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          const item = optionList?.find((it) => it?.value === option);
          return item?.label || '';
        }}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={(event, newValue) => {
          props.onChange(newValue)
          props?.setautocompleteselectedvalue && props?.setautocompleteselectedvalue(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          props.setautocompleteinputvalue && props.setautocompleteinputvalue(newInputValue);
        }}
        id="combo-box"
        options={
          isEmpty(props?.value) && !isNil(optionList)
            ? optionList?.map((it) => it?.value)
            : !isNil(optionList) && [
              ...orderBy(
                optionList?.filter((it) => props?.value?.includes(it?.value)),
                ['label'],
                ['asc']
              ),
              ...optionList.filter((it) => !props?.value?.includes(it?.value)),
            ].map((it) => it?.value)
        }
        sx={autoCompleteCustomStyles}
        renderInput={(params) => <TextField {...params} label={props.label} />}
        renderOption={(params, option, { selected }) => (
          <MenuItem {...params}>
            <Checkbox checked={selected} />
            <Tooltip title={params.key} placement="top">
              <ListItemText sx={{ wordBreak: 'break-all', overflowWrap: 'break-word' }} primary={params.key} />
            </Tooltip>
          </MenuItem>
        )}
        renderTags={(tagValue) => {
          if (props.value.length === 1) {
            return <Chip label={optionList?.find((op) => op.value === props.value[0])?.label} />;
          }
          if (props.value.length > 1) {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                <Chip label={optionList?.find((op) => op.value === props.value[0])?.label} />
                {props.value.length > 1 && <Chip className="chipSelectCount" label={`+ ${props.value.length - 1}`} />}
              </Box>
            );
          }
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {tagValue?.map((value, index) => (
                <Chip key={`${value}-${index}`} label={optionList?.find((op) => op.value === value)?.label} />
              ))}
            </Box>
          );
        }}
        ListboxProps={{
          onScroll: (event) => {
            setListboxNode(event.currentTarget);
            const x = listboxNode.scrollTop + listboxNode.clientHeight;
            if (listboxNode.scrollHeight - x <= 1 && x > 0) {
              loadMoreOptions(x);
            }
          },
          style: {
            height: 240,
          },
        }}
        disabled={props.disabled}
        componentsProps={{
          paper: {
            sx: {
              width: isNil(props?.menuWidth) ?  300 : props?.menuWidth,
            },
          },
        }}
      />
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
};

export default AutocompleteBox;
