import {
  SEARCH_API_KEY_MANAGER_DETAILS_LOADING,
  SEARCH_API_KEY_MANAGER_DETAILS_FAILURE,
  SEARCH_API_KEY_MANAGER_DETAILS_SUCCESS,
  SET_SELECTED_API_KEY,
  SET_TARGET_UPDATE_STATE,
  POST_API_KEY_LOADING,
  POST_API_KEY_FAILURE,
  POST_API_KEY_SUCCESS,
  UPDATE_API_KEY_MANAGER_DETAILS_LOADING,
  UPDATE_API_KEY_MANAGER_DETAILS_FAILURE,
  UPDATE_API_KEY_MANAGER_DETAILS_SUCCESS,
} from 'actions/apiKeyManager';
import { ERROR_MESSAGE, SAVED_SUCCESSFULLY, UPDATED_SUCCESSFULLY } from 'constants/errorMessage';
import { format } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  loading: false,
  apiKeyManagerList: [],
  selectedApiKey: {},
  targetUpdateState: null,
};

export const apiKeyManager = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_API_KEY_MANAGER_DETAILS_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_API_KEY_MANAGER_DETAILS_FAILURE: {
      return {
        ...state,
        apiKeyManagerList: [],
      };
    }

    case SEARCH_API_KEY_MANAGER_DETAILS_SUCCESS: {
      return {
        ...state,
        apiKeyManagerList: payload?.Data,
      };
    }

    case SET_SELECTED_API_KEY: {
      return {
        ...state,
        selectedApiKey: {
          ...payload,
          CreateDate: isNil(payload?.CreateDate) || isEmpty(payload?.CreateDate) ? '' : format(new Date(payload?.CreateDate), 'MM/dd/yyyy'),
          ExpiryDate: isNil(payload?.ExpiryDate) || isEmpty(payload?.ExpiryDate) ? '' : format(new Date(payload?.ExpiryDate), 'MM/dd/yyyy'),
        },
      };
    }

    case SET_TARGET_UPDATE_STATE: {
      return {
        ...state,
        targetUpdateState: payload,
      };
    }

    case POST_API_KEY_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_API_KEY_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case POST_API_KEY_SUCCESS: {
      toast.success(SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_API_KEY_MANAGER_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_API_KEY_MANAGER_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_API_KEY_MANAGER_DETAILS_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};
