export const modalStyle = () => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 12,
    p: 4,
    overflow: 'auto',
  };
};

export const buttonStyle = () => {
  return {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10%',
    marginBottom: '2%',
  };
};
