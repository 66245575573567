import axios from 'axios';
import { setErrors } from './errorHandler';
import {
  getClientProfileCompanyContactsBody,
  searchClientProfileCheckDuplicateEmailContactsBody,
  searchClientProfileCompanySpecificContactsBody,
} from '../utils/clientProfileContactsUtils';
import { NO_DATA_FOUND } from '../constants/errorMessage';
import { VALIDATIONS } from 'constants/validations';

export const GET_CLIENT_PROFILE_CONTACTS_LOADING = '@@clientProfileCompanyContact/GET_CLIENT_PROFILE_CONTACTS_LOADING';
export const GET_CLIENT_PROFILE_CONTACTS_SUCCESS = '@@clientProfileCompanyContact/GET_CLIENT_PROFILE_CONTACTS_SUCCESS';
export const GET_CLIENT_PROFILE_CONTACTS_FAILURE = '@@clientProfileCompanyContact/GET_CLIENT_PROFILE_CONTACTS_FAILURE';

export const getClientProfileCompanyContacts = () => async (dispatch) => {
  dispatch({
    type: GET_CLIENT_PROFILE_CONTACTS_LOADING,
  });

  var body = getClientProfileCompanyContactsBody();

  try {
    const response = await axios.post(`/client/contact/search`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_CLIENT_PROFILE_CONTACTS_FAILURE,
        payload: {},
      });
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
    } else {
      dispatch({
        type: GET_CLIENT_PROFILE_CONTACTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: GET_CLIENT_PROFILE_CONTACTS_FAILURE,
      payload: {},
    });
  }
};

export const SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_LOADING = '@@clientProfileCompanyContact/SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_LOADING';
export const SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_SUCCESS = '@@clientProfileCompanyContact/SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_SUCCESS';
export const SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_FAILURE = '@@clientProfileCompanyContact/SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_FAILURE';

export const getClientProfileCompanySpecificContacts = (searchValue) => async (dispatch) => {
  dispatch({
    type: SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_LOADING,
  });
  let body;

  !VALIDATIONS.emailValidation.value.test(searchValue)
    ? (body = searchClientProfileCompanySpecificContactsBody(searchValue))
    : (body = searchClientProfileCheckDuplicateEmailContactsBody(searchValue));

  try {
    const response = await axios.post(`/client/contact/search`, body);
    if (response?.data?.ErrorCount > 0) {
      if (!VALIDATIONS.emailValidation.value.test(searchValue)) {
        if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
          dispatch(setErrors(response?.data?.Errors));
        }
        dispatch({
          type: SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_FAILURE,
        });
      } else {
        dispatch({
          type: SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_SUCCESS,
          payload: response?.data?.Data,
          checkerValue: 'Email',
        });
      }
    } else {
      dispatch({
        type: SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
        checkerValue: !VALIDATIONS.emailValidation.value.test(searchValue) ? 'FirstName' : 'Email',
      });
    }
  } catch {
    dispatch({
      type: SEARCH_CLIENT_PROFILE_SPECIFIC_CONTACTS_FAILURE,
      payload: {},
    });
  }
};

export const POST_CONTACT_TO_LIST_LOADING = '@@clientProfileCompanyContact/POST_CONTACT_TO_LIST_LOADING';
export const POST_CONTACT_TO_LIST_SUCCESS = '@@clientProfileCompanyContact/POST_CONTACT_TO_LIST_SUCCESS';
export const POST_CONTACT_TO_LIST_FAILURE = '@@clientProfileCompanyContact/POST_CONTACT_TO_LIST_FAILURE';

export const addContactToList = (formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_CONTACT_TO_LIST_LOADING,
  });

  const body = JSON.stringify(formData);

  try {
    const response = await axios.post(`/client/contact`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_CONTACT_TO_LIST_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: POST_CONTACT_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: POST_CONTACT_TO_LIST_FAILURE,
      payload: {},
    });
  }
};

export const PUT_CONTACT_TO_LIST_LOADING = '@@clientProfileCompanyContact/PUT_CONTACT_TO_LIST_LOADING';
export const PUT_CONTACT_TO_LIST_SUCCESS = '@@clientProfileCompanyContact/PUT_CONTACT_TO_LIST_SUCCESS';
export const PUT_CONTACT_TO_LIST_FAILURE = '@@clientProfileCompanyContact/PUT_CONTACT_TO_LIST_FAILURE';

export const updateContactToList = (formData, callback) => async (dispatch) => {
  dispatch({
    type: PUT_CONTACT_TO_LIST_LOADING,
  });
  let id = formData.clientContactId;

  const body = JSON.stringify(formData.formData);
  try {
    const response = await axios.put(`/client/contact/${id}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: PUT_CONTACT_TO_LIST_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: PUT_CONTACT_TO_LIST_SUCCESS,
        payload: {
          response: response?.data?.Data,
          formData,
        },
      });
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: PUT_CONTACT_TO_LIST_FAILURE,
      payload: {},
    });
  }
};

export const DELETE_CONTACT_FROM_LIST_LOADING = '@@clientProfileCompanyContact/DELETE_CONTACT_FROM_LIST_LOADING';
export const DELETE_CONTACT_FROM_LIST_SUCCESS = '@@clientProfileCompanyContact/DELETE_CONTACT_FROM_LIST_SUCCESS';
export const DELETE_CONTACT_FROM_LIST_FAILURE = '@@clientProfileCompanyContact/DELETE_CONTACT_FROM_LIST_FAILURE';

export const deleteContactFromList = (formData, callback) => async (dispatch) => {
  dispatch({
    type: DELETE_CONTACT_FROM_LIST_LOADING,
  });
  const id = formData.clientContactId;
  try {
    const response = await axios.delete(`/client/contact/${id}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: DELETE_CONTACT_FROM_LIST_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: DELETE_CONTACT_FROM_LIST_SUCCESS,
        payload: {
          response: response?.data?.Data,
          formData,
        },
      });
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: DELETE_CONTACT_FROM_LIST_FAILURE,
      payload: {},
    });
  }
};

export const SET_CONTACTS_LOADING = '@@clientProfileCompanyContact/SET_CONTACTS_LOADING';
export const SET_CONTACTS_SUCCESS = '@@clientProfileCompanyContact/SET_CONTACTS_SUCCESS';
export const SET_CONTACTS_FAILURE = '@@clientProfileCompanyContact/SET_CONTACTS_FAILURE';

export const setContacts = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_CONTACTS_LOADING,
  });
  try {
    dispatch({
      type: SET_CONTACTS_SUCCESS,
      payload: payload,
    });
    if (callback) {
      callback();
    }
  } catch {
    dispatch({
      type: SET_CONTACTS_FAILURE,
      payload: {},
    });
  }
};
