export const logOutStyles = () => {
  return {
    color: '#000 !important',
    '&:hover': {
      background: 'none',
    },
  };
};

export const iconStyles = () => {
  return {
    marginRight: '5px',
    transform: 'translateY("1px")',
    width: '22px',
    height: '16px',
  };
};
