export const getCompanyInformationSearchBody = () => {
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'ClientOrgName',
      'DomainName',
      'YearFounded',
      'NumberOfEmployeesRange',
      'RegisteredBusinessAddressLine1',
      'RegisteredBusinessAddressLine2',
      'RegisteredBusinessAddressCity',
      'RegisteredBusinessAddressState',
      'RegisteredBusinessAddressCountry',
      'RegisteredBusinessAddressZip',
      'Phone',
      'PhoneCode',
      'MainPhone',
      'PhoneExtension',
      'ZendeskOrgId',
    ],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
