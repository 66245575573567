import axios from 'axios';
import { API_RESPONSE_STATUSES, FILE_UPLOAD_STATUS } from '../constants/constants';
import { NO_DATA_FOUND } from '../constants/errorMessage';
import { getErrorObject } from '../utils/apiUtils';
import { getVendorProductContactBody } from '../utils/vendorIncidentUtils';
import { searchVendorListBody } from '../utils/vendorUtils';
import { setErrors } from './errorHandler';

export const SEARCH_VENDOR_LIST_LOADING = '@@vendors/SEARCH_VENDOR_LIST_LOADING';
export const SEARCH_VENDOR_LIST_SUCCESS = '@@vendors/SEARCH_VENDOR_LIST_SUCCESS';
export const SEARCH_VENDOR_LIST_FAILURE = '@@vendors/SEARCH_VENDOR_LIST_FAILURE';

export const searchVendorList = () => async (dispatch) => {
  dispatch({
    type: SEARCH_VENDOR_LIST_LOADING,
  });

  const body = searchVendorListBody();

  try {
    const response = await axios.post(`client/vendor/search`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: SEARCH_VENDOR_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_ONBOARDING_TEMPLATE_LOADING = '@@vendors/SEARCH_VENDOR_ONBOARDING_TEMPLATE_LOADING';
export const SEARCH_VENDOR_ONBOARDING_TEMPLATE_SUCCESS = '@@vendors/SEARCH_VENDOR_ONBOARDING_TEMPLATE_SUCCESS';
export const SEARCH_VENDOR_ONBOARDING_TEMPLATE_FAILURE = '@@vendors/SEARCH_VENDOR_ONBOARDING_TEMPLATE_FAILURE';

export const searchVendorOnboardingTemplate =
  (clientOrgId = 0, callback) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_VENDOR_ONBOARDING_TEMPLATE_LOADING });
      try {
        const response = await axios.post(`/client/${clientOrgId}/vendoronboardingtemplate/action/geturl`);

        if (response?.data?.ErrorCount > 0) {
          dispatch(setErrors(response?.data?.Errors));
          dispatch({
            type: SEARCH_VENDOR_ONBOARDING_TEMPLATE_FAILURE,
          });
        } else {
          dispatch({
            type: SEARCH_VENDOR_ONBOARDING_TEMPLATE_SUCCESS,
            payload: response?.data,
          });

          if (callback) {
            callback(response?.data?.Data?.Url);
          }
        }
      } catch (err) {
        dispatch({
          type: SEARCH_VENDOR_ONBOARDING_TEMPLATE_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const POST_XLSX_VENDOR_CONTACT_UPLOAD_LOADING = '@@vendors/POST_XLSX_VENDOR_CONTACT_UPLOAD_LOADING';
export const POST_XLSX_VENDOR_CONTACT_UPLOAD_SUCCESS = '@@vendors/POST_XLSX_VENDOR_CONTACT_UPLOAD_SUCCESS';
export const POST_XLSX_VENDOR_CONTACT_UPLOAD_FAILURE = '@@vendors/POST_XLSX_VENDOR_CONTACT_UPLOAD_FAILURE';

export const uploadVendorContacts = (uploadedFile, callback) => async (dispatch) => {
  dispatch({ type: POST_XLSX_VENDOR_CONTACT_UPLOAD_LOADING });

  try {
    const formData = new FormData();
    Object.keys(uploadedFile).forEach((key) => {
      formData.append('uploadedFile[]', uploadedFile[key]);
    });

    // Obtain the presigned URL for uploading the file ...
    const response = await axios.post(`/client/fileupload/action/geturl`, formData);

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_XLSX_VENDOR_CONTACT_UPLOAD_FAILURE,
      });
    } else {
      const fileOptions = {
        body: uploadedFile[0],
        method: 'PUT',
        headers: {
          'Content-Type': 'text/xlsx',
        },
      };

      // Upload the file using pre-signed url ...
      const fileRes = await fetch(response?.data?.Data?.UploadClientFileUrl?.Url, fileOptions);

      const data = {
        ClientFileUploadLogId: response?.data?.Data?.ClientFileUploadLogId,
        NewStatus: FILE_UPLOAD_STATUS.PENDING,
      };

      if (fileRes?.status !== API_RESPONSE_STATUSES.SUCCESS) {
        // File upload failed ....
        data.NewStatus = FILE_UPLOAD_STATUS.FAILED;
      } else {
        data.NewStatus = FILE_UPLOAD_STATUS.SUCCESS;
      }

      // Update the status of file upload ...
      const res = await axios.post('/client/fileupload/action/updatestatus', data);
      if (res?.data?.ErrorCount > 0) {
        dispatch(setErrors(res?.data?.Errors));
        dispatch({
          type: POST_XLSX_VENDOR_CONTACT_UPLOAD_FAILURE,
        });
      } else {
        if (data.NewStatus === FILE_UPLOAD_STATUS.SUCCESS) {
          dispatch({
            type: POST_XLSX_VENDOR_CONTACT_UPLOAD_SUCCESS,
            payload: response?.data,
          });
        } else {
          dispatch({
            type: POST_XLSX_VENDOR_CONTACT_UPLOAD_FAILURE,
          });
        }
      }
    }
    if (callback) {
      callback()
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_ONBOARDING_TEMPLATE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_VENDOR_PRODUCT_LIST_LOADING = '@@vendors/GET_VENDOR_PRODUCT_LIST_LOADING';
export const GET_VENDOR_PRODUCT_LIST_SUCCESS = '@@vendors/GET_VENDOR_PRODUCT_LIST_SUCCESS';
export const GET_VENDOR_PRODUCT_LIST_FAILURE = '@@vendors/GET_VENDOR_PRODUCT_LIST_FAILURE';

export const getVendorProductList =
  (searchText = '') =>
    async (dispatch) => {
      dispatch({ type: GET_VENDOR_PRODUCT_LIST_LOADING });
      try {
        var response;

        if (searchText) {
          response = await axios.get(`/app/customer/vendorproduct?vendorName=${searchText}`);
        } else {
          response = await axios.get(`/app/customer/vendorproduct`);
        }

        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: GET_VENDOR_PRODUCT_LIST_FAILURE,
            payload: {},
          });
          dispatch(setErrors(response?.data?.Errors));
        } else {
          dispatch({
            type: GET_VENDOR_PRODUCT_LIST_SUCCESS,
            payload: response?.data,
          });
        }
      } catch (err) {
        dispatch({
          type: GET_VENDOR_PRODUCT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_VENDOR_PRODUCT_CONTACT_LIST_LOADING = '@@vendors/SEARCH_VENDOR_PRODUCT_CONTACT_LIST_LOADING';
export const SEARCH_VENDOR_PRODUCT_CONTACT_LIST_SUCCESS = '@@vendors/SEARCH_VENDOR_PRODUCT_CONTACT_LIST_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_CONTACT_LIST_FAILURE = '@@vendors/SEARCH_VENDOR_PRODUCT_CONTACT_LIST_FAILURE';

export const searchVendorProductContactList = (customerVendorId, vendorOrgId, productVendorId) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_CONTACT_LIST_LOADING });

  const body = getVendorProductContactBody(customerVendorId, productVendorId);
  try {
    const response = await axios.post(`/customer/vendor/${vendorOrgId}/incidentresponsecontact/search`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_CONTACT_LIST_FAILURE,
        payload: {},
      });
      if (response?.data?.Errors[0].Message !== NO_DATA_FOUND) {
        dispatch(setErrors(response?.data?.Errors));
      }
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_CONTACT_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_CONTACT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_VENDOR_METRICS_LOADING = '@@vendors/GET_VENDOR_METRICS_LOADING';
export const GET_VENDOR_METRICS_SUCCESS = '@@vendors/GET_VENDOR_METRICS_SUCCESS';
export const GET_VENDOR_METRICS_FAILURE = '@@vendors/GET_VENDOR_METRICS_FAILURE';

export const getVendorMetrics = () => async (dispatch) => {
  dispatch({ type: GET_VENDOR_METRICS_LOADING });
  try {
    var response = await axios.get(`/app/customer/vendor/metrics`);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_VENDOR_METRICS_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: GET_VENDOR_METRICS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VENDOR_METRICS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
