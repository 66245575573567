import axios from 'axios';
import { addApiKeyBody, getApiKeyManagerDetailsSearchBody, saveApiKeyManagerDetailsBody } from 'utils/apiKeyManagerUtils';
import { getErrorObject } from 'utils/apiUtils';

export const SEARCH_API_KEY_MANAGER_DETAILS_LOADING = '@apiKeyManager/SEARCH_API_KEY_MANAGER_DETAILS_LOADING';
export const SEARCH_API_KEY_MANAGER_DETAILS_SUCCESS = '@apiKeyManager/SEARCH_API_KEY_MANAGER_DETAILS_SUCCESS';
export const SEARCH_API_KEY_MANAGER_DETAILS_FAILURE = '@apiKeyManager/SEARCH_API_KEY_MANAGER_DETAILS_FAILURE';

export const getApiKeyManagerDetails = (callback) => async (dispatch) => {
  dispatch({ type: SEARCH_API_KEY_MANAGER_DETAILS_LOADING });
  try {
    const body = getApiKeyManagerDetailsSearchBody();
    const response = await axios.post(`/client/apiuser/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_API_KEY_MANAGER_DETAILS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_API_KEY_MANAGER_DETAILS_SUCCESS,
        payload: response.data || [],
      });
      if (callback) {
        callback(response?.data?.Data);
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_API_KEY_MANAGER_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_API_KEY = '@apiKeyManager/SET_SELECTED_API_KEY';

export const setSelectedApiKey =
  (selectedApiKey = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_API_KEY,
      payload: selectedApiKey,
    });
  };

export const SET_TARGET_UPDATE_STATE = '@apiKeyManager/SET_TARGET_UPDATE_STATE';

export const setTargetUpdateState =
  (requestFormData = {}, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_TARGET_UPDATE_STATE,
      payload: requestFormData,
    });
    if (callback) {
      callback();
    }
  };

export const POST_API_KEY_LOADING = '@apiKeyManager/POST_API_KEY_LOADING';
export const POST_API_KEY_SUCCESS = '@apiKeyManager/POST_API_KEY_SUCCESS';
export const POST_API_KEY_FAILURE = '@apiKeyManager/POST_API_KEY_FAILURE';

export const addApiKey = (formData) => async (dispatch) => {
  dispatch({ type: POST_API_KEY_LOADING });
  try {
    const body = addApiKeyBody(formData);
    const response = await axios.post(`/client/apiuser`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_API_KEY_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: POST_API_KEY_SUCCESS,
        payload: response.data || [],
      });
      dispatch(getApiKeyManagerDetails());
    }
  } catch (err) {
    dispatch({
      type: POST_API_KEY_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_API_KEY_MANAGER_DETAILS_LOADING = '@apiKeyManager/UPDATE_API_KEY_MANAGER_DETAILS_LOADING';
export const UPDATE_API_KEY_MANAGER_DETAILS_SUCCESS = '@apiKeyManager/UPDATE_API_KEY_MANAGER_DETAILS_SUCCESS';
export const UPDATE_API_KEY_MANAGER_DETAILS_FAILURE = '@apiKeyManager/UPDATE_API_KEY_MANAGER_DETAILS_FAILURE';

export const updateApiKeyManagerDetails = (formData) => async (dispatch) => {
  dispatch({ type: UPDATE_API_KEY_MANAGER_DETAILS_LOADING });
  try {
    const body = saveApiKeyManagerDetailsBody(formData);
    const response = await axios.put(`/client/apiuser/${formData?.ApiUserId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: UPDATE_API_KEY_MANAGER_DETAILS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: UPDATE_API_KEY_MANAGER_DETAILS_SUCCESS,
        payload: response.data || [],
      });
      dispatch(
        getApiKeyManagerDetails((data) => {
          dispatch(setSelectedApiKey(data?.find((item) => item.ApiUserId === formData?.ApiUserId)));
        })
      );
    }
  } catch (err) {
    dispatch({
      type: UPDATE_API_KEY_MANAGER_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


