import axios from "axios";
import { CARD_VIEW, DEFAULT_VENDOR_CATALOG_ROWS_PER_PAGE, TABLE_VIEW, VENDORS_CATALOG_TAB_OPTIONS } from "pages/VendorCatalog/constant";
import { setErrors } from "./errorHandler";
import { getErrorObject } from "utils/apiUtils";
import { NO_DATA_FOUND } from "constants/errorMessage";
import { getTableViewListBody, searchVendorCatalogCardListBody, getAssociateVendorClientOrgBody, getAssociateProductToCustomerVendorBody, getAllProductsForVendorSearchBody } from "utils/vendorCatalogUtils";

export const SET_VENDORS_CATALOG_TAB_VALUE = '@@vendorCatalog/SET_VENDORS_CATALOG_TAB_VALUE';

export const setVendorsCatalogTabValue = (value = VENDORS_CATALOG_TAB_OPTIONS.CARD) =>
    async (dispatch) => {
      dispatch({
        type: SET_VENDORS_CATALOG_TAB_VALUE,
        payload: value,
      });
};

export const GET_TABLE_VIEW_DATA_LOADING = '@@vendorCatalog/GET_VENDORS_TABLE_VIEW_DATA_LOADING';
export const GET_TABLE_VIEW_DATA_SUCCESS = '@@vendorCatalog/GET_VENDORS_TABLE_VIEW_DATA_SUCCESS';
export const GET_TABLE_VIEW_DATA_FAILURE = '@@vendorCatalog/GET_VENDORS_TABLE_VIEW_DATA_FAILURE';

export const searchVendorCatalogTableView = (searchData, pageNo, rowsPerPage) => async (dispatch) => {
  dispatch({ type: GET_TABLE_VIEW_DATA_LOADING });
  try {
    const body = getTableViewListBody(searchData, pageNo, rowsPerPage);
    const response = await axios.post(`customer/vendor/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: GET_TABLE_VIEW_DATA_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: GET_TABLE_VIEW_DATA_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_TABLE_VIEW_DATA_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_LOADING = '@@vendorCatalog/ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_LOADING';
export const ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_SUCCESS = '@@vendorCatalog/ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_SUCCESS';
export const ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_FAILURE = '@@vendorCatalog/ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_FAILURE';

export const associateVendorClientOrg = (data = {}, filterData, pageNo, rowsPerPage, expandedVendorId, view) => async (dispatch) => {
  dispatch({ type: ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_LOADING });
  try {
    const body = getAssociateVendorClientOrgBody(data);
    const response = await axios.post(`app/customer/vendor`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if(expandedVendorId) {
        dispatch(getAllProductsForVendorSearch({ VendorOrgId: expandedVendorId.toString() }));
      }
      if(view === CARD_VIEW){
        dispatch(searchVendorCatalogCardList(filterData, pageNo, rowsPerPage));
      } else if(view === TABLE_VIEW){
      dispatch(searchVendorCatalogTableView(filterData, pageNo, rowsPerPage));
      }
    }
  } catch (err) {
    dispatch({
      type: ASSOCIATE_VENDOR_ORG_TO_CLIENT_ORG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_VENDOR_PROFILE = '@@vendorCatalog/SET_SELECTED_VENDOR_PROFILE';

export const setSelectedVendorProfile = (vendor) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_VENDOR_PROFILE,
    payload: vendor,
  });
};

export const SET_EXPANDED_VENDOR_ID = '@@vendorCatalog/SET_EXPANDED_VENDOR_ID';

export const setExpandedVendorId = (vendorId) => async (dispatch) => {
  dispatch({
    type: SET_EXPANDED_VENDOR_ID,
    payload: vendorId,
  });
};

export const SEARCH_ALL_PRODUCTS_FOR_VENDOR_LOADING = '@@vendorCatalog/SEARCH_ALL_PRODUCTS_FOR_VENDOR_LOADING';
export const SEARCH_ALL_PRODUCTS_FOR_VENDOR_SUCCESS = '@@vendorCatalog/SEARCH_ALL_PRODUCTS_FOR_VENDOR_SUCCESS';
export const SEARCH_ALL_PRODUCTS_FOR_VENDOR_FAILURE = '@@vendorCatalog/SEARCH_ALL_PRODUCTS_FOR_VENDOR_FAILURE';

export const getAllProductsForVendorSearch = (formData) => async (dispatch) => {
  dispatch({
    type: SEARCH_ALL_PRODUCTS_FOR_VENDOR_LOADING,
  });

  const body = getAllProductsForVendorSearchBody(formData);
  try {
    const res = await axios.post(`/customer/vendor/product/association/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors))
      dispatch({
        type: SEARCH_ALL_PRODUCTS_FOR_VENDOR_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ALL_PRODUCTS_FOR_VENDOR_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ALL_PRODUCTS_FOR_VENDOR_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_LOADING = '@@vendorCatalog/ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_LOADING';
export const ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_SUCCESS = '@@vendorCatalog/ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_SUCCESS';
export const ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_FAILURE = '@@vendorCatalog/ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_FAILURE';

export const associateProductToCustomerVendor = (data = {}, expandedVendorId) => async (dispatch) => {
  dispatch({ type: ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_LOADING });
  try {
    const body = getAssociateProductToCustomerVendorBody(data);
    const response = await axios.post(`customer/vendor/${data?.CustomerVendorId}/product`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      dispatch(getAllProductsForVendorSearch({ VendorOrgId: expandedVendorId.toString() }));
    }
  } catch (err) {
    dispatch({
      type: ASSOCIATE_PRODUCT_TO_CUSTOMER_VENDOR_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const SEARCH_VENDOR_CATALOG_CARD_LIST_LOADING = '@@vendorCatalog/SEARCH_VENDOR_CATALOG_CARD_LIST_LOADING';
export const SEARCH_VENDOR_CATALOG_CARD_LIST_SUCCESS = '@@vendorCatalog/SEARCH_VENDOR_CATALOG_CARD_LIST_SUCCESS';
export const SEARCH_VENDOR_CATALOG_CARD_LIST_FAILURE = '@@vendorCatalog/SEARCH_VENDOR_CATALOG_CARD_LIST_FAILURE';

export const searchVendorCatalogCardList = (searchData, pageNo, rowsPerPage) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_CATALOG_CARD_LIST_LOADING });
  try {
    const body = searchVendorCatalogCardListBody(searchData, pageNo, rowsPerPage);
    const response = await axios.post(`customer/vendor/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_VENDOR_CATALOG_CARD_LIST_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_VENDOR_CATALOG_CARD_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_CATALOG_CARD_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_VENDOR_CATALOG_PAGE_NO = '@vendorCatalog/SET_VENDOR_CATALOG_PAGE_NO';

export const setVendorCatalogPageNo =
  (page = 0) =>
  async (dispatch) => {
    dispatch({
      type: SET_VENDOR_CATALOG_PAGE_NO,
      payload: page,
    });
};


export const SET_VENDOR_CATALOG_ROW_PER_PAGE = '@vendorCatalog/SET_VENDOR_CATALOG_ROW_PER_PAGE';

export const setVendorCatalogRowPerPage =
  (page = DEFAULT_VENDOR_CATALOG_ROWS_PER_PAGE, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_VENDOR_CATALOG_ROW_PER_PAGE,
      payload: page,
    });
    if (callback) {
      callback(page);
    }
};