import { isEmpty, isString } from 'lodash';

export const VALIDATIONS = {
  yearValidation: {
    key: 'yearValidate',
    value: 4,
    noOfDigitError: 'Year should have 4 digits',
    maxYearError: 'Year Founded cannot be a future date!',
  },
  zipValidation: {
    key: 'zipValidate',
    value: 5,
    error: 'Zip should have 5 digits',
  },
  domainValidation: {
    key: 'domainValidate',
    value:
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    error: 'Domain Name should be valid',
  },
  noSubDomainValidation: {
    key: 'noSubDomainValidate',
    value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-z]{2,}(:[0-9]{1,})?(\/.*)?$/,
    error: 'Please enter a valid URL',
  },
  emailValidation: {
    key: 'emailValidate',
    value: /^[A-Za-z0-9'._%+-]{1,64}@[A-Za-z0-9.-]+\.[a-z]{2,255}$/i,
    error: 'Invalid email address',
  },
  onlyAlphabetValidation: {
    key: 'onlyAlphabetValidate',
    value: /[^a-zA-Z ]/i,
    error: 'Only alphabets are allowed. Please avoid numbers and special characters',
  },
  titleValidation: {
    key: 'titleValidate',
    value: /^[a-zA-Z&\-\s]+$/i,
    error: 'Please verify the title',
  },
  nameValidation: {
    key: 'nameValidate',
    value: /[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]/i,
    error: 'Please verify the name',
  },
  alphaNumericNameValidation: {
    key: 'alphaNumericNameValidate',
    value: /[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9 ,.'-]/i,
    error: 'Please verify the name',
  },
  onlyAlphabetAndNumberValidation: {
    key: 'onlyAlphabetAndNumberValidate',
    value: /[^a-zA-Z0-9 ]/i,
    error: 'Only alphabets and digits are allowed. Please avoid special characters',
  },
  leadingSpaceValidation: {
    key: 'leadingSpaceValidation',
    value: /^\s/g,
    error: '',
  },
  leadingZeroValidation: {
    key: 'leadingZeroValidation',
    value: /^0/g,
    error: '',
  },
  phoneCodeValidation: {
    key: 'phoneCodeValidate',
    value: /^(([0-9]{2,4})|(\+[0-9]{1,3}))$/,
    error: "Valid codes include +1', '+44', ...",
  },
  mainPhoneValidation: {
    key: 'mainPhoneValidate',
    error: 'Must be minimum 10 digits',
  },
  deleteConfirmationValidation: {
    key: 'deleteConfirmationValidation',
    value: 'Delete',
    error: 'Entered text does not match with the expected text. Please retry.',
  },
  disablePastDateValidation: {
    key: 'disablePastDateValidation',
    error: 'Enter a future date, or leave this field empty',
  },
  disableFutureDateValidation: {
    key: 'disableFutureDateValidation',
    error: 'Enter a past date',
  },
};

export const restrictSpaceAtStart = (value) => {
  if (!value) {
    return value;
  }
  return isString(value) ? value.replace(VALIDATIONS.leadingSpaceValidation.value, '') : '';
};
export const restrictZeroAtStart = (value) => {
  if (!value) {
    return value;
  }
  return isString(value) ? value.replace(VALIDATIONS.leadingZeroValidation.value, '') : '';
};

export const nameValidation = (value) => !isEmpty(value) && (!VALIDATIONS.nameValidation.value.test(value) ? undefined : VALIDATIONS.nameValidation.error);

export const alphaNumericNameValidation = (value) =>
  !isEmpty(value) && (!VALIDATIONS.alphaNumericNameValidation.value.test(value) ? undefined : VALIDATIONS.alphaNumericNameValidation.error);
