import { NO_DATA_FOUND } from 'constants/errorMessage';
import { PRODUCTS_LIST, VENDORS_AND_PRODUCTS_TAB_OPTIONS } from 'pages/VendorsAndProducts/constants';
import { setErrors } from './errorHandler';
import { getErrorObject } from 'utils/apiUtils';
import axios from 'axios';
import { getCustomerVendorProductLookupSearchBody, getProductListSearchBody, getVendorInformationSearchBody, getVendorSearchListBody, getVendorsListBody, updateVendorDetailsSearchBody } from 'utils/vendorsAndProductsUtils';

export const SET_VENDORS_AND_PRODUCTS_TAB_VALUE = '@vendorsAndProducts/SET_VENDORS_AND_PRODUCTS_TAB_VALUE';

export const setVendorsAndProductsTabValue =
  (value = VENDORS_AND_PRODUCTS_TAB_OPTIONS.VENDORS) =>
    async (dispatch) => {
      dispatch({
        type: SET_VENDORS_AND_PRODUCTS_TAB_VALUE,
        payload: value,
      });
    };

export const SET_SELECTED_VENDOR = '@@vendorsAndProducts/SET_SELECTED_VENDOR';

export const setSelectedVendor =
  (selectedVendor = {}) =>
    async (dispatch) => {
      dispatch({
        type: SET_SELECTED_VENDOR,
        payload: selectedVendor,
      });
    };

export const GET_VENDORS_LIST_LOADING = '@@supportTicket/GET_VENDORS_LIST_LOADING';
export const GET_VENDORS_LIST_SUCCESS = '@@supportTicket/GET_VENDORS_LIST_SUCCESS';
export const GET_VENDORS_LIST_FAILURE = '@@supportTicket/GET_VENDORS_LIST_FAILURE';

export const getVendorsListData = (searchData, callback) => async (dispatch) => {
  dispatch({ type: GET_VENDORS_LIST_LOADING });
  try {
    const body = getVendorsListBody(searchData, callback);
    const response = await axios.post(`app/customer/vendor/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: GET_VENDORS_LIST_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: GET_VENDORS_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if (callback) {
        callback(response?.data?.Data?.Rows);
      }
    }
  } catch (err) {
    dispatch({
      type: GET_VENDORS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const PUT_VENDOR_SIDE_SHEET_LOADING = '@@vendorsAndProducts/PUT_VENDOR_SIDE_SHEET_LOADING';
export const PUT_VENDOR_SIDE_SHEET_SUCCESS = '@@vendorsAndProducts/PUT_VENDOR_SIDE_SHEET_SUCCESS';
export const PUT_VENDOR_SIDE_SHEET_FAILURE = '@@vendorsAndProducts/PUT_VENDOR_SIDE_SHEET_FAILURE';

export const updateVendorSideSheetDetails = (formData) => async (dispatch) => {
  dispatch({
    type: PUT_VENDOR_SIDE_SHEET_LOADING,
  });

  const body = updateVendorDetailsSearchBody(formData);
  try {
    const response = await axios.put(`/app/customer/vendor/${formData?.CustomerVendorId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_VENDOR_SIDE_SHEET_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_VENDOR_SIDE_SHEET_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(
        getVendorsListData({}, (data) => {
          dispatch(setSelectedVendor(data.find((item) => item.CustomerVendorId === formData?.CustomerVendorId)));
        })
      );
    }
  } catch {
    dispatch({
      type: PUT_VENDOR_SIDE_SHEET_FAILURE,
    });
  }
};

export const DELETE_CLIENT_VENDOR_RELATIONSHIP_LOADING = '@@vendorsAndProducts/DELETE_CLIENT_VENDOR_RELATIONSHIP_LOADING';
export const DELETE_CLIENT_VENDOR_RELATIONSHIP_SUCCESS = '@@vendorsAndProducts/DELETE_CLIENT_VENDOR_RELATIONSHIP_SUCCESS';
export const DELETE_CLIENT_VENDOR_RELATIONSHIP_FAILURE = '@@vendorsAndProducts/DELETE_CLIENT_VENDOR_RELATIONSHIP_FAILURE';

export const deleteClientVendorRelationship = (CustomerVendorId, callback) => async (dispatch) => {
  dispatch({
    type: DELETE_CLIENT_VENDOR_RELATIONSHIP_LOADING,
  });

  try {
    const response = await axios.delete(`/app/customer/vendor/${CustomerVendorId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_CLIENT_VENDOR_RELATIONSHIP_FAILURE,
      });
    } else {
      dispatch({
        type: DELETE_CLIENT_VENDOR_RELATIONSHIP_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(getVendorsListData());
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: DELETE_CLIENT_VENDOR_RELATIONSHIP_FAILURE,
    });
  }
};
export const RESET_VENDORS_AND_PRODUCTS = '@@vendorsAndProducts/RESET_VENDORS_AND_PRODUCTS';

export const resetVendorsandProducts = () => async (dispatch) => {
  dispatch({
    type: RESET_VENDORS_AND_PRODUCTS,
  });
};
export const SET_SELECTED_VENDOR_LOOKUP_INDEX = '@@vendorsAndProducts/SET_SELECTED_VENDOR_LOOKUP_INDEX';

export const setSelectedVendorLookupIndex =
  (selectedVendorindex = null) =>
    async (dispatch) => {
      dispatch({
        type: SET_SELECTED_VENDOR_LOOKUP_INDEX,
        payload: selectedVendorindex,
      });
    };

export const SET_SELECTED_VENDOR_LOOKUP_DETAILS = '@@vendorsAndProducts/SET_SELECTED_VENDOR_LOOKUP_DETAILS';

export const setSelectedVendorLookupDetails =
  (selectedVendorDetails = {}) =>
    async (dispatch) => {
      dispatch({
        type: SET_SELECTED_VENDOR_LOOKUP_DETAILS,
        payload: selectedVendorDetails,
      });
    };

export const RESET_SELECTED_VENDOR_LOOKUP_DETAILS = '@@vendorsAndProducts/RESET_SELECTED_VENDOR_LOOKUP_DETAILS';

export const resetSelectedVendorLookupDetails = () => async (dispatch) => {
  dispatch({
    type: RESET_SELECTED_VENDOR_LOOKUP_DETAILS,
  });
};

export const SET_VENDORS_AND_PRODUCTS_MANUAL_ADD_VENDOR_BOX = '@@vendorsAndProducts/SET_VENDORS_AND_PRODUCTS_MANUAL_ADD_VENDOR_BOX';

export const setShowManualAddVendorBox =
  (val = false) =>
    async (dispatch) => {
      dispatch({
        type: SET_VENDORS_AND_PRODUCTS_MANUAL_ADD_VENDOR_BOX,
        payload: val,
      });
    };

export const SEARCH_VENDORS_LIST_LOADING = '@@vendorsAndProducts/SEARCH_VENDORS_LIST_LOADING';
export const SEARCH_VENDORS_LIST_SUCCESS = '@@vendorsAndProducts/SEARCH_VENDORS_LIST_SUCCESS';
export const SEARCH_VENDORS_LIST_FAILURE = '@@vendorsAndProducts/SEARCH_VENDORS_LIST_FAILURE';

export const getvendorSearchList = (formData) => async (dispatch) => {
  dispatch({
    type: SEARCH_VENDORS_LIST_LOADING,
  });

  const body = getVendorSearchListBody(formData);
  try {
    const res = await axios.post(`/app/customer/vendor/fulltext/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_VENDORS_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_VENDORS_LIST_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
    dispatch(setShowManualAddVendorBox(true));
  } catch (err) {
    dispatch({
      type: SEARCH_VENDORS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_VENDORS_TO_CLIENT_LOADING = '@@vendorsAndProducts/ADD_VENDORS_TO_CLIENT_LOADING';
export const ADD_VENDORS_TO_CLIENT_SUCCESS = '@@vendorsAndProducts/ADD_VENDORS_TO_CLIENT_SUCCESS';
export const ADD_VENDORS_TO_CLIENT_FAILURE = '@@vendorsAndProducts/ADD_VENDORS_TO_CLIENT_FAILURE';

export const addVendorsToClient = (formData, callback) => async (dispatch) => {
  dispatch({
    type: ADD_VENDORS_TO_CLIENT_LOADING,
  });

  try {
    const res = await axios.post(`/app/customer/vendor`, formData);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: ADD_VENDORS_TO_CLIENT_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: ADD_VENDORS_TO_CLIENT_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: ADD_VENDORS_TO_CLIENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const MANUAL_ADD_VENDORS_TO_CLIENT_LOADING = '@@vendorsAndProducts/MANUAL_ADD_VENDORS_TO_CLIENT_LOADING';
export const MANUAL_ADD_VENDORS_TO_CLIENT_SUCCESS = '@@vendorsAndProducts/MANUAL_ADD_VENDORS_TO_CLIENT_SUCCESS';
export const MANUAL_ADD_VENDORS_TO_CLIENT_FAILURE = '@@vendorsAndProducts/MANUAL_ADD_VENDORS_TO_CLIENT_FAILURE';

export const manualAddVendorsToClient = (formData, callback) => async (dispatch) => {
  dispatch({
    type: MANUAL_ADD_VENDORS_TO_CLIENT_LOADING,
  });

  try {
    const res = await axios.post(`/vendor/action/ticket`, formData);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: MANUAL_ADD_VENDORS_TO_CLIENT_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: MANUAL_ADD_VENDORS_TO_CLIENT_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: MANUAL_ADD_VENDORS_TO_CLIENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_VENDOR_COMPANY_INFO_LOADING = '@@vendorsAndProducts/POST_VENDOR_COMPANY_INFO_LOADING';
export const POST_VENDOR_COMPANY_INFO_SUCCESS = '@@vendorsAndProducts/POST_VENDOR_COMPANY_INFO_SUCCESS';
export const POST_VENDOR_COMPANY_INFO_FAILURE = '@@vendorsAndProducts/POST_VENDOR_COMPANY_INFO_FAILURE';

export const addVendorCompanyInformation = (formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_VENDOR_COMPANY_INFO_LOADING,
  });

  const body = {
    OrgName: formData.VendorOrgName,
    DomainName: formData.DomainName,
    IsVerified: false,
    AddVendorToDynamics: false,
  };

  try {
    const res = await axios.post(`/vendor`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_VENDOR_COMPANY_INFO_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: POST_VENDOR_COMPANY_INFO_SUCCESS,
        payload: res?.data,
      });
      if (callback) {
        callback(res?.data?.Data);
      }
    }
  } catch (err) {
    dispatch({
      type: POST_VENDOR_COMPANY_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_INFORMATION_LOADING = '@@vendorsAndProducts/SEARCH_VENDOR_INFORMATION_LOADING';
export const SEARCH_VENDOR_INFORMATION_SUCCESS = '@@vendorsAndProducts/SEARCH_VENDOR_INFORMATION_SUCCESS';
export const SEARCH_VENDOR_INFORMATION_FAILURE = '@@vendorsAndProducts/SEARCH_VENDOR_INFORMATION_FAILURE';

export const getVendorInformation = (vendorOrgName, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_INFORMATION_LOADING });
  try {
    const body = getVendorInformationSearchBody(vendorOrgName);
    const response = await axios.post(`/vendor/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const error = response?.data?.Errors.filter(
        (it) => it.Message === NO_DATA_FOUND
      );
      if (error && callback) {
        callback()
      }
      dispatch({
        type: SEARCH_VENDOR_INFORMATION_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_INFORMATION_SUCCESS,
        payload: response.data,
      });

      if (response?.data?.Data?.Rows?.length > 0) {
        dispatch(setErrors([
          {
            Message: `${vendorOrgName} already exists.`
          }
        ]))
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_INFORMATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_PRODUCTS_LIST_LOADING = '@@vendorsAndProducts/SEARCH_PRODUCTS_LIST_LOADING';
export const SEARCH_PRODUCTS_LIST_SUCCESS = '@@vendorsAndProducts/SEARCH_PRODUCTS_LIST_SUCCESS';
export const SEARCH_PRODUCTS_LIST_FAILURE = '@@vendorsAndProducts/SEARCH_PRODUCTS_LIST_FAILURE';

export const getProductListSearch = (formData) => async (dispatch) => {
  dispatch({
    type: SEARCH_PRODUCTS_LIST_LOADING,
  });

  const body = getProductListSearchBody(formData);
  try {
    const res = await axios.post(`/customer/vendor/product/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors))
      dispatch({
        type: SEARCH_PRODUCTS_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCTS_LIST_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCTS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_LOADING = '@@vendorsAndProducts/SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_LOADING';
export const SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_SUCCESS = '@@vendorsAndProducts/SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_SUCCESS';
export const SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_FAILURE = '@@vendorsAndProducts/SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_FAILURE';

export const getCustomerVendorProductSearch = () => async (dispatch) => {
  dispatch({
    type: SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_LOADING,
  });

  const body = getCustomerVendorProductLookupSearchBody();
  try {
    const res = await axios.post(`/customer/vendor/product/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors))
      dispatch({
        type: SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_PRODUCTS_LIST_OF_VENDOR_LOADING = '@@vendorsAndProducts/SEARCH_PRODUCTS_LIST_OF_VENDOR_LOADING';
export const SEARCH_PRODUCTS_LIST_OF_VENDOR_SUCCESS = '@@vendorsAndProducts/SEARCH_PRODUCTS_LIST_OF_VENDOR_SUCCESS';
export const SEARCH_PRODUCTS_LIST_OF_VENDOR_FAILURE = '@@vendorsAndProducts/SEARCH_PRODUCTS_LIST_OF_VENDOR_FAILURE';

export const getProductListofVendorSearch = (formData) => async (dispatch) => {
  dispatch({
    type: SEARCH_PRODUCTS_LIST_OF_VENDOR_LOADING,
  });

  const body = getProductListSearchBody(formData);
  try {
    const res = await axios.post(`/customer/vendor/product/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors))
      dispatch({
        type: SEARCH_PRODUCTS_LIST_OF_VENDOR_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCTS_LIST_OF_VENDOR_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCTS_LIST_OF_VENDOR_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_EXPANDED_VENDOR = '@@vendorsAndProducts/SET_EXPANDED_VENDOR';

export const setExpandedVendor = (vendorId) => async (dispatch) => {
  dispatch({
    type: SET_EXPANDED_VENDOR,
    payload: vendorId,
  });
};

export const RESET_VENDOR_LIST = '@@vendorsAndProducts/RESET_VENDOR_LIST';

export const resetVendorList = (list = []) => async (dispatch) => {
  dispatch({
    type: RESET_VENDOR_LIST,
    payload: list,
  });
};

export const SET_SELECTED_PRODUCT = '@@vendorsAndProducts/SET_SELECTED_PRODUCT';

export const setSelectedProduct = (selectedProduct = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_PRODUCT,
      payload: selectedProduct,
    });
  };

export const PUT_PRODUCT_SIDE_SHEET_LOADING = '@@vendorsAndProducts/PUT_PRODUCT_SIDE_SHEET_LOADING';
export const PUT_PRODUCT_SIDE_SHEET_SUCCESS = '@@vendorsAndProducts/PUT_PRODUCT_SIDE_SHEET_SUCCESS';
export const PUT_PRODUCT_SIDE_SHEET_FAILURE = '@@vendorsAndProducts/PUT_PRODUCT_SIDE_SHEET_FAILURE';

export const updateProductSideSheetDetails = (formData, openTableSideSheetFrom, expandedVendorId) => async (dispatch) => {
  dispatch({
    type: PUT_PRODUCT_SIDE_SHEET_LOADING,
  });

  try {
    const response = await axios.put(`/app/customer/vendor/product/${formData?.CustomerVendorProductId}`, formData);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_PRODUCT_SIDE_SHEET_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_PRODUCT_SIDE_SHEET_SUCCESS,
        payload: response?.data?.Data,
      });
      if(openTableSideSheetFrom === PRODUCTS_LIST) {
        dispatch(getProductListSearch());
      } else {
        dispatch(getProductListofVendorSearch({ VendorOrgId: expandedVendorId.toString() }));
      }
    }
  } catch {
    dispatch({
      type: PUT_PRODUCT_SIDE_SHEET_FAILURE,
    });
  }
};