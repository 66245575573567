import DatePicker from 'react-multi-date-picker';
import 'react-multi-date-picker/styles/layouts/mobile.css';

import FormControl from '@mui/material/FormControl';
import { isEmpty, isNil } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { dateRangeCalendarIcon, dateRangePickerStyle } from './questionnaireBuilderStyles';

export default function DatepickerResponse(props) {
  return (
    <div className="position-relative">
      <FormControl sx={(theme) => dateRangePickerStyle(theme, isEmpty(props?.value))}>
        <DatePicker
          {...props}
          value={props?.value && JSON.parse(props.value)}
          onChange={(e) => (isNil(e) ? '' : props.onChange(JSON.stringify(e)))}
          placeholder={props?.placeholder}
          format="MM/DD/YYYY"
          editable={false}
          range
        />
        <FontAwesomeIcon icon={faCalendarDay} style={dateRangeCalendarIcon()} className='placeHolder'/>
      </FormControl>
    </div>
  );
}
