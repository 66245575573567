import { isNil } from 'lodash';

export const getVendorProductContactBody = (customerVendorId, productVendorId) => {
  return {
    intent: '',
    fields: ['ContactType', 'FirstName', 'LastName', 'Title', 'Email', 'CellPhone', 'OfficePhone', 'IsTeamContact', 'FullName'],
    criterion: [
      {
        field: 'CustomerVendorId',
        operator: isNil(customerVendorId) ? 'isnull' : '=',
        value: isNil(customerVendorId) ? '' : customerVendorId,
      },
      {
        field: 'ProductVendorId',
        operator: isNil(productVendorId) ? 'isnull' : '=',
        value: isNil(productVendorId) ? '' : productVendorId,
      },
    ],
    sort: [
      {
        field: 'ContactType',
        order: 'ASC',
      },
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getIncidentNotificationBody = () => {
  return {
    intent: '',
    fields: ['ClientOrgId', 'Title', 'Description', 'Reference', 'UserFirstName', 'UserLastName', 'InsertTimeStamp'],
    criterion: [],
    sort: [
      {
        field: 'InsertTimeStamp',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
