import { Avatar, Box, Button, Typography, Tooltip } from '@mui/material';
import LogoutButton from '../LogOut/LogoutButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  userNameStyles,
  userActionsBtnWrapper,
  userActionsBtn,
  userInfoBoxStyle,
  userEmailStyles,
  userNameInfoWrapper,
  userNameBox,
  userNameAvtar,
  linkStyles,
  linkBoxStyles,
  iconStyles,
  userInfoWrapper,
  avatarStyles,
} from './NavBarStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuickToggles from './QuickToggles';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  faCircleQuestion,
  faBell
} from '@fortawesome/pro-regular-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Notifications from 'pages/Notifications';
import { searchEventNotificationListCount } from 'actions/userProfile';

const UserInfo = () => {
  
  const dispatch = useDispatch();

  const details = useSelector((state) => state.session.details);
  const eventNotificationListCount = useSelector((state) => state.userReducer.eventNotificationListCount);

  const history = useHistory();
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [displayUserName, setDisplayUserName] = useState('');
  const [openNotifications, setOpenNotifications] = useState(false);

  let quickToggle = [
    {
      icon: faCircleQuestion,
      onClick: () => history.push('/support'),
      showBadge: false,
    },
    {
      icon: faBell,
      onClick: () => setOpenNotifications(true),
      showBadge: true,
    },
  ];

  useEffect(() => {
    dispatch(searchEventNotificationListCount());
  }, [dispatch])

  useEffect(() => {
    let userName = '';
    const { FirstName, LastName, UserEmail } = details;

    if (FirstName || LastName) {
      if (FirstName) {
        userName += FirstName.trim()[0];
      }
      if (LastName) {
        userName += LastName.trim()[0];
      }
    } else if (UserEmail) {
      userName += UserEmail.trim()[0];
    }

    setDisplayUserName(userName);
  }, [details?.FirstName, details?.LastName, details?.UserEmail]);

  const userNameHover = () => {
    setShowUserDetails(true);
  };

  const handleUserNameClose = () => {
    setShowUserDetails(false);
  };

  const onClickSupport = () => {
    handleUserNameClose();
    history.push('/support');
  };

  const onClickPreferences = () => {
    handleUserNameClose();
    history.push('/communication-preferences');
  };

  return (
    <Box sx={userInfoBoxStyle}>
      <QuickToggles list={quickToggle} count={eventNotificationListCount} />
      <Box sx={userNameAvtar} onMouseEnter={userNameHover} onMouseLeave={handleUserNameClose}>
        <Avatar sx={(theme) => avatarStyles(theme)}>{displayUserName}</Avatar>
        {showUserDetails && (
          <Box sx={(theme) => userInfoWrapper(theme)}>
            <Box sx={userNameInfoWrapper}>
              <Avatar sx={(theme) => avatarStyles(theme)}>{displayUserName}</Avatar>
              <Box sx={userNameBox}>
                <Typography variant="h3" sx={userNameStyles}>
                  {details?.DisplayName}
                </Typography>
                <Tooltip title={details?.UserEmail}>
                  <Typography variant="body2" sx={userEmailStyles}>
                    {details?.UserEmail}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
            <Button className="btn" disableRipple disableElevation sx={linkBoxStyles} onClick={onClickSupport}>
              <FontAwesomeIcon style={iconStyles()} icon={faCircleQuestion} />
              <Typography sx={linkStyles}>Support Page</Typography>
            </Button>
            <Button className="btn" disableRipple disableElevation sx={linkBoxStyles} onClick={onClickPreferences}>
              <FontAwesomeIcon style={iconStyles()} icon={faUser} />
              <Typography sx={linkStyles}>Preferences</Typography>
            </Button>
            <Box sx={userActionsBtnWrapper}>
              <Box sx={userActionsBtn}>
                <LogoutButton handleUserNameClose={handleUserNameClose} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {openNotifications && <Notifications open={openNotifications} onClose={() => setOpenNotifications(false)} />}
    </Box>
  );
};

export default UserInfo;
