import { POST_DYNAMIC_VENDOR_ROSTER_FAILURE, POST_DYNAMIC_VENDOR_ROSTER_LOADING, POST_DYNAMIC_VENDOR_ROSTER_SUCCESS } from 'actions/dynamicVendorRoster';

const initialState = {
  loading: false,
  quicksightDashboard: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case POST_DYNAMIC_VENDOR_ROSTER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_DYNAMIC_VENDOR_ROSTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        quicksightDashboard: payload?.Data,
      };
    }

    case POST_DYNAMIC_VENDOR_ROSTER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
