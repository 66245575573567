import {
  SEARCH_ASSESSMENT_ACTIVITY_FAILURE,
  SEARCH_ASSESSMENT_ACTIVITY_LOADING,
  SEARCH_ASSESSMENT_ACTIVITY_SUCCESS,
  SEARCH_ASSESSMENT_AGE_LOADING,
  SEARCH_ASSESSMENT_AGE_SUCCESS,
  SEARCH_ASSESSMENT_AGE_FAILURE,
  SEARCH_ASSESSMENT_METRICS_FAILURE,
  SEARCH_ASSESSMENT_METRICS_LOADING,
  SEARCH_ASSESSMENT_METRICS_SUCCESS,
  SEARCH_ASSESSMENT_RISK_RATING_LOADING,
  SEARCH_ASSESSMENT_RISK_RATING_SUCCESS,
  SEARCH_ASSESSMENT_RISK_RATING_FAILURE,
  SEARCH_ESCALATED_ASSESSMENT_LIST_LOADING,
  SEARCH_ESCALATED_ASSESSMENT_LIST_SUCCESS,
  SEARCH_ESCALATED_ASSESSMENT_LIST_FAILURE
} from 'actions/commandCenter';
import { isEmpty } from 'lodash';
import { getModifiedList } from 'utils/assessmentsOverviewUtils';

const initialState = {
  loading: false,
  loadingAge: false,
  loadingMetrics: false,
  loadingEscalatedAssessments: false,
  loadingRiskRating: false,
  assessmentActivities: [],
  assessmentAges: [],
  assessmentMetrics: {},
  riskRatings: [],
  escalatedAssessments: [],
  totalCountOfRiskRating: '',
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_ASSESSMENT_ACTIVITY_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_ACTIVITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentActivities: getModifiedList(payload?.Data?.Rows),
      };
    }

    case SEARCH_ASSESSMENT_ACTIVITY_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentActivities: [],
      };
    }

    case SEARCH_ASSESSMENT_AGE_LOADING: {
      return {
        ...state,
        loadingAge: true,
      };
    }

    case SEARCH_ASSESSMENT_AGE_SUCCESS: {
      let agesFlat = payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0];

      const ages = [{
        name: 'Waiting on Vendor Response',
        colorByPoint: false,
        color: '#F78D2A',
        data: [agesFlat?.CountOfWaitingOnVendorResponse | 0, 0, 0, 0]
      }, {
        name: 'CORL Reviewing',
        colorByPoint: false,
        color: '#145091',
        data: [agesFlat?.CountOfCorlReviewing | 0, 0, 0, 0]
      }, {
        name: 'Assessed',
        colorByPoint: false,
        color: '#145091',
        data: [0, agesFlat?.CountOfCompletedAssessedLessThanYear | 0, agesFlat?.CountOfCompletedAssessedOneTwoYear | 0, agesFlat?.CountOfCompletedAssessedGreaterThanTwoYear | 0]
      }]

      return {
        ...state,
        loadingAge: false,
        assessmentAges: ages,
      };
    }

    case SEARCH_ASSESSMENT_AGE_FAILURE: {
      return {
        ...state,
        loadingAge: false,
        assessmentAges: [],
      };
    }

    case SEARCH_ASSESSMENT_RISK_RATING_LOADING: {
      return {
        ...state,
        loadingRiskRating: true,
      };
    }

    case SEARCH_ASSESSMENT_RISK_RATING_SUCCESS: {
      let riskRatingList = [];
      if (!isEmpty(payload?.Data?.Rows)) {
        riskRatingList = [
          {
            name: 'Very High',
            y: payload?.Data?.Rows[0].PercentageOfVeryHighRiskRating,
          },
          {
            name: 'High',
            y: payload?.Data?.Rows[0].PercentageOfHighRiskRating,
          },
          {
            name: 'Medium',
            y: payload?.Data?.Rows[0].PercentageOfMediumRiskRating,
          },
          {
            name: 'Low',
            y: payload?.Data?.Rows[0].PercentageOfLowRiskRating,
          },
          {
            name: 'Managed',
            y: payload?.Data?.Rows[0].PercentageOfManagedRiskRating,
          },
          // {
          //     name: 'N/A',
          //     y: payload?.Data?.Rows[0]?.PercentageOfNARating
          // }
        ];
      }

      return {
        ...state,
        loadingRiskRating: false,
        riskRatings: riskRatingList,
        totalCountOfRiskRating: payload?.Data?.Rows.length > 0 && payload?.Data?.Rows[0].TotalCountOfRiskRating,
      };
    }

    case SEARCH_ASSESSMENT_RISK_RATING_FAILURE: {
      return {
        ...state,
        loadingRiskRating: false,
        riskRatings: [],
        totalCountOfRiskRating: '',
      };
    }

    case SEARCH_ASSESSMENT_METRICS_LOADING: {
      return {
        ...state,
        loadingMetrics: true,
      };
    }

    case SEARCH_ASSESSMENT_METRICS_SUCCESS: {
      let metricsFlat = {};
      for (let i = 0; i < payload?.Data?.Rows?.length; i++) {
        Object.assign(metricsFlat, payload?.Data?.Rows[i]);
      }

      return {
        ...state,
        loadingMetrics: false,
        assessmentMetrics: metricsFlat,
      };
    }

    case SEARCH_ASSESSMENT_METRICS_FAILURE: {
      return {
        ...state,
        loadingMetrics: false,
        assessmentMetrics: {},
      };
    }

    case SEARCH_ESCALATED_ASSESSMENT_LIST_LOADING: {
      return {
        ...state,
        loadingEscalatedAssessments: true,
      };
    }

    case SEARCH_ESCALATED_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        loadingEscalatedAssessments: false,
        escalatedAssessments: getModifiedList(payload?.Data?.Rows),
      };
    }

    case SEARCH_ESCALATED_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        loadingEscalatedAssessments: false,
        escalatedAssessments: [],
      };
    }

    default:
      return state;
  }
}
