export const calendarIcon = () => {
  return {
    left: '190px',
    top: '23px',
    position: 'absolute',
  };
};

export const datePickerStyle = () => {
  return {
    backgroundColor: 'white',
    height: '62px',
    borderRadius: '6px',
    fontSize: '14px',
    padding: '22px 16px',
    width: '220px',
    border: 'unset',
    cursor: 'pointer',
  };
};
