import React from "react";
import MuiPhoneNumber from 'material-ui-phone-number';
import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import { formLabelFieldStyles, countryCodeComponentStyles } from "./countryCodeStyles";

const CountryCode = (props) => {
  
    return (
      <FormControl error={props.error} disabled={props.disabled}>
        {props.label && <FormLabel sx={formLabelFieldStyles}>{props.label}</FormLabel>}
        <MuiPhoneNumber 
          value={props.value}
          onChange={props.onChange}
          defaultCountry={'us'} 
          countryCodeEditable={false}
          disableAreaCodes={true}
          sx={() => countryCodeComponentStyles(props.disabled)}
          readOnly={true}
          disabled={props.disabled}
          onKeyPress={(e) => {e.preventDefault();}}
          InputProps={{ disableUnderline: true }}
        />
        {props?.error && <FormHelperText>{props?.error}</FormHelperText>}
      </FormControl>
    );
  };

export default CountryCode;
