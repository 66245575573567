export const searchVendorListBody = () => {
  return {
    intent: '',
    fields: ['VendorOrgId', 'Active', 'VendorOrgName', 'RegisteredBusinessAddressCity'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
