import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CheckboxComponent = ({ input, label, ...props }) => (
  <div>
    <FormControlLabel  control={<Checkbox checked={!!input.value} disabled={props.disabled} color="primary" onChange={input.onChange} className="checkbox-item"/>} label={label} />
  </div>
);
export default CheckboxComponent;
