import axios from 'axios';
import { setErrors } from './errorHandler';
import { addUserRoleToUserBody, searchUserRoleListOfUserBody, searchUserRolesListBody } from '../utils/clientProfileUsersUtils';
import { NO_DATA_FOUND } from '../constants/errorMessage';

export const SEARCH_USER_ROLE_LIST_LOADING = '@@client/SEARCH_USER_ROLE_LIST_LOADING';
export const SEARCH_USER_ROLE_LIST_SUCCESS = '@@client/SEARCH_USER_ROLE_LIST_SUCCESS';
export const SEARCH_USER_ROLE_LIST_FAILURE = '@@client/SEARCH_USER_ROLE_LIST_FAILURE';

export const searchUserRoleList =
  (searchText = '') =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_USER_ROLE_LIST_LOADING,
    });

    const body = searchUserRolesListBody(searchText);

    try {
      const response = await axios.post(`/userrole/search`, body);
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
        dispatch({
          type: SEARCH_USER_ROLE_LIST_FAILURE,
        });
        dispatch(setErrors(errors));
      } else {
        dispatch({
          type: SEARCH_USER_ROLE_LIST_SUCCESS,
          payload: response?.data,
        });
      }
    } catch {
      dispatch({
        type: SEARCH_USER_ROLE_LIST_FAILURE,
      });
    }
  };

export const SEARCH_USER_ROLE_LIST_OF_USER_LOADING = '@@clientProfileUsers/SEARCH_USER_ROLE_LIST_OF_USER_LOADING';
export const SEARCH_USER_ROLE_LIST_OF_USER_SUCCESS = '@@clientProfileUsers/SEARCH_USER_ROLE_LIST_OF_USER_SUCCESS';
export const SEARCH_USER_ROLE_LIST_OF_USER_FAILURE = '@@clientProfileUsers/SEARCH_USER_ROLE_LIST_OF_USER_FAILURE';

export const searchUserRoleListOfUser = (userId) => async (dispatch) => {
  dispatch({
    type: SEARCH_USER_ROLE_LIST_OF_USER_LOADING,
  });

  const body = searchUserRoleListOfUserBody(userId);

  try {
    const response = await axios.post(`/userrole/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_USER_ROLE_LIST_OF_USER_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_USER_ROLE_LIST_OF_USER_SUCCESS,
        payload: response?.data,
      });
    }
  } catch {
    dispatch({
      type: SEARCH_USER_ROLE_LIST_OF_USER_FAILURE,
    });
  }
};

export const ADD_USER_ROLE_TO_USER_LOADING = '@@clientProfileUsers/ADD_USER_ROLE_TO_USER_LOADING';
export const ADD_USER_ROLE_TO_USER_SUCCESS = '@@clientProfileUsers/ADD_USER_ROLE_TO_USER_SUCCESS';
export const ADD_USER_ROLE_TO_USER_FAILURE = '@@clientProfileUsers/ADD_USER_ROLE_TO_USER_FAILURE';

export const addUserRoleToUser = (userRoleId, userId, oldUserRoleId) => async (dispatch) => {
  dispatch({
    type: ADD_USER_ROLE_TO_USER_LOADING,
  });

  const body = addUserRoleToUserBody(userRoleId, userId, oldUserRoleId);

  try {
    const response = await axios.post(`/userrole/${userRoleId}/user/association`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: ADD_USER_ROLE_TO_USER_FAILURE,
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: ADD_USER_ROLE_TO_USER_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchUserRoleListOfUser(userId));
    }
  } catch {
    dispatch({
      type: ADD_USER_ROLE_TO_USER_FAILURE,
    });
  }
};
