import axios from 'axios';
import { setErrors } from './errorHandler';
import { searchClientUsersBody } from '../utils/clientProfileUsersUtils';
import { NO_DATA_FOUND } from '../constants/errorMessage';
import { CLIENT_APP_ID, ORG_ID, SEND_EMAIL, USER_ID } from '../constants/users';
import { ADMIN_ROLE, USERS_TAB_OPTIONS } from 'pages/ClientProfile/Users/constants';

export const SEARCH_CLIENT_USERS_LOADING = '@@clientProfileUsers/SEARCH_CLIENT_USERS_LOADING';
export const SEARCH_CLIENT_USERS_SUCCESS = '@@clientProfileUsers/SEARCH_CLIENT_USERS_SUCCESS';
export const SEARCH_CLIENT_USERS_FAILURE = '@@clientProfileUsers/SEARCH_CLIENT_USERS_FAILURE';

export const searchClientUsers = (searchValue = '', callback) => async (dispatch) => {
    dispatch({
      type: SEARCH_CLIENT_USERS_LOADING,
    });

    var body = searchClientUsersBody(searchValue);

    try {
      const response = await axios.post(`/app/user/search`, body);
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
        dispatch({
          type: SEARCH_CLIENT_USERS_FAILURE,
        });
        dispatch(setErrors(errors));
      } else {
        dispatch({
          type: SEARCH_CLIENT_USERS_SUCCESS,
          payload: response?.data,
        });
      if(callback) {
        callback(response?.data?.Data?.Rows)
      }
      }
    } catch {
      dispatch({
        type: SEARCH_CLIENT_USERS_FAILURE,
      });
    }
};

export const ADD_CLIENT_USER_LOADING = '@@client/ADD_CLIENT_USER_LOADING';
export const ADD_CLIENT_USER_SUCCESS = '@@client/ADD_CLIENT_USER_SUCCESS';
export const ADD_CLIENT_USER_FAILURE = '@@client/ADD_CLIENT_USER_FAILURE';

export const addClientUser = (formData) => async (dispatch) => {
  dispatch({
    type: ADD_CLIENT_USER_LOADING,
  });

  let tempFormData = {
    "UserId": USER_ID,
    "UserEmail": formData?.UserEmail,
    "FirstName": formData?.FirstName,
    "LastName": formData?.LastName,
    "UserContact": {
      "Title": formData?.Title,
      "CellPhoneCountryCode": formData?.CellPhoneCountryCode,
      "MainCellPhone": formData?.MainCellPhone,
      "CellPhoneExtension": formData?.CellPhoneExtension,
      "OfficePhoneCountryCode": formData?.OfficePhoneCountryCode,
      "MainOfficePhone": formData?.MainOfficePhone,
      "OfficePhoneExtension": formData?.OfficePhoneExtension,
    },
    "UserGroupId": formData?.UserGroupId,
    "IsAdmin": formData?.IsAdmin,
    "AppId": CLIENT_APP_ID,
    "OrgId": ORG_ID,
    "SendEmail": SEND_EMAIL
  }

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`/app/user`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_CLIENT_USER_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_CLIENT_USER_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchClientUsers());
    }
  } catch {
    dispatch({
      type: ADD_CLIENT_USER_FAILURE,
    });
    dispatch(searchClientUsers());
  }
};

export const UPDATE_CLIENT_USER_LOADING = '@@client/UPDATE_CLIENT_USER_LOADING';
export const UPDATE_CLIENT_USER_SUCCESS = '@@client/UPDATE_CLIENT_USER_SUCCESS';
export const UPDATE_CLIENT_USER_FAILURE = '@@client/UPDATE_CLIENT_USER_FAILURE';

export const updateClientUser = (formData, oldUserRoleId) => async (dispatch) => {
  dispatch({
    type: UPDATE_CLIENT_USER_LOADING,
  });

  let tempFormData = {
    UserId: formData?.UserId,
    UserEmail: formData?.UserEmail,
    FirstName: formData?.FirstName,
    LastName: formData?.LastName,
    "UserContact": {
      "Title": formData?.Title,
      "CellPhoneCountryCode": formData?.CellPhoneCountryCode,
      "MainCellPhone": formData?.MainCellPhone,
      "CellPhoneExtension": formData?.CellPhoneExtension,
      "OfficePhoneCountryCode": formData?.OfficePhoneCountryCode,
      "MainOfficePhone": formData?.MainOfficePhone,
      "OfficePhoneExtension": formData?.OfficePhoneExtension,
      "ContactId": formData?.ContactId ?? 0,
    },
    "UserGroupId": formData?.UserGroupId,
    "IsAdmin": formData?.IsAdmin,
    "OldUserRoleId": oldUserRoleId,
    "AppId": CLIENT_APP_ID,
    "OrgId": ORG_ID,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.put(`app/user/${formData?.UserId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPDATE_CLIENT_USER_FAILURE,
      });
    } else {
      dispatch({
        type: UPDATE_CLIENT_USER_SUCCESS,
        payload: {
          response: response?.data,
        },
      });
      dispatch(searchClientUsers('', (data) => {
        const updatedData = data.map(item => ({ ...item, IsAdmin: item.UserRoleName?.toLowerCase().endsWith(ADMIN_ROLE) }));
        dispatch(
          setSelectedUser(
            updatedData?.find((item) => item.UserId === formData?.UserId, )
          )
        );
      }));
    }
  } catch {
    dispatch({
      type: UPDATE_CLIENT_USER_FAILURE,
    });
  }
};

export const REMOVE_CLIENT_USER_LOADING = '@@client/REMOVE_CLIENT_USER_LOADING';
export const REMOVE_CLIENT_USER_SUCCESS = '@@client/REMOVE_CLIENT_USER_SUCCESS';
export const REMOVE_CLIENT_USER_FAILURE = '@@client/REMOVE_CLIENT_USER_FAILURE';

export const removeClientUser = (userId, userRoleId) => async (dispatch) => {
  dispatch({
    type: REMOVE_CLIENT_USER_LOADING,
  });

  let tempFormData = {
    UserId: userId,
    Roles: [{ UserRoleId: userRoleId }],
    AppId: CLIENT_APP_ID,
    OrgId: ORG_ID,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`/app/user/${userId}/action/unassign`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: REMOVE_CLIENT_USER_FAILURE,
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: REMOVE_CLIENT_USER_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchClientUsers());
    }
  } catch {
    dispatch({
      type: REMOVE_CLIENT_USER_FAILURE,
    });
  }
};

export const SET_SELECTED_USER = '@@clientProfileUsers/SET_SELECTED_USER';

export const setSelectedUser =
  (user = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_USER,
      payload: user,
    });
  };

export const SET_SELECTED_INDEX = '@@user/SET_SELECTED_INDEX';

export const setSelectedIndex =
  (index = null) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_INDEX,
      payload: index,
    });
  };

export const SET_CLIENT_USER_FORM_EDITABLE = '@@clientProfileUsers/SET_CLIENT_USER_FORM_EDITABLE';

export const setClientUserFormEditable =
  (isEditable = false) =>
  async (dispatch) => {
    dispatch({
      type: SET_CLIENT_USER_FORM_EDITABLE,
      isEditable,
    });
  };

export const POST_RESEND_INVITE_TO_USER_LOADING = '@@users/POST_RESEND_INVITE_TO_USER_LOADING';
export const POST_RESEND_INVITE_TO_USER_SUCCESS = '@@users/POST_RESEND_INVITE_TO_USER_SUCCESS';
export const POST_RESEND_INVITE_TO_USER_FAILURE = '@@users/POST_RESEND_INVITE_TO_USER_FAILURE';

export const resendInviteToUser = (reqBody) => async (dispatch) => {
  dispatch({ type: POST_RESEND_INVITE_TO_USER_LOADING });

  const body = JSON.stringify(reqBody);
  try {
    let res = await axios.post(`/app/user/${reqBody.UserId}/action/sendemailinvite`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_RESEND_INVITE_TO_USER_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_RESEND_INVITE_TO_USER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_RESEND_INVITE_TO_USER_FAILURE,
      payload: [],
    });
  }
};

export const SET_USERS_TAB_VALUE = '@@users/SET_USERS_TAB_VALUE';

export const setUsersTabValue =
  (value = USERS_TAB_OPTIONS.USERS) =>
  async (dispatch) => {
    dispatch({
      type: SET_USERS_TAB_VALUE,
      payload: value,
    });
  };

export const SET_OPEN_USERS_SIDESHEET = '@@users/SET_OPEN_USERS_SIDESHEET';

export const setOpenUserSideSheet = ( value = false ) => async (dispatch) => {
  dispatch({
    type: SET_OPEN_USERS_SIDESHEET,
    payload: value
  });
}