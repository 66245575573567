import { isArray, isNil, isString } from "lodash";
import { escapeSpecialCharacters } from "./specialCharactersUtils";

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key])) {
      criterion.push({
        Field: key,
        Operator: 'contains',
        Value: escapeSpecialCharacters(searchData[key]),
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

const getMappedProductsSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key])) {
      criterion.push({
        Field: key,
        Operator: '=',
        Value: searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

export const getTableViewListBody = (searchData, pageNo, rowsPerPage) => {
  let criterion = [];
  if (!isNil(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    "fields": [
      "CustomerVendorId",
      "CustomerVendorLogId",
      "CustomerOrgId",
      "CustomerOrgName",
      "VendorOrgId",
      "VendorOrgName",
      "VendorAddressLine1",
      "VendorAddressLine2",
      "VendorAddressCity",
      "VendorAddressState",
      "VendorAddressCountry",
      "VendorWebsite",
      "IsTPIR",
      "VendorAlias",
      "EndOfRelationShipDate",
      "Status",
      "DynCustomerVendorId",
      "VendorIdForCustomer",
      "DunsNumber",
      "IsVerified",
      "DynVendorAccountId",
      "ProductVendorId",
      "CORLClearedStatus",
      "ClientVendorList"
      , "TotalCountOfVendors"
    ],
    criterion: criterion,
    sort: [
      {
        field: 'VendorOrgName',
        order: 'ASC',
      },
    ],
    page: pageNo,
    pageSize: rowsPerPage,
  };
};

export const searchVendorCatalogCardListBody = (searchData, pageNo, rowsPerPage) => {
  let criterion = [];
  if (!isNil(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    "fields": [
      "CustomerVendorId",
      "CustomerVendorLogId",
      "CustomerOrgId",
      "CustomerOrgName",
      "VendorOrgId",
      "VendorOrgName",
      "VendorAddressLine1",
      "VendorAddressLine2",
      "VendorAddressCity",
      "VendorAddressState",
      "VendorAddressCountry",
      "VendorWebsite",
      "DomainName",
      "IsTPIR",
      "VendorAlias",
      "EndOfRelationShipDate",
      "Status",
      "DynCustomerVendorId",
      "VendorIdForCustomer",
      "DunsNumber",
      "IsVerified",
      "DynVendorAccountId",
      "ProductVendorId",
      "CORLClearedStatus",
      "ClientVendorList",
      "TotalCountOfVendors",
    ],
    criterion: criterion,
    sort: [
      {
        field: 'VendorOrgName',
        order: 'ASC',
      },
    ],
    page: pageNo,
    pageSize: rowsPerPage,
  };
};

export const getAllProductsForVendorSearchBody = (searchData = {}) => {
  let criterion = [];
  if (!isNil(searchData)) {
    criterion = [...criterion, ...getMappedProductsSearchCriterion(searchData)];
  }
  return {
    "intent": "",
    "fields": [
      "ProductName",
      "ProductType",
      "ProductVendorRole",
      "ProductGroup",
      "CustomerVendorProduct",
      "ProductVendorId",
      "VendorOrgId",
      "CCDynVRASId",
      "Oem",
      "CORLClearedStatus"
    ],
    "criterion": criterion,
    "sort": [
      {
        "field": "ProductName",
        "order": "ASC"
      }
    ],
    "page": 0,
    "pageSize": 0
  }
}

export const getAssociateVendorClientOrgBody = (data) => {
  return {
    'VendorOrgId': data?.VendorOrgId ?? 0,
    'IsTPIR': data?.IsTPIR ?? 0,
  };
};

export const getAssociateProductToCustomerVendorBody = (data) => {
  return {
    'CustomerVendorId': data?.CustomerVendorId ?? 0,
    'ProductVendorId': data?.ProductVendorId ?? 0,
  };
};
