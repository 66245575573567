import axios from 'axios';
import { getApiDocumentationListSearchBody } from 'utils/apiDocumentationUtils';
import { getErrorObject } from 'utils/apiUtils';
import { setErrors } from './errorHandler';
import { NO_DATA_FOUND } from 'constants/errorMessage';

export const SEARCH_API_DOCUMENTATION_LIST_LOADING = '@apiDocumentation/SEARCH_API_DOCUMENTATION_LIST_LOADING';
export const SEARCH_API_DOCUMENTATION_LIST_SUCCESS = '@apiDocumentation/SEARCH_API_DOCUMENTATION_LIST_SUCCESS';
export const SEARCH_API_DOCUMENTATION_LIST_FAILURE = '@apiDocumentation/SEARCH_API_DOCUMENTATION_LIST_FAILURE';

export const getApiDocumentationList = () => async (dispatch) => {
    dispatch({ type: SEARCH_API_DOCUMENTATION_LIST_LOADING });
    try {
        const body = getApiDocumentationListSearchBody();
        const response = await axios.post(`grcapidocument/search`, body);
        if (response?.data?.ErrorCount > 0) {
            const errors = response?.data?.Errors.filter(
                (errorItem) => errorItem.Message !== NO_DATA_FOUND
            );
            dispatch({
                type: SEARCH_API_DOCUMENTATION_LIST_FAILURE,
                payload: [],
            });
            dispatch(setErrors(errors));
        } else {
            dispatch({
                type: SEARCH_API_DOCUMENTATION_LIST_SUCCESS,
                payload: response.data || [],
            });
        }
    } catch (err) {
        dispatch({
            type: SEARCH_API_DOCUMENTATION_LIST_FAILURE,
            payload: getErrorObject(err),
        });
    }
};

export const SEARCH_API_TOKEN_LOADING = '@apiDocumentation/SEARCH_API_TOKEN_LOADING';
export const SEARCH_API_TOKEN_SUCCESS = '@apiDocumentation/SEARCH_API_TOKEN_SUCCESS';
export const SEARCH_API_TOKEN_FAILURE = '@apiDocumentation/SEARCH_API_TOKEN_FAILURE';

export const getApIToken = (apiKey) => async (dispatch) => {
    dispatch({ type: SEARCH_API_TOKEN_LOADING });
    try {
        const body = {
            ApiKey: apiKey,
        }
        const response = await axios.post(`/public/api/token`, body);
        if (response?.data?.ErrorCount > 0) {
            const errors = response?.data?.Errors.filter(
                (errorItem) => errorItem.Message !== NO_DATA_FOUND
            );
            dispatch({
                type: SEARCH_API_TOKEN_FAILURE,
                payload: {},
            });
            dispatch(setErrors(errors));
        } else {
            dispatch({
                type: SEARCH_API_TOKEN_SUCCESS,
                payload: response.data || {},
            });
        }
    } catch (err) {
        dispatch({
            type: SEARCH_API_TOKEN_FAILURE,
            payload: getErrorObject(err),
        });
    }
};

export const SET_API_TOKEN = '@apiDocumentation/SET_API_TOKEN';

export const setApIToken = (val = "") => async (dispatch) => {
    dispatch({
        type: SET_API_TOKEN,
        payload: val
    });
}