export const getClientProfileCompanyContactsBody = () => {
  return {
    intent: '',
    fields: ['FirstName', 'LastName', 'Title', 'Email', 'CellPhone', 'OfficePhone', 'ContactId', 'ClientContactId', 'ClientOrgId', 'IsTeamContact', 'FullName'],
    criterion: [],
    sort: [
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchClientProfileCompanySpecificContactsBody = (searchValue) => {
  return {
    intent: '',
    fields: [
      'FirstName',
      'LastName',
      'Title',
      'Email',
      'ContactType',
      'CellPhone',
      'OfficePhone',
      'ContactId',
      'ClientContactId',
      'ClientOrgId',
      'IsTeamContact',
      'FullName',
    ],
    criterion: [
      {
        field: 'FullName',
        operator: 'startswith',
        value: searchValue,
      },
    ],
    sort: [
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchClientProfileCheckDuplicateEmailContactsBody = (searchValue) => {
  return {
    intent: '',
    fields: [
      'FirstName',
      'LastName',
      'Title',
      'Email',
      'ContactType',
      'CellPhone',
      'OfficePhone',
      'ContactId',
      'ClientContactId',
      'ClientOrgId',
      'IsTeamContact',
      'FullName',
    ],
    criterion: [
      {
        field: 'Email',
        operator: '=',
        value: searchValue,
      },
    ],
    sort: [
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
