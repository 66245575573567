import { isNil } from 'lodash';

export const getSlaInformationListSearchBody = (customerVendorId, productVendorId) => {
  return {
    intent: '',
    fields: [
      'ProductVendorId',
      'CustomerVendorProductId',
      'ProducVendorId',
      'SlaStatementId',
      'SlaText',
      'Indent',
      'DisplayOrder',
      'IncidentSlaSectionId',
      'TimeOffsetText',
      'ResponseTimeUnit',
      'ResponseEventText',
      'RequiresDocument',
      'VendorSlaResponseId',
      'ResponseTime',
      'QuestionResponseType',
      'QuestionResponseCategory',
      'QuestionResponseText',
      'ParentSlaStatementId',
      'ResponseCategoryId',
    ],
    criterion: [
      {
        field: 'CustomerVendorId',
        operator: isNil(customerVendorId) ? 'isnull' : '=',
        value: isNil(customerVendorId) ? '' : customerVendorId,
      },
      {
        field: 'ProductVendorId',
        operator: isNil(productVendorId) ? 'isnull' : '=',
        value: isNil(productVendorId) ? '' : productVendorId,
      },
    ],
    sort: [
      {
        field: 'DisplayOrder',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
