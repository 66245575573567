import {
  CLEAR_REMEDIATION_GUIDANCE_DETAILS,
  REMEDIATION_GUIDANCE_DETAILS_SEARCH_FAILURE,
  REMEDIATION_GUIDANCE_DETAILS_SEARCH_LOADING,
  REMEDIATION_GUIDANCE_DETAILS_SEARCH_SUCCESS,
  ACCEPT_REMEDIATION_GUIDANCE_FAILURE,
  ACCEPT_REMEDIATION_GUIDANCE_LOADING,
  ACCEPT_REMEDIATION_GUIDANCE_SUCCESS,
  REMEDIATION_GUIDANCE_SEARCH_FAILURE,
  REMEDIATION_GUIDANCE_SEARCH_LOADING,
  REMEDIATION_GUIDANCE_SEARCH_SUCCESS,
  SUBMIT_ALTERNATE_GUIDANCE_FAILURE,
  SUBMIT_ALTERNATE_GUIDANCE_LOADING,
  SUBMIT_ALTERNATE_GUIDANCE_SUCCESS,
  GET_REMEDIATION_GUIDANCE_STATUS_LOADING,
  GET_REMEDIATION_GUIDANCE_STATUS_SUCCESS,
  GET_REMEDIATION_GUIDANCE_STATUS_FAILURE,
  SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_LOADING,
  SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_SUCCESS,
  SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_FAILURE,
} from 'actions/remediationGuidance';
import { ERROR_MESSAGE, PROVIDE_ALTERNATE_GUIDANCE_SUCCESSFULLY } from 'constants/errorMessage';
import { isEmpty } from 'lodash';
import { ALTERNATE_REMEDIATION_GUIDANCE_SUBMITTED_SUCCESSFULLY, CORL_REMEDIATION_GUIDANCE_ACCEPTED_SUCCESSFULLY } from 'pages/RemediationGuidance/constants';
import { toast } from 'react-toastify';

const initialState = {
  loading: false,
  remediationListLoading: false,
  remediationGuidanceList: [],
  remediationGuidanceDetails: {},
  remediationGuidanceStatus: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REMEDIATION_GUIDANCE_SEARCH_LOADING: {
      return {
        ...state,
        remediationListLoading: true,
      };
    }
    case ACCEPT_REMEDIATION_GUIDANCE_LOADING:
    case GET_REMEDIATION_GUIDANCE_STATUS_LOADING:
    case SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case REMEDIATION_GUIDANCE_SEARCH_SUCCESS: {
      const alternateGuidance = payload.filter((item) => {
        return item.RemediationGuidance.length > 0;
      });
      const list = payload.map((item, index) => {
        return { ...item, RiskNumberValue: index + 1 };
      });
      return {
        ...state,
        remediationListLoading: false,
        remediationGuidanceList: list?.map((item) => ({
          ...item,
          Description: item?.RiskDescription?.replace(`${item?.ControlNumber} `, '')
        })),
        alternateGuidance: alternateGuidance,
      };
    }
    case ACCEPT_REMEDIATION_GUIDANCE_SUCCESS: {
      toast.success(CORL_REMEDIATION_GUIDANCE_ACCEPTED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case GET_REMEDIATION_GUIDANCE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        remediationGuidanceStatus: !isEmpty(payload) ? payload : {},
      };
    }
    case SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_SUCCESS: {
      toast.success(ALTERNATE_REMEDIATION_GUIDANCE_SUBMITTED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case REMEDIATION_GUIDANCE_SEARCH_FAILURE: {
      return {
        ...state,
        remediationListLoading: false,
        remediationGuidanceList: [],
        alternateGuidance: [],
      };
    }
    case ACCEPT_REMEDIATION_GUIDANCE_FAILURE:
    case GET_REMEDIATION_GUIDANCE_STATUS_FAILURE:
    case SUBMIT_ALTERNATE_REMEDIATION_GUIDANCE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case REMEDIATION_GUIDANCE_DETAILS_SEARCH_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case REMEDIATION_GUIDANCE_DETAILS_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        remediationGuidanceDetails: payload,
      };
    }
    case REMEDIATION_GUIDANCE_DETAILS_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SUBMIT_ALTERNATE_GUIDANCE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SUBMIT_ALTERNATE_GUIDANCE_SUCCESS: {
      toast.success(PROVIDE_ALTERNATE_GUIDANCE_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case SUBMIT_ALTERNATE_GUIDANCE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case CLEAR_REMEDIATION_GUIDANCE_DETAILS: {
      return {
        ...state,
        remediationGuidanceDetails: {},
      };
    }
    default:
      return state;
  }
}
