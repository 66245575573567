export const radioGroupLabel = () => {
  return {
    marginLeft: '-14px !important',
  };
};

export const radioGroupOptions = () => {
  return {
    display: 'flex',
    alignItems: 'center',
  };
};
