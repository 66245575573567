import {
  SET_VENDORS_AND_PRODUCTS_TAB_VALUE,
  SET_SELECTED_VENDOR,
  GET_VENDORS_LIST_FAILURE,
  GET_VENDORS_LIST_LOADING,
  GET_VENDORS_LIST_SUCCESS,
  PUT_VENDOR_SIDE_SHEET_LOADING,
  PUT_VENDOR_SIDE_SHEET_FAILURE,
  PUT_VENDOR_SIDE_SHEET_SUCCESS,
  DELETE_CLIENT_VENDOR_RELATIONSHIP_LOADING,
  DELETE_CLIENT_VENDOR_RELATIONSHIP_FAILURE,
  DELETE_CLIENT_VENDOR_RELATIONSHIP_SUCCESS,
  RESET_VENDORS_AND_PRODUCTS,
  SET_SELECTED_VENDOR_LOOKUP_DETAILS,
  SET_SELECTED_VENDOR_LOOKUP_INDEX,
  RESET_SELECTED_VENDOR_LOOKUP_DETAILS,
  SET_VENDORS_AND_PRODUCTS_MANUAL_ADD_VENDOR_BOX,
  SEARCH_VENDORS_LIST_LOADING,
  SEARCH_VENDORS_LIST_SUCCESS,
  SEARCH_VENDORS_LIST_FAILURE,
  ADD_VENDORS_TO_CLIENT_LOADING,
  ADD_VENDORS_TO_CLIENT_SUCCESS,
  ADD_VENDORS_TO_CLIENT_FAILURE,
  MANUAL_ADD_VENDORS_TO_CLIENT_LOADING,
  MANUAL_ADD_VENDORS_TO_CLIENT_SUCCESS,
  MANUAL_ADD_VENDORS_TO_CLIENT_FAILURE,
  POST_VENDOR_COMPANY_INFO_LOADING,
  POST_VENDOR_COMPANY_INFO_SUCCESS,
  POST_VENDOR_COMPANY_INFO_FAILURE,
  SEARCH_PRODUCTS_LIST_LOADING,
  SEARCH_PRODUCTS_LIST_SUCCESS,
  SEARCH_PRODUCTS_LIST_FAILURE,
  SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_LOADING,
  SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_SUCCESS,
  SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_FAILURE,
  SEARCH_PRODUCTS_LIST_OF_VENDOR_LOADING,
  SEARCH_PRODUCTS_LIST_OF_VENDOR_SUCCESS,
  SEARCH_PRODUCTS_LIST_OF_VENDOR_FAILURE,
  SET_EXPANDED_VENDOR,
  RESET_VENDOR_LIST,
  SET_SELECTED_PRODUCT,
  PUT_PRODUCT_SIDE_SHEET_FAILURE,
  PUT_PRODUCT_SIDE_SHEET_SUCCESS,
  PUT_PRODUCT_SIDE_SHEET_LOADING,
} from 'actions/vendorsAndProducts';
import { NO, YES } from 'constants/constants';
import {
  ADD_VENDOR_TO_CLIENT_SUCCESSFULLY,
  DELETED_SUCCESSFULLY,
  ERROR_MESSAGE,
  MANUAL_ADD_VENDOR_TO_CLIENT_SUCCESSFULLY,
  SAVED_SUCCESSFULLY,
} from 'constants/errorMessage';
import { format, isValid } from 'date-fns';
import { isEmpty, isNil, uniqBy } from 'lodash';
import { VENDORS_AND_PRODUCTS_TAB_OPTIONS, VENDOR_ACTIVE, VENDOR_INACTIVE } from 'pages/VendorsAndProducts/constants';
import { toast } from 'react-toastify';
import { convertToLocalDateTime } from 'utils/dateAndTimeUtils';

const initialState = {
  loading: false,
  productsLoading: false,
  searchVendorsAndProducts: false,
  vendorsAndProductsTabValue: VENDORS_AND_PRODUCTS_TAB_OPTIONS.VENDORS,
  selectedVendor: {},
  vendorsList: [],
  exportVendorInventory: [],
  selectedVendorLookupDetails: {},
  selectedVendorLookupIndex: null,
  showManualAddVendor: false,
  vendorSearchList: [],
  vendorCompanyInfo: {},
  productList: [],
  vendorLookup: [],
  productLookup: [],
  productsWithinVendor: [],
  expandedVendorId: null,
  selectedProduct: {},
};
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_VENDORS_AND_PRODUCTS_TAB_VALUE: {
      return {
        ...state,
        vendorsAndProductsTabValue: payload || VENDORS_AND_PRODUCTS_TAB_OPTIONS.VENDORS,
      };
    }
    case GET_VENDORS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_VENDORS_LIST_SUCCESS: {
      const exportVendorInventory = [];
      if (!isEmpty(payload)) {
        payload?.forEach((item) => {
          exportVendorInventory.push({
            vendorOrgId: item?.VendorOrgId,
            accountId: item?.DynVendorAccountId,
            vendorName: item?.VendorOrgName,
            vendorUrl: item?.VendorWebsite,
            dunsNumber: item?.DunsNumber,
            vendorCorrelationId: item?.VendorIdForCustomer,
            alias: item?.VendorAlias,
            isTPIR: item?.IsTPIR === 0 ? NO : YES,
            endOfRelationshipDate:
              !isNil(item?.EndOfRelationShipDate) && isValid(new Date(item?.EndOfRelationShipDate))
                ? convertToLocalDateTime(new Date(item?.EndOfRelationShipDate), 'M/dd/yyyy')
                : '',
            isVendorVerified: item?.IsVerified,
          });
        });
      }

      return {
        ...state,
        loading: false,
        vendorsList: payload,
        exportVendorInventory: exportVendorInventory,
      };
    }
    case GET_VENDORS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorsList: [],
      };
    }
    case SET_SELECTED_VENDOR: {
      return {
        ...state,
        selectedVendor: {
          ...payload,
          EndOfRelationShipDate:
            isNil(payload?.EndOfRelationShipDate) || isEmpty(payload?.EndOfRelationShipDate)
              ? ''
              : format(new Date(payload?.EndOfRelationShipDate), 'MM/dd/yyyy'),
        },
      };
    }
    case PUT_VENDOR_SIDE_SHEET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_VENDOR_SIDE_SHEET_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_VENDOR_SIDE_SHEET_SUCCESS: {
      toast.success(SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_CLIENT_VENDOR_RELATIONSHIP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_CLIENT_VENDOR_RELATIONSHIP_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_CLIENT_VENDOR_RELATIONSHIP_SUCCESS: {
      toast.success(DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case RESET_VENDORS_AND_PRODUCTS: {
      return {
        ...state,
        loading: false,
        vendorsAndProductsTabValue: VENDORS_AND_PRODUCTS_TAB_OPTIONS.VENDORS,
        vendorsList: [],
      };
    }
    case SET_SELECTED_VENDOR_LOOKUP_INDEX: {
      return {
        ...state,
        searchVendorsAndProducts: false,
        selectedVendorLookupIndex: payload,
      };
    }
    case SET_SELECTED_VENDOR_LOOKUP_DETAILS: {
      return {
        ...state,
        searchVendorsAndProducts: false,
        selectedVendorLookupDetails: payload,
      };
    }
    case RESET_SELECTED_VENDOR_LOOKUP_DETAILS: {
      return {
        ...state,
        searchVendorsAndProducts: false,
        selectedVendorLookupDetails: {},
        selectedVendorLookupIndex: null,
        vendorSearchList: [],
        showManualAddVendor: false,
      };
    }
    case SET_VENDORS_AND_PRODUCTS_MANUAL_ADD_VENDOR_BOX: {
      return {
        ...state,
        searchVendorsAndProducts: false,
        showManualAddVendor: payload,
      };
    }
    case SEARCH_VENDORS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_VENDORS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorSearchList: payload,
      };
    }
    case SEARCH_VENDORS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_VENDORS_TO_CLIENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_VENDORS_TO_CLIENT_SUCCESS: {
      toast.success(ADD_VENDOR_TO_CLIENT_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_VENDORS_TO_CLIENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case MANUAL_ADD_VENDORS_TO_CLIENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case MANUAL_ADD_VENDORS_TO_CLIENT_SUCCESS: {
      toast.success(MANUAL_ADD_VENDOR_TO_CLIENT_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case MANUAL_ADD_VENDORS_TO_CLIENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case POST_VENDOR_COMPANY_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_VENDOR_COMPANY_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorCompanyInfo: payload?.Data,
      };
    }
    case POST_VENDOR_COMPANY_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_SUCCESS: {
      const vendorLookup = [];
      const productLookup = [];
      payload.forEach((item) => {
        vendorLookup.push({
          label: item.OemOrgName,
          value: item.OemOrgId.toString(),
        });
      });
      payload.map((item) =>
        productLookup.push({
          label: item.ProductName,
          value: item.ProductId.toString(),
        })
      );
      return {
        ...state,
        loading: false,
        vendorLookup: uniqBy(vendorLookup, (item) => item.value),
        productLookup: productLookup,
      };
    }
    case SEARCH_CUSTOMER_VENDOR_PRODUCT_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorLookup: [],
        productLookup: [],
      };
    }
    case SEARCH_PRODUCTS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_PRODUCTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productList: payload,
      };
    }
    case SEARCH_PRODUCTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        productList: [],
      };
    }
    case SEARCH_PRODUCTS_LIST_OF_VENDOR_LOADING: {
      return {
        ...state,
        productsLoading: true,
      };
    }
    case SEARCH_PRODUCTS_LIST_OF_VENDOR_SUCCESS: {
      return {
        ...state,
        productsLoading: false,
        productsWithinVendor: payload,
      };
    }
    case SEARCH_PRODUCTS_LIST_OF_VENDOR_FAILURE: {
      return {
        ...state,
        productsLoading: false,
        productsWithinVendor: [],
      };
    }
    case SET_EXPANDED_VENDOR: {
      return {
        ...state,
        expandedVendorId: payload,
        productsWithinVendor: [],
      };
    }
    case RESET_VENDOR_LIST: {
      return {
        ...state,
        vendorsList: [],
      };
    }

    case SET_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProduct: {
          ...payload,
          Status: isNil(payload?.EndOfRelationShipDate) || new Date(payload?.EndOfRelationShipDate) > new Date() ? VENDOR_ACTIVE : VENDOR_INACTIVE,          
          EndOfRelationShipDate:
            isNil(payload?.EndOfRelationShipDate) || isEmpty(payload?.EndOfRelationShipDate)
              ? ''
              : format(new Date(payload?.EndOfRelationShipDate), 'MM/dd/yyyy'),
        },
      };
    }

    case PUT_PRODUCT_SIDE_SHEET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_PRODUCT_SIDE_SHEET_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_PRODUCT_SIDE_SHEET_SUCCESS: {
      toast.success(SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
