const required = (v) => (v ? undefined : '* Required');

export const incidentNotificationFields = [
  {
    label: 'Title',
    placeholder: 'Title',
    name: 'Title',
    tstype: 'text',
    variant: 'standard',
    size: 'small',
    multiline: true,
    minRows: 1,
    validate: [required],
  },
  {
    label: 'Description',
    placeholder: 'Description',
    name: 'Description',
    tstype: 'text',
    variant: 'standard',
    multiline: true,
    minRows: 1,
    validate: [required],
  },
  {
    label: 'Reference',
    placeholder: 'Please enter any urls that provide more information about the incident',
    name: 'Reference',
    tstype: 'text',
    variant: 'standard',
    multiline: true,
    minRows: 4,
  },
];
