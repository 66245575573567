export const characterCountStyles = (theme) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '12px',
    marginTop: '4px',
    color: `${theme.palette.grey[600]}`,
  };
};
export const helpTextStyle = (theme) => {
  return {
    color: `${theme.palette.primary.contrastText}`,
    cursor: "pointer",
    margin: "16px 0 0 4px",
  };
};
export const fieldLabelStyles = (theme, isDisabled) => {
  let color = `${theme.palette.grey[600]}`;

  if (isDisabled) {
    color = `${theme.palette.grey[500]}`;
  }

  return {
    margin: '-7px 0 5px 0',
    color: color,
  };
};