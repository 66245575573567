// src/auth/protected-route.js

import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from '../components/index';
import { useSelector } from 'react-redux';
import { ROUTE_CLAIMS_LIST } from 'constants/userClaims';
import { hasClaims } from 'hooks/useHasClaims';
import { isEmpty, isUndefined } from 'lodash';
import AccessDenied from 'components/AccessDenied';
import { EXECUTIVE_SUMMARY, TPIR_SUBSCRIBED } from 'constants/constants';

const ProtectedRoute = ({ component, path, ...args }) => {
  const userClaimList = useSelector((state) => state?.session?.details?.FeatureActionClaimList);
  const latestContractConfiguration = useSelector((state) => state.client.latestContractConfiguration);
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    const currentRoute = ROUTE_CLAIMS_LIST.find((item) => path === item.route);
    if (!isEmpty(currentRoute) && currentRoute?.claims?.length > 0 && !isUndefined(userClaimList)) {
      if (!hasClaims(currentRoute?.claims, userClaimList, currentRoute?.shouldHaveAllClaims)) {
        setAccessDenied(true);
      }
    }
  }, [userClaimList, path]);

  useEffect(() => {
    const currentRoute = ROUTE_CLAIMS_LIST.find((item) => path === item.route);
    if (!isEmpty(currentRoute) && !isUndefined(latestContractConfiguration) && !isEmpty(latestContractConfiguration)) {
      if ((currentRoute?.isTPIRSubscribedCheck && latestContractConfiguration?.IsTPIRSubscribed === TPIR_SUBSCRIBED.NO)
        || (currentRoute?.isExecutiveSummaryCheck && latestContractConfiguration?.ExecutiveSummary === EXECUTIVE_SUMMARY.NO)) {
        setAccessDenied(true);
      }
    }
  }, [latestContractConfiguration, path]);

  if (accessDenied) {
    return <Route component={AccessDenied} path={path} {...args} />;
  }

  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <Loading />,
      })}
      path={path}
      {...args}
    />
  );
};

export default ProtectedRoute;
