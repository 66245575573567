import {
  SET_INCIDENT_ALERT_POPUP,
  SEARCH_INCIDENT_NOTIFICATION_LOADING,
  SEARCH_INCIDENT_NOTIFICATION_FAILURE,
  SEARCH_INCIDENT_NOTIFICATION_SUCCESS,
  POST_INCIDENT_NOTIFICATION_LOADING,
  POST_INCIDENT_NOTIFICATION_FAILURE,
  POST_INCIDENT_NOTIFICATION_SUCCESS,
} from '../actions/vendorIncidentNotification';

import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { INCIDENT_ERROR_MESSAGE, INCIDENT_SAVED_SUCCESSFULLY_MESSAGE } from '../components/Common/AlertMessages';

const initialState = {
  loading: false,
  incidentNotificationList: [],
  incidentNotification: [],
  incidentNotificationSaveSuccess: null,
};

const convertToLocalTime = (str) => {
  const globalTime = new Date(`${str}` + 'Z');
  const formattedTime = format(globalTime, 'MM/dd/yy hh:mm a');
  return formattedTime;
};

export function vendorIncidentNotification(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_INCIDENT_ALERT_POPUP: {
      return {
        ...state,
        incidentNotificationSaveSuccess: payload,
      };
    }

    case SEARCH_INCIDENT_NOTIFICATION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_INCIDENT_NOTIFICATION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_INCIDENT_NOTIFICATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_INCIDENT_NOTIFICATION_FAILURE: {
      toast.error(INCIDENT_ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_INCIDENT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        incidentNotificationList: payload?.Data?.Rows.map((item) => {
          return {
            ...item,
            id: item.IncidentNotificationId,
            LocalTimeStamp: convertToLocalTime(item.InsertTimeStamp),
            GlobalTimeSTamp: format(parseISO(item.InsertTimeStamp), 'MM/dd/yy hh:mm a'),
          };
        }),
      };
    }

    case POST_INCIDENT_NOTIFICATION_SUCCESS: {
      toast.success(INCIDENT_SAVED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        incidentNotification: payload?.Data,
      };
    }

    default:
      return state;
  }
}
