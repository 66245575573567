import DOMPurify from 'dompurify';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';

export const getValidUrl = (url = '') => {
  if (isEmpty(url)) {
    return;
  }
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, '');

  if (/^(:\/\/)/.test(newUrl)) {
    return `https${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `https://${newUrl}`;
  }

  return newUrl;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const sanitizeUrl = (url) => {
  try {
    const sanitizedUrl = new URL(url);
    return sanitizedUrl.href;
  } catch (error) {
    return null;
  }
}

export const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

export const validateAndSanitizeURL = (url) => {
  // Check if the URL is empty or null
  if (!url) {
    return null;
  }

  // Sanitize the URL
  var sanitizedURL = sanitizeUrl(url);
  if (!sanitizedURL) {
    return null;
  }

  // Validate the URL format
  if (!urlPattern.test(sanitizedURL)) {
    return null;
  }

  // Check the protocol
  if (!(sanitizedURL.startsWith('http://') || sanitizedURL.startsWith('https://'))) {
    return null;
  }

  // Encode special characters
  var encodedURL = encodeURI(sanitizedURL);

  // Sanitize input using DOMPurify
  var sanitizedInput = DOMPurify.sanitize(encodedURL);

  // Return the sanitized URL
  return sanitizedInput;
}