import React from 'react';
import { FormControl, Box, InputLabel, MenuItem, Select, Chip, Checkbox, ListItemText, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { chipWrapper, defaultChipWrapper, extraSelectedChipStyles, multiSelectLabelStyles } from './styles';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 140,
    width: 240,
    height: '100%',
  },
  placeholder: {
    color: '#999',
  },
  standardVariant: {
    minHeight: '88px',
  },
  smallSize: {
    minHeight: '72px',
  },
  select: {
    maxHeight: '370px',
  },
}));

const MultiSelectDropdown = (props) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      disabled={props.disabled}
      error={props.error}
      style={props.style}
      variant={props.variant}
      htmlFor={props.htmlFor}
      size="small"
    >
      <InputLabel shrink sx={multiSelectLabelStyles}>
        {props.label}
      </InputLabel>
      <Select
        multiple
        value={props.value === '' ? [] : props.value}
        onChange={(e) => props.onChange(e.target.value)}
        renderValue={(selected) => {
          if (props.showEllipsis && selected?.length === 0) {
            return <p className="cropped-placeholder">{props.placeholder}</p>;
          } else if (selected?.length === 0) {
            return <p>{props.placeholder}</p>;
          }
          if (props.showEllipsis && selected?.length > 0) {
            return (
              <Box sx={chipWrapper}>
                <Chip key={selected[0]} sx={{ maxWidth: '70%', width: 'auto' }} label={props?.options?.find((op) => op.value === selected[0])?.label} />
                {selected.length > 1 && <Chip key={selected[selected.length - 1]} sx={extraSelectedChipStyles} label={`+ ${selected.length - 1}`} />}
              </Box>
            );
          }
          return (
            <Box sx={defaultChipWrapper}>
              {selected?.map((value) => (
                <Chip key={value} label={props?.options?.find((op) => op.value === value)?.label} />
              ))}
            </Box>
          );
        }}
        displayEmpty
        MenuProps={{ classes: { paper: classes.select } }}
        error={props.error}
        disabled={props.disabled}
        label={props.label}
        defaultValue={props.value || props.placeholder}
        disableUnderline={props.disableUnderline}
        className={`${isEmpty(props.value) ? classes.placeholder : ''} ${props.variant === 'standard' ? classes.standardVariant : ''} ${
          props.size === 'sm' ? classes.smallSize : ''
        }`}
      >
        <MenuItem disabled value={props.placeholder}>
          <em>{props.placeholder}</em>
        </MenuItem>
        {props.options.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            <Checkbox checked={props.value.indexOf(item.value) > -1} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
};

export default MultiSelectDropdown;
