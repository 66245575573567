import { 
        SEARCH_USER_GROUP_LIST_LOADING,
        SEARCH_USER_GROUP_LIST_SUCCESS,
        SEARCH_USER_GROUP_LIST_FAILURE, 
        ADD_USER_GROUP_LOADING,
        ADD_USER_GROUP_SUCCESS,
        ADD_USER_GROUP_FAILURE,
        SET_OPEN_USER_GROUP_SIDESHEET,
        SET_USER_GROUP_FORM_EDITABLE,
        SET_IS_NEW_USER_GROUP,
        UPDATE_USER_GROUP_LOADING,
        UPDATE_USER_GROUP_SUCCESS,
        UPDATE_USER_GROUP_FAILURE,
        SEARCH_USER_GROUP_FEATURE_LIST_LOADING,
        SEARCH_USER_GROUP_FEATURE_LIST_SUCCESS,
        SEARCH_USER_GROUP_FEATURE_LIST_FAILURE,
      } from "actions/clientProfileUserGroup";
import { ERROR_MESSAGE, SAVED_SUCCESSFULLY, UPDATED_SUCCESSFULLY } from "constants/errorMessage";
import { ACTIVE, ASSESSMENT_REQUEST_PERMISSIONS, ASSESSMENT_RESULTS_PERMISSIONS, ASSESSMENT_STATUS_PERMISSIONS, ASSESSMENT_VENDOR_RESPONSES_PERMISSIONS, REMEDIATION_PERMISSIONS, TPIR_VENDOR_PERMISSIONS } from "pages/ClientProfile/Users/UserGroups/UserGroupSideSheet/constants";
import { toast } from "react-toastify";
import { getSelectOptionsList } from "utils/arrayUtils";


const initialState = {
  loading: false,
  userGroupList: [],
  openUserGroupSidesheet: false,
  isUserGroupFormEditable: false,
  userGroupValueLabelList: [],
  userGroupFeatureList: [],
  isNewSystemGroup: false
};

const isAppRoleActive = (appRoleSystemName, permissionArray) => {
  return permissionArray.includes(appRoleSystemName);
}


export const clientProfileUserGroup = (state = initialState, action) => {
  const { type, payload, isEditable, isNewGroup } = action;

  switch (type) {
    case SEARCH_USER_GROUP_LIST_LOADING: 
    case ADD_USER_GROUP_LOADING: 
    case UPDATE_USER_GROUP_LOADING:
    case SEARCH_USER_GROUP_FEATURE_LIST_LOADING:{
        return {
          ...state,
          loading: true,
        };
      }
    case SEARCH_USER_GROUP_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE)
      return {
        ...state,
        loading: false,
        userGroupList : []
      }
    }
    case ADD_USER_GROUP_FAILURE: 
    case UPDATE_USER_GROUP_FAILURE: {
      toast.error(ERROR_MESSAGE)
      return {
        ...state,
        loading: false,
      }
    }
    case SEARCH_USER_GROUP_FEATURE_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE)
      return {
        ...state,
        loading: false,
        userGroupFeatureList: []
      }
    }
    case SEARCH_USER_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        userGroupList: payload?.Data?.Rows,
        userGroupValueLabelList: getSelectOptionsList(payload?.Data?.Rows, "UserGroupName", "UserGroupId") 
      }
    }
    case ADD_USER_GROUP_SUCCESS: {
      toast.success(SAVED_SUCCESSFULLY)
      return {
        ...state,
        loading: false
      }
    }
    case UPDATE_USER_GROUP_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY)
      return {
        ...state,
        loading: false
      }
    }
    case SEARCH_USER_GROUP_FEATURE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        userGroupFeatureList: payload,
        formBody: {
          UserGroupId: payload?.[0]?.UserGroupId ?? 1,
          UserGroupName: payload?.[0]?.UserGroupName ?? '',
          IsSystem: payload?.[0]?.IsSystem ?? '',
          Request: payload?.reduce((acc, item) => {
            if (item.Active === ACTIVE && isAppRoleActive(item.AppRoleSystemName, ASSESSMENT_REQUEST_PERMISSIONS)) {
              acc.push(item.AppRoleSystemName);
            }
            return acc;
          }, []),
          Status: payload.reduce((acc, item) => {
            if (item.Active === ACTIVE && isAppRoleActive(item.AppRoleSystemName, ASSESSMENT_STATUS_PERMISSIONS)) {
              acc.push(item.AppRoleSystemName);
            }
            return acc;
          }, []),
          Results: payload.reduce((acc, item) => {
            if (item.Active === ACTIVE && isAppRoleActive(item.AppRoleSystemName, ASSESSMENT_RESULTS_PERMISSIONS)) {
              acc.push(item.AppRoleSystemName);
            }
            return acc;
          }, []),
          VendorResponses: payload.reduce((acc, item) => {
            if (item.Active === ACTIVE && isAppRoleActive(item.AppRoleSystemName, ASSESSMENT_VENDOR_RESPONSES_PERMISSIONS)) {
              acc.push(item.AppRoleSystemName);
            }
            return acc;
          }, []),
          Remediation: payload.reduce((acc, item) => {
            if (item.Active === ACTIVE && isAppRoleActive(item.AppRoleSystemName, REMEDIATION_PERMISSIONS)) {
              acc.push(item.AppRoleSystemName);
            }
            return acc;
          }, []),
          TPIR: payload.reduce((acc, item) => {
            if (item.Active === ACTIVE && isAppRoleActive(item.AppRoleSystemName, TPIR_VENDOR_PERMISSIONS)) {
              acc.push(item.AppRoleSystemName);
            }
            return acc;
          }, [])
        }
      }
    }
    case SET_OPEN_USER_GROUP_SIDESHEET: {
      return {
        ...state,
        openUserGroupSidesheet: payload
      }
    }
    case SET_USER_GROUP_FORM_EDITABLE: {
      return {
        ...state,
        isUserGroupFormEditable: isEditable
      }
    }
    case SET_IS_NEW_USER_GROUP: {
      return {
        ...state,
        isNewSystemGroup: isNewGroup
      }
    }
    default:
      return state;
  }
};
