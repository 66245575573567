import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import userReducer from './userProfile';
import client from './client';
import session from './session';
import navbar from './navbar';
import vendors from './vendors';
import errorHandler from './errorHandler';
import { clientProfileCompanyContacts } from './clientProfileCompanyContacts';
import vendorsSlaInformation from './vendorsSlaInformation';
import clientProduct from './clientProduct';
import { companyProfileData } from './companyInformation';
import { vendorIncidentNotification } from './vendorIncidentNotification';
import dashboard from './dashboard';
import vendorInformation from './vendorInformation';
import vendorContactInformation from './vendorContactInformation';
import { documentedIncidents } from './documentedIncidents';
import { incidentDetails } from './incidentDetails';
import { activeOutreach } from './clientProfileActiveOutreaches';
import questionnaireBuilder from './viewQuestionnaire';
import { outreachInformation } from './outreachInformation';
import { vendorQuestionnaireAnswers } from './vendorQuestionnaireAnswers';
import { clientProfileUsers } from './clientProfileUsers';
import { clientProfileUserRoles } from './clientProfileUserRoles';
import assessmentsOverview from './assessmentsOverview';
import customErrorHandler from './customErrorHandler';
import { assessmentRequest } from './assessmentRequest';
import { lookUp } from './lookup';
import programMetrics from './programMetrics';
import config from './config';
import supportTicket from './supportTicket';
import executiveSummary from './executiveSummary';
import vendorsAndProducts from './vendorsAndProducts';
import supportArticles from './supportArticles';
import dynamicVendorRoster from './dynamicVendorRoster';
import commandCenter from './commandCenter';
import remediationGuidance from './remediationGuidance';
import assessmentResponses from './assessmentResponses';
import { apiKeyManager } from './apiKeyManager';
import { clientProfileUserGroup } from './clientProfileUserGroup';
import apiDocumentation from './apiDocumentation';
import communicationPreferences from './communicationPreferences';
import vendorCatalog from './vendorCatalog';
import appSettings from './appSettings';

const rootReducer = combineReducers({
  form,
  userReducer,
  session,
  client,
  navbar,
  vendors,
  clientProfileCompanyContacts,
  errorHandler,
  vendorsSlaInformation,
  companyProfileData,
  clientProduct,
  vendorIncidentNotification,
  dashboard,
  vendorInformation,
  vendorContactInformation,
  activeOutreach,
  documentedIncidents,
  incidentDetails,
  questionnaireBuilder,
  outreachInformation,
  vendorQuestionnaireAnswers,
  clientProfileUsers,
  customErrorHandler,
  assessmentsOverview,
  assessmentRequest,
  clientProfileUserRoles,
  lookUp,
  programMetrics,
  config,
  supportTicket,
  executiveSummary,
  vendorsAndProducts,
  supportArticles,
  dynamicVendorRoster,
  commandCenter,
  remediationGuidance,
  assessmentResponses,
  apiKeyManager,
  clientProfileUserGroup,
  apiDocumentation,
  communicationPreferences,
  vendorCatalog,
  appSettings
});
export default rootReducer;
