import { CLIENT_APP_ID } from "constants/users"

export const getApiDocumentationListSearchBody = () => {
    return {
        "Intent": "",
        "Fields": [
            "GRCApiFeatureId",
            "FeatureName",
            "RequestType",
            "EndPoint",
            "Payload",
            "Response",
            "GRCApiDocumentParameterList",
            "GRCApiDocumentResponseList"
        ],
        "Criterion": [
            {
                "Field": "AppId",
                "Operator": "=",
                "Value": CLIENT_APP_ID
            }
        ],
        "Sort": [],
        "Page": 0,
        "PageSize": 0
    }
}