import { isNil, isUndefined } from 'lodash';

export const getPercentage = (num, den) => {
  const numerator = Number(num);
  const denominator = Number(den);
  if (!isUndefined(numerator) && !isUndefined(denominator) && numerator !== 0 && denominator !== 0 && !isNil(numerator) && !isNil(denominator)) {
    return (Number(numerator) / Number(denominator)) * 100;
  }
  return 0;
};
