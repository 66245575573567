export const autoCompleteStyles = () => {
  return {
    boxShadow: '-4px -4px 40px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    '.MuiInputLabel-root': {
      marginTop: '4px !important',
      fontSize: '14px',
    },
    '.MuiInputBase-root': {
      padding: '2px',
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: '0',
    },
    '.MuiButtonBase-root': {
      marginRight: '4px',
    },
    '& button.MuiButtonBase-root': {
      visibility: 'hide',
    },
    '.MuiInputBase-input': {
      padding: "24px !important",
      "&::placeholder": {
        fontSize: "14px"
      }
    },
    marginTop: '20px',
  };
};
