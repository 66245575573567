import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import { FormHelperText, IconButton, Tooltip, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp, CloseRounded, ExpandLess, ExpandMore } from '@mui/icons-material'
import { fieldLabelStyles } from 'components/FormField/formFieldStyles';

const SelectBox = (props) => {

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: parseInt(props?.minWidth) > 0 ? parseInt(props?.minWidth) : 240,
      width: '100%',
      height: '100%',
    },
    placeholder: {
      color: '#999',
    },
    standardVariant: {
      height: parseInt(props?.height) > 0 ? parseInt(props?.height) : 88,
    },
    standardVariantSmall: {
      height: '60px',
    },
    select: {
      maxHeight: '370px',
    },
    buttonSelect: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      marginTop: '1px',
      marginRight: '10px',
      width: 'fit-content',
    },
  }));

  const classes = useStyles();

  const mapSelectOptions = () => (item, i) => {
    if (item.type === 'file-upload') {
      return (
        <label>
          <MenuItem key={i} disabled={item.disabled}>
            <Tooltip
              title={item.label}
              placement="top"
              componentsProps={{ tooltip: { className: props?.tooltipClassName } }}
            >
              <Typography>
                {item.label}
              </Typography>
            </Tooltip>
            <input hidden accept=".csv" type="file" onChange={item.onClick} />
          </MenuItem>
        </label>
      );
    }
    return (
      <MenuItem key={i} value={item.value} disabled={item.disabled}>
        <Tooltip
          title={item.label}
          placement="top"
          componentsProps={{ tooltip: { className: props?.tooltipClassName } }}
        >
          <Typography>
            {' '}
            {item.label}{' '}
          </Typography>
        </Tooltip>
      </MenuItem >
    );
  };

  const { buttonSelect = false, useExpandIcons } = props;
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props?.value);
    props.setselectedtype && props.setselectedtype(props?.value);
  }, [props.value]);

  const handleClearSelection = () => {
    setValue('');
    props.setselectedtype && props.setselectedtype(null);
  };

  return (
    <FormControl
      className={`${classes.formControl}`}
      disabled={props.disabled}
      error={props.error}
      style={props.style}
      variant={props.variant}
      htmlFor={props.htmlFor}
      size="small"
    >
      {
        props?.showTooltipLabel ? (
          <Tooltip placement='top' title={props.label}>
            {props.label && <Typography variant="body3" sx={(theme) => fieldLabelStyles(theme, props.disabled)}>
          {props?.label}
        </Typography>}
          </Tooltip>
        ) : props.label && <Typography variant="body3" sx={(theme) => fieldLabelStyles(theme, props.disabled)}>
          {props?.label}
        </Typography>
      }
      <Select
        MenuProps={{ classes: { paper: classes.select } }}
        IconComponent={
          value
            ? props.clearSelection === true
              ? () => {
                return (
                  <IconButton onClick={handleClearSelection}>
                    <CloseRounded sx={{ mr: '-12px ' }} />
                  </IconButton>
                );
              }
              : (props) => {
                return useExpandIcons ? <ExpandLess {...props} /> : <ArrowDropUp {...props} />;
              }
            : (props) => {
              return useExpandIcons ? <ExpandMore {...props} /> : <ArrowDropDown {...props} />;
            }
        }
        error={props.error}
        disabled={props.disabled}
        label={props.label}
        onChange={props.onChange}
        placeholder={props.placeholder}
        value={value || props.placeholder}
        defaultValue={value || props.placeholder}
        disableUnderline={props.disableUnderline}
        className={`${!value ? classes.placeholder : ''} ${props.variant === 'standard' ? props.size === 'small' ? classes.standardVariantSmall : classes.standardVariant : ''} ${buttonSelect ? classes.buttonSelect : ''
          }`}
      >
        <MenuItem value={props.placeholder} hidden>
          {props.placeholder}
        </MenuItem>
        {props.options && props.options.map(mapSelectOptions())}
      </Select>
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
};
export default SelectBox;
