import {
    SEARCH_API_DOCUMENTATION_LIST_LOADING,
    SEARCH_API_DOCUMENTATION_LIST_SUCCESS,
    SEARCH_API_DOCUMENTATION_LIST_FAILURE,
    SEARCH_API_TOKEN_LOADING,
    SEARCH_API_TOKEN_SUCCESS,
    SEARCH_API_TOKEN_FAILURE,
    SET_API_TOKEN
} from 'actions/apiDocumentation';
import { TOKEN_SET_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    apiDocumentationList: [],
    authEndpoint: {},
    apiKeyToken: "",
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SEARCH_API_DOCUMENTATION_LIST_LOADING:
        case SEARCH_API_TOKEN_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case SEARCH_API_DOCUMENTATION_LIST_SUCCESS: {
            const enpointList = payload?.Data?.Rows?.slice(1);
            // Grouping the objects based on "ControlName" using Array.reduce
            const groupedArray = enpointList?.reduce((acc, obj) => {
                const key = obj?.FeatureName;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});

            //Creating an array of objects with FeatureName as keys and nested arrays as endpoints
            const resultArray = Object.entries(groupedArray).map(([key, value]) => {
                return {
                    FeatureName: key,
                    endpoints: value.map((endpoint) => {
                        return {
                            ...endpoint
                        };
                    }),
                };
            });

            return {
                ...state,
                loading: false,
                apiDocumentationList: resultArray,
                authEndpoint: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0]
            };
        }

        case SEARCH_API_DOCUMENTATION_LIST_FAILURE: {
            return {
                ...state,
                loading: false,
                apiDocumentationList: [],
                authEndpoint: {}
            };
        }

        case SEARCH_API_TOKEN_SUCCESS: {
            toast.success(TOKEN_SET_SUCCESSFULLY_MESSAGE)
            return {
                ...state,
                loading: false,
                apiKeyToken: payload?.Data?.jwtToken || ""
            }
        }

        case SEARCH_API_TOKEN_FAILURE: {
            return {
                ...state,
                loading: false,
                apiKeyToken: ""
            }
        }

        case SET_API_TOKEN: {
            return {
                ...state,
                apiKeyToken: payload
            }
        }

        default:
            return state;
    }
};
