import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '@mui/material';
import CustomErrorHandlerModal from '../CustomErrorHandlerModal/CustomErrorHandlerModal';
import { LOGOUT_MESSAGE } from 'constants/constants';
import { iconStyles, logOutStyles } from './logoutbuttonStyle';

const LogoutButton = ({ handleUserNameClose }) => {
  const { logout } = useAuth0();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const customOptionSelected = () => {
    logout({
      returnTo: window.location.origin + '/logout',
    });
  };

  const logoutButtonClicked = () => {
    setOpenConfirmation(true);
  };

  const closePopup = () => {
    handleUserNameClose();
    setOpenConfirmation(false);
  };

  return (
    <div>
      <Button className="btn" disableRipple disableFocusRipple disableElevation onClick={logoutButtonClicked} sx={logOutStyles}>
        <FontAwesomeIcon style={iconStyles()} icon={faPowerOff} />
        Log Out
      </Button>
      <CustomErrorHandlerModal question={LOGOUT_MESSAGE} optionSelected={customOptionSelected} open={openConfirmation} close={closePopup} />
    </div>
  );
};

export default LogoutButton;
