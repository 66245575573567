import { isEmpty } from 'lodash';
import { ACTIVE_TICKETS, SOLVED_TICKETS } from 'pages/SupportTicket/SupportTicketOverview/constants';

export const getSupportTicketBody = (searchData, searchStatus) => {
  let criterion = [];
  let sort = [
    {
      Field: 'LastUpdated',
      Order: 'DESC',
    }
  ];
  let fields = [
    'ClientOrgId',
    'ClientOrgName',
    'VendorOrgId',
    'VendorOrgName',
    'ProductVendorId',
    'ProductName',
    'Subject',
    'Message',
    'ExternalTicketId',
    'RequestType',
    'Status',
    'Active',
    'LastUpdated',
  ]
  if (!isEmpty(searchData)) {
    fields.push("Comments")
    criterion = [
      {
        field: 'SupportTicketId',
        operator: '=',
        value: searchData,
      },
    ];
  }

  if (searchStatus === ACTIVE_TICKETS || searchStatus === SOLVED_TICKETS) {
    fields.push(searchStatus);
  }

  return {
    intent: '',
    fields: fields,
    criterion: criterion,
    sort: sort,
    page: 0,
    pageSize: 0,
  };
};
