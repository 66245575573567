import React, { useState } from 'react';
import { getInputComponent, T } from './getInputComponent';
import './index.scss';
import { InputAdornment, IconButton, Tooltip, Box } from '@mui/material';
import { Visibility, VisibilityOff, Add, Search, Info } from '@mui/icons-material';
import { characterCountStyles, helpTextStyle } from './formFieldStyles';

const getIcons = (icon) => {
  switch (icon) {
    case 'search':
      return <Search />;
    default:
      return <Search />;
  }
};
const FormField = (props) => {
  const { input, children, ...rest } = props;
  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const showError = props.error && props.error !== '' && props.error !== null;

  const Component = getInputComponent(props.tstype);

  return (
    <div className={`mui-field`} style={{ display: 'flex', flexDirection: 'column' }}>
      {props.showLabel && <div className="mui-field-label">{props.formFieldLabel}</div>}
      <div className="mui-field-value" style={{ display: 'flex', alignItems: 'center' }}>
        <Component
          {...props}
          {...input}
          {...rest}
          {...children}
          id={props.inputId}
          onChange={props.onChange}
          autoComplete={props.tstype === T.AUTOCOMPLETE || props.tstype === T.MULTI_SELECT_AUTOCOMPLETE ? 'off' : 'on'}
          type={props.tstype === 'password' ? (values.showPassword ? 'text' : 'password') : props.tstype}
          onInput={(e) => (props.tstype === 'number' ? (e.target.value = e.target.value.toString().slice(0, props.maxNumberLength)) : {})}
          onKeyDown={props.tstype === 'number' ? (evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault() : null}
          InputProps={{
            disableUnderline: true,
            endAdornment: props.tstype === 'password' && (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: props.startIcon && <InputAdornment position="start">{getIcons(props.startIcon)}</InputAdornment>,
          }}
          inputProps={{
            maxLength: props.maxLength,
          }}
          InputLabelProps={{ shrink: true }}
          helperText={showError ? props.error : props.helperText}
          disabled={props.disabled}
          defaultValue={props.value}
        />

        {props.helpText && (
          <Tooltip title={props.helpText} placement="right-start" arrow>
            <Info sx={theme => helpTextStyle(theme)}/>
          </Tooltip>
        )}

        {props.footerLabel && (
          <div className="mui-field-footer-label" style={{ width: props.width }}>
            {props.footerLabel}
          </div>
        )}
      </div>

      {props.showCharacterCount && props.maxLength && (
        <Box sx={(theme) => characterCountStyles(theme)}>
          {props.tstype === 'autocompleteWithFreeSolo' ? (
            `${input?.value?.label?.length ?? 0} / ${props.maxLength}`
          ) : (
            `${input?.value?.length ?? 0} / ${props.maxLength}`
          )}
        </Box>
      )}

      {props.addLabel && (
        <div className="add-new-label" onClick={() => props.onClickAddNew(props.name)}>
          <Add /> Add New {props.addLabel}
        </div>
      )}
    </div>
  );
};

export default FormField;
