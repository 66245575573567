import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NavBarStyle.scss';
import { menuListWrapper, menuLinkWrapper, menuWrapper, menuLink, linkWrapper } from './NavBarStyles';
import MenuPanel from './MenuPanel';
import { getIcon } from './util';
import IncidentNotificationPopup from '../../pages/Incident/IncidentNotificationPopup/IncidentNotificationPopup';
import { setMenuPanelId } from 'actions/navbar';
import { isEmpty } from 'lodash';
import useHasClaims from 'hooks/useHasClaims';
import { USER_CLAIMS } from 'constants/userClaims';
import { INCIDENTS_TAB, TPIR_SUBSCRIBED } from 'constants/constants';

const Menu = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const menuPanelId = useSelector((state) => state.navbar.menuPanelId);

  const menuList = useSelector((state) => state.navbar.menuList);
  const location = useLocation();
  const [openPopup, setOpenPopup] = useState(false);
  const [updatedMenuList, setUpdatedMenuList] = useState(false);
  const latestContractConfiguration = useSelector((state) => state?.client?.latestContractConfiguration);

  const hasVendorViewAccess = useHasClaims([USER_CLAIMS.TPIR_VENDOR_READ]);
  const hasVendorEditAccess = useHasClaims([USER_CLAIMS.VENDORS_UPDATE]);

  const getActiveNavClass = (subMenuList) => {
    return subMenuList?.filter((it) => it.ButtonRoute === `${location.pathname + location?.search}` || it.MenuRoute === `${location.pathname + location?.search}`).length > 0 ? 'activeNavBar' : '';
  };

  const openReportIncidentPopup = () => {
    setOpenPopup(true);
  };

  useEffect(() => {
    if (latestContractConfiguration?.IsTPIRSubscribed === TPIR_SUBSCRIBED.NO || (!hasVendorEditAccess && !hasVendorViewAccess)) {
      setUpdatedMenuList(menuList.filter(menu => menu.AppMenuName?.toLowerCase() !== INCIDENTS_TAB))
    } else {
      setUpdatedMenuList(menuList)
    }
  }, [latestContractConfiguration, hasVendorEditAccess, hasVendorViewAccess]);

  const onClickMenu = (subMenuList) => {
    if (subMenuList?.length > 0) {
      history.push(subMenuList[0].MenuRoute);
    }
  };

  return (
    <Box sx={menuListWrapper}>
      {openPopup && <IncidentNotificationPopup openPopup={openPopup} setClosePopup={setOpenPopup} />}
      {updatedMenuList &&
        updatedMenuList.map((menu, index) => {
          const { AppMenuId, AppMenuName, MenuRoute, MenuIcon, SubMenuList = [] } = menu;
          return SubMenuList?.length === 0 ? (
            !isEmpty(MenuRoute) && (
              <Box sx={linkWrapper} key={index}>
                <NavLink
                  to={MenuRoute}
                  key={index}
                  onMouseOver={() => {
                    dispatch(setMenuPanelId(0));
                  }}
                >
                  <Box sx={menuLinkWrapper} className={`${location.pathname + location?.search}` === MenuRoute ? 'activeNavBar' : 'hoverIcon'}>
                    <FontAwesomeIcon icon={getIcon(MenuIcon)} />
                    <Typography sx={menuLink}>{AppMenuName}</Typography>
                  </Box>
                </NavLink>
              </Box>
            )
          ) : (
            <Box
              key={index}
              onMouseOver={() => {
                dispatch(setMenuPanelId(AppMenuId));
              }}
              onMouseLeave={() => dispatch(setMenuPanelId(0))}
              sx={menuWrapper}
              onClick={() => onClickMenu(SubMenuList)}
            >
              <Box sx={menuLinkWrapper} className={getActiveNavClass(SubMenuList)}>
                <FontAwesomeIcon icon={getIcon(MenuIcon)} />
                <Typography sx={menuLink}>{AppMenuName}</Typography>
                {menuPanelId === AppMenuId && <MenuPanel subMenuList={SubMenuList} openReportIncidentPopup={openReportIncidentPopup} />}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default Menu;
