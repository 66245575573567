import {
  GET_CLIENT_INCIDENT_DETAILS_FAILURE,
  GET_CLIENT_INCIDENT_DETAILS_LOADING,
  GET_CLIENT_INCIDENT_DETAILS_SUCCESS,
  SET_INCIDENT_FAILURE,
  SET_INCIDENT_LOADING,
  SET_INCIDENT_SUCCESS,
} from '../actions/incidentDetails';

const initialState = {
  loading: false,
  incidentDetails: [],
  isViewQuestionnaire: false,
};

export const incidentDetails = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENT_INCIDENT_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CLIENT_INCIDENT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        incidentDetails: payload,
      };
    }
    case GET_CLIENT_INCIDENT_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_INCIDENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_INCIDENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        isViewQuestionnaire: payload.isViewQuestionnnaire,
      };
    }
    case SET_INCIDENT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};
